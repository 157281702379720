import { CopyOutlined, SyncOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Button, Col, Divider, notification, Row, Select, Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import { updateUser } from '../../apis/auth';
import Submit from "../../assets/common/verifiedLogos/verify-yes.svg";
import Cross from "../../assets/icons/Cross.svg";
import Pen from "../../assets/icons/Pen.svg";
import { InputField } from '../ComponentLibrary';

const { Option } = Select;

const EmployeeUpdate = ({ user }) => {
    const [disabled, setDisabled] = useState(true)
    const [employee, setEmployee] = useState({
        displayName: "",
        organisationId: "",
        PAN: "",
        username: "",
        dateOfJoining: "",
        dateOfBirth: "",
        pointOfContact: "",
        primaryEmail: "",
        phone: "",
        designation: "",
    });
    const [familyDetails, setFamilyDetails] = useState({
        phoneNumber: "",
        dateOfBirth: "",
        gender: "",
        maritalStatus: "",
        spouseDOB: "",
        spouseName: "",
        spouseEmploymentStatus: "",
        fathersName: "",
        mothersName: "",
        fathersDOB: "",
        mothersDOB: "",
        isFatherDisabled: false,
        isMotherDisabled: false,
    });

    const handleEmployeeChange = (e) => {
        setEmployee({ ...employee, [e.target.name]: e.target.value })
    }
    const handleFamilyChange = (e) => {
        setEmployee({ ...employee, [e.target.name]: e.target.value })
    }

    const onUpdate = () => {
        updateUser(employee).then(res => {
            notification.success({ message: "Updated Employee Basic Info Successfully!" })
        }).catch(err => {
            notification.error({ message: "Error While Updating Organisation" })
        })
    }

    useEffect(() => {
        const familyInfo = user.familyDetails ?? {};
        setEmployee({
            displayName: user.displayName,
            organisationId: user.organisationId,
            dateOfBirth: user.dateOfBirth ? user.dateOfBirth : familyInfo.dateOfBirth,
            dateOfJoining: user.dateOfJoining ? new Date(user.dateOfJoining) : "",
            PAN: user.PAN,
            username: user.username,
            primaryEmail: user.primaryEmail,
            phone: user.phone,
            designation: user.designation,
        });
        setFamilyDetails({
            phoneNumber: familyInfo.phoneNumber ?? "",
            dateOfBirth: familyInfo.dateOfBirth ?? "",
            gender: familyInfo.gender ?? "",
            maritalStatus: familyInfo.maritalStatus ?? "",
            spouseEmploymentStatus: familyInfo.spouseEmploymentStatus ?? "",
            spouseDOB: familyDetails.spouseDOB,
            spouseName: familyDetails.spouseName,
            fathersName: familyInfo.fathersName ?? "",
            mothersName: familyInfo.mothersName ?? "",
            fathersDOB: familyInfo.fathersDOB ?? "",
            mothersDOB: familyInfo.mothersDOB ?? "",
            isFatherDisabled: familyInfo.isFatherDisabled ?? false,
            isMotherDisabled: familyInfo.isMotherDisabled ?? false,
        })
    }, [familyDetails.spouseDOB, familyDetails.spouseName, user])


    return (
        <div >
            <div className="organisation-basic-info organisation-details-container" style={{ padding: "35px 20px" }}>
                <Skeleton loading={!user._id} active avatar>
                    <div className="organisation-profile-container">
                        <div className="organisation-logo-container">
                            {user.image ? <img
                                alt="img"
                                width="50"
                                height="50"
                                className="organisation-logo"
                                src={user.image}
                            /> : <Avatar size={64} icon={<UserOutlined />} />}
                        </div>
                        <div className="organisation-name-container">
                            <h1 className="organisation-name-displayname">{user.displayName}</h1>
                            <span className="copy-unique-id"><p className="unique-id">{user._id}</p><span className="copy-btn"><CopyOutlined /></span></span>
                        </div>
                    </div>
                    <div className="password-container">
                        <Button type="link" className="button-password">
                            <SyncOutlined />
                            Reset Password
                        </Button>
                    </div>
                </Skeleton>
            </div>

            <div className=" organisation-details-container">
                <Skeleton loading={!user._id} active avatar paragraph={{ rows: 10 }}>
                    <div className="information-section">
                        <div className="section-header">
                            <p className="information-category-heading">
                                Personal Information
                            </p>
                            <div className="flex">
                                {
                                    !disabled && <Button type="link" className="edit-button" style={{ marginTop: "13px" }} onClick={onUpdate}>
                                        <span style={{ color: "rgb(64, 210, 172)" }}><img alt="img" src={Submit} />
                                            {" "}Save</span>
                                    </Button>
                                }
                                <Button type="link" className="edit-card-button" style={{ marginTop: "13px" }} onClick={() => setDisabled(!disabled)}>
                                    {disabled ? <span style={{ color: "rgb(254, 161, 1)" }}><img alt="img" src={Pen} />
                                        {" "}Edit</span> : <span style={{ color: "rgb(118, 123, 130)" }}><img alt="img" src={Cross} />
                                        {" "}Cancel</span>}
                                </Button>
                            </div>
                        </div>
                        <Row gutter={[40, 2]}>
                            <Col xs={24} sm={24} md={12} lg={12}>
                                <div className="employee-info">
                                    <h3 className="headings fields-heading">Employee Name</h3>
                                    <InputField onChange={handleEmployeeChange} name="displayName" disabled={disabled} className="profile-data-display" value={employee.displayName} />
                                </div>
                                <div className="employee-info">
                                    <h3 className="headings fields-heading">PAN Number</h3>
                                    <InputField onChange={handleEmployeeChange} name="PAN" disabled={disabled} className="profile-data-display" value={employee.PAN} />
                                </div>
                                <div className="employee-info">
                                    <h3 className="headings fields-heading">Date Of Birth</h3>
                                    <InputField onChange={handleEmployeeChange} name="dateOfBirth" disabled={disabled} placeholder="mm/dd/yyyy" className="profile-data-display" value={employee.dateOfBirth} />
                                </div>
                                <div className="employee-info">
                                    <h3 className="headings fields-heading">Designation</h3>
                                    <InputField onChange={handleEmployeeChange} name="designation" disabled={disabled} className="profile-data-display" value={employee.designation} />
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12}>
                                <div className="employee-info">
                                    <h3 className="headings fields-heading">Email</h3>
                                    <InputField onChange={handleEmployeeChange} name="primaryEmail" disabled={disabled} className="profile-data-display" value={employee.primaryEmail} />
                                </div>
                                <div className="employee-info">
                                    <h3 className="headings fields-heading">UserName</h3>
                                    <InputField onChange={handleEmployeeChange} name="username" disabled={disabled} className="profile-data-display" value={employee.username} />
                                </div>
                                <div className="employee-info">
                                    <h3 className="headings fields-heading">Date Of Joining</h3>
                                    <InputField name="dateOfJoining" disabled={disabled} placeholder="mm/dd/yyyy" className="profile-data-display" value={employee.dateOfJoining} />
                                </div>
                                <div className="employee-info">
                                    <h3 className="headings fields-heading">Phone number</h3>
                                    <InputField onChange={handleEmployeeChange} name="phone" disabled={disabled} className="profile-data-display" value={employee.phone} />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Skeleton>
            </div>
            <div className=" organisation-details-container">
                <Divider />
                <Skeleton loading={!user._id} active avatar paragraph={{ rows: 10 }}>
                    <div className="information-section">
                        <div className="section-header">
                            <p className="information-category-heading">
                                Parent Information
                            </p>
                        </div>
                        <Row gutter={[40, 2]}>
                            <Col xs={24} sm={24} md={12} lg={12}>
                                <div className="employee-info">
                                    <h3 className="headings fields-heading">Father's Name</h3>
                                    <InputField onChange={handleFamilyChange} name="fathersName" disabled={disabled} className="profile-data-display" value={familyDetails.fathersName} />
                                </div>
                                <div className="employee-info">
                                    <h3 className="headings fields-heading">PAN Number</h3>
                                    <InputField onChange={handleFamilyChange} name="PAN" disabled={disabled} className="profile-data-display" value={familyDetails.fathersDOB} />
                                </div>
                                <div className="employee-info">
                                    <h3 className="headings fields-heading">Phone Number</h3>
                                    <InputField onChange={handleFamilyChange} name="phoneNumber" disabled={disabled} className="profile-data-display" value={familyDetails.phoneNumber} />
                                </div>
                                <div className="employee-info">
                                    <h3 className="headings fields-heading">Spouse Name</h3>
                                    <InputField onChange={handleFamilyChange} name="spouseName" disabled={disabled} className="profile-data-display" value={familyDetails.spouseName} />
                                </div>
                                <div className="employee-info">
                                    <h3 className="headings fields-heading">Gender</h3>
                                    <InputField onChange={handleFamilyChange} name="gender" disabled={disabled} className="profile-data-display" value={familyDetails.gender} />
                                </div>
                                <div className="employee-info">
                                    <h3 className="headings fields-heading">Is Mother Disabled</h3>
                                    <Select name="isFatherDisabled" disabled={disabled} className="profile-data-display" onChange={handleFamilyChange} value={familyDetails.isFatherDisabled}>
                                        <Option value={true}>
                                            True
                                        </Option>
                                        <Option value={false}>
                                            False
                                        </Option>
                                    </Select>
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12}>
                                <div className="employee-info">
                                    <h3 className="headings fields-heading">Mother's Name</h3>
                                    <InputField onChange={handleFamilyChange} name="mothersName" disabled={disabled} className="profile-data-display" value={familyDetails.mothersName} />
                                </div>
                                <div className="employee-info">
                                    <h3 className="headings fields-heading">Mother's DOB</h3>
                                    <InputField onChange={handleFamilyChange} name="mothersDOB" disabled={disabled} className="profile-data-display" value={familyDetails.mothersDOB} />
                                </div>
                                <div className="employee-info">
                                    <h3 className="headings fields-heading">Phone Number</h3>
                                    <InputField onChange={handleFamilyChange} name="phoneNumber" disabled={disabled} className="profile-data-display" value={familyDetails.phoneNumber} />
                                </div>
                                <div className="employee-info">
                                    <h3 className="headings fields-heading">Spouse DOB</h3>
                                    <InputField onChange={handleFamilyChange} name="spouseDOB" disabled={disabled} className="profile-data-display" value={familyDetails.spouseDOB} />
                                </div>
                                <div className="employee-info">
                                    <h3 className="headings fields-heading">Marital Status</h3>
                                    <InputField onChange={handleFamilyChange} name="maritalStatus" disabled={disabled} className="profile-data-display" value={familyDetails.maritalStatus} />
                                </div>
                                <div className="employee-info">
                                    <h3 className="headings fields-heading">Is Mother Disabled</h3>
                                    <Select name="isMotherDisabled" disabled={disabled} className="profile-data-display" onChange={handleFamilyChange} value={familyDetails.isMotherDisabled}>
                                        <Option value={true}>
                                            True
                                        </Option>
                                        <Option value={false}>
                                            False
                                        </Option>
                                    </Select>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Skeleton>
            </div>
        </div>
    );
}

export default EmployeeUpdate