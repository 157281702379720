import { Tabs } from "antd";
import { useState } from "react";
import { HeaderComponent } from "../Common";
import CalcDataCapturing from "./components/calcDataCapturing";
import SubscriberLeads from "./components/subscriberLeads";
import "./WebsiteData.less";

const { TabPane } = Tabs;

const WebsiteData = (props) => {
  const [active, setActive] = useState(1);

  return (
    <div className="site-layout">
      {/* <HeaderComponent icon={activeDashboard} name={"Organisation Dashboard"} /> */}
      <HeaderComponent />
      <hr className="line" />
      <h2 className="header">Website Data</h2>
      <div className="app-main-content-container">
        <Tabs
          defaultActiveKey={active}
          size="large"
          onClick={() => setActive(1)}
        >
          <TabPane tab="Calculator" key="1">
            <CalcDataCapturing />
          </TabPane>
          <TabPane tab="Subscriber leads" key="2">
            <SubscriberLeads />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default WebsiteData;
