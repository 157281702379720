import "./completionMessage.less";
import { Button } from "antd"
import { Link } from "react-router-dom"
const CompletionMessage = () => {
    return (
        <div className="completion-message-container">
            <div className="completion-message-container-message-container">
                <h1 className="completion-message-heading">We’re getting things ready for you…</h1>
                <p className="completion-message-para">Thank you for signing up. We’ve received your request. Our representatives are on their way and will get back to you shortly. We appreciate your patience.</p>
                <div className="completion-message-button-container">
                    <Link to="/login">
                        <Button className="completion-message-button" >Log In</Button>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default CompletionMessage;