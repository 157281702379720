import { Select, Spin } from "antd";
import { useEffect, useState } from "react";
import { changedLoginStateAPI } from "../../../../../apis/admin";

const { Option } = Select;

const loginStateList = {
    0: "Password Change",
    1: "Select Salary Structure",
    2: "Add/Modify CTC",
    3: "Add Allowances",
    4: "Completed",
}

const LoginStateChange = ({ user }) => {
    let [changedLoginState, setChangedLoginState] = useState(0)
    let [apiLoading, setApiLoading] = useState(false)

    useEffect(() => {
        if (user) setChangedLoginState(user.loginstate ?? 0)
    }, [user])

    let onChange = async (value) => {
        try {
            setApiLoading(true)
            setChangedLoginState(parseInt(value.value))
            let response = await changedLoginStateAPI({ id: user._id, loginstate: parseInt(value.value) })
            if (response.status !== 200) {
                setChangedLoginState(0)
            }
        } catch (error) {
            setChangedLoginState(0)
        } finally {
            setApiLoading(false)
        }
    }


    return (
        <div className="originality-container">
            <Spin spinning={!user.organisationId || apiLoading}>
                <div className={"flex"}>
                    <div className="header-description">
                        <p className="heading">Change Login State</p>
                        <span className='description'>Login State is the state of activity, after user first time login.</span>
                    </div>
                    <div className="switch-container">
                        {
                            user && <Select style={{ minWidth: "200px" }} labelInValue onChange={onChange}
                                value={{ value: changedLoginState, label: `${changedLoginState} - ${loginStateList[parseInt(changedLoginState)]}` }}>
                                {
                                    Object.keys(loginStateList).map(key => {
                                        return (
                                            <Option key={key} value={key}>
                                                {key + " - " + loginStateList[key]}
                                            </Option>
                                        )
                                    })
                                }

                            </Select>
                        }
                    </div>
                </div>
            </Spin>
        </div>
    )
}
export default LoginStateChange