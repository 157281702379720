import { Select, Tabs } from "antd";
import { useState } from "react";
import config from "../../utils/config";
import { HeaderComponent } from "../Common";
import BB12 from "./components/12bb";
import JannUsers from "./components/JannUsers";
import RentReceipt from "./components/rentReceipt";
import "./documentCheck.less";

const { TabPane } = Tabs;
const { Option } = Select;
const { getCurrentFinancialYear, getFinancialYears } = config;
const DocumentCheck = (props) => {
  const [currentFinancialYear, setCurrentFinancialYear] = useState(getCurrentFinancialYear());

  return (
    <div className="site-layout">
      {/* <HeaderComponent icon={activeDashboard} name={"Organisation Dashboard"} /> */}
      <HeaderComponent />
      <hr className="line" />
      <div className="flex justify-between align-center">
        <h2 className="header">Jann</h2>
        <Select
          className="general-input-field m-right-30"
          name="subscriptionType"
          value={currentFinancialYear}
          style={{ width: "200px" }}
          onChange={(e) => {
            setCurrentFinancialYear(e)
          }}
          bordered={false}
          size={"large"}
        >
          {getFinancialYears().map((val) => {
            return <Option key={val.value} value={val.value}>{val.label}</Option>;
          })}
        </Select>
      </div>
      <div className="app-main-content-container">
        <Tabs
          defaultActiveKey={"1"}
          size="large"
        >
          <TabPane tab="Users" key="1">
            <JannUsers />
          </TabPane>
          <TabPane tab="Rent Receipt" key="2">
            <RentReceipt />
          </TabPane>
          <TabPane tab="12bb" key="3">
            <BB12 financialYear={currentFinancialYear} />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default DocumentCheck;
