import { useState } from "react";
import { Modal, Layout, Button, Progress } from "antd";
import { deductSubscription } from "../../../apis/admin";

const { Content } = Layout;

const SubscriptionProgressModal = ({
  modalVisible,
  allOrg,
  setModalVisible,
  updateSubscriptionData
}) => {
  const [modalData, setModalData] = useState({
    success: 0,
    fail: 0,
    total: allOrg.length,
  });
  const [state, setState] = useState(0);
  const [responseCount, setResponseCount] = useState(0);

  const resetModal = () => {
    setModalData({ ...modalData, success: 0, fail: 0 });
    setResponseCount(0);
    setState(0);
    setModalVisible(false);
  };

  const submitModal = async () => {
    setState(1);
    let success = 0;
    let fail = 0;
    let final = 1;
    for (let i = 0; i < allOrg.length; i++) {
      let response = await deductSubscription({ PAN: allOrg[i].PAN });
      setResponseCount(final);
      final++;
      if (response.data.success) {
        success++;
      } else {
        fail++;
      }
    }
    setModalData({ ...modalData, success: success, fail: fail });
    setResponseCount(0);
    setState(2);
    updateSubscriptionData();
  };

  return (
    <Modal
      centered
      footer={null}
      width={400}
      onCancel={resetModal}
      style={{ padding: 20 }}
      visible={modalVisible}
    >
      <Content>
        {state === 0 ? (
          <div style={{ padding: 20 }}>
            <p className="app-label-1">Total Org Available: {allOrg.length}</p>
            <Button type="primary" onClick={submitModal} size="large">
              Subscription Deduction
            </Button>
          </div>
        ) : state === 1 ? (
          <div style={{ padding: 20 }}>
            <Progress
              percent={Math.round(
                (responseCount /
                  allOrg.length) *
                  100
              )}
              size="small"
              status="active"
            />{" "}
          </div>
        ) : (
          <div style={{ padding: 20 }}>
              <h3 className="app-heading-3">Result</h3>
            <p className="app-label-1">Total Org Available: {allOrg.length}</p>
            <p className="app-label-1"  style={{ color: "green", fontWeight: 600 }}>Total Success: {modalData.success}</p>
            <p className="app-label-1"  style={{ color: "red", fontWeight: 600 }}>Total Fail: {modalData.fail}</p>

          </div>
        )}
      </Content>
    </Modal>
  );
};

export default SubscriptionProgressModal;
