import { Spin, Switch } from "antd";
import { setAccountOriginalityStatus } from "../../../../../apis/admin";
import { useEffect, useState } from "react";

const OriginalityCard = ({ orgId, original }) => {

    let [originalState, setOriginalState] = useState(false)
    let [apiLoading, setApiLoading] = useState(false)

    useEffect(() => {
        if (original)
            setOriginalState(original)
    }, [original])

    let onChange = async (status) => {
        try {
            setApiLoading(true)
            setOriginalState(status)
            let response = await setAccountOriginalityStatus({ orgId, original: status })
            if (response.status !== 200) {
                setOriginalState(!status)
            }
        } catch (error) {
            setOriginalState(!status)
        } finally {
            setApiLoading(false)
        }
    }

    return (
        <div className="originality-container">
            <Spin spinning={apiLoading}>
                <div className={"flex"}>
                    <p className="heading">Account originality</p>
                    <div className={"switch-container"}>
                        <p className={"switch-container-text-left"}>False</p>
                        <Switch onChange={(status) => { onChange(status) }} checked={originalState ? true : false} />
                        <p className={"switch-container-text-right"}>True</p>
                    </div>
                </div>
            </Spin>
        </div>
    )
}

export default OriginalityCard