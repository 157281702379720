import { Tabs } from "antd";
import { HeaderComponent } from "../Common";
import { NormalButton } from "../ComponentLibrary";
import { Content } from "antd/lib/layout/layout";
import { useState, useEffect } from "react";
import CreateNewMessage from "./components/CreateNewMessage";
import SentMessage from "./components/SentMessage";
import { getBroadcastByStatus } from "../../apis/admin";
import broadcastLogo from "../../assets/icons/activeIcons/activeBroadcastLogo.svg"

const { TabPane } = Tabs;

const BroadcastMessage = () => {

    const [pageState, setPageState] = useState(1)
    const [allBroadcastMessage, setAllBroadCastMessage] = useState([])
    const [draftObj, setDraftObj] = useState({});
    const [isUpdateCall, setIsUpdateCall] = useState(false);

    const getBroadcast = async () => {
        let res = await getBroadcastByStatus(0);
        if (res.status === 200) {
            setAllBroadCastMessage(res.data)
        }
    }

    useEffect(() => {
        if (pageState === 1) {
            getBroadcast();
        }
    }, [pageState])

    return (
        <div>
            <section className="site-layout">
                <HeaderComponent name="Broadcast" icon={broadcastLogo} />
                <Content className="app-main-content-container app-white-card">
                    {
                        pageState === 1 && (
                            <div>
                                <div className="paper-header-container">
                                    <h1 className="paper-heading">All Broadcasts</h1>
                                    <div className="paper-heading-action">
                                        <NormalButton
                                            type={8}
                                            buttonText="Create New"
                                            onClick={() => setPageState(2)}
                                        />
                                    </div>
                                </div>
                                <div className="m-top-36">
                                    <Tabs
                                        size="large"
                                        tabPosition="left"
                                    >
                                        <TabPane tab="Sent" key="1">
                                            <SentMessage
                                                allBroadcastMessage={allBroadcastMessage.filter((data) => data.messageStatus === 2).sort((a, b) => a.updatedAt > b.updatedAt ? -1 : 1)}
                                                statusType={2}
                                                getBroadcast={getBroadcast}
                                            />
                                        </TabPane>
                                        <TabPane tab="Drafts" key="2">
                                            <SentMessage
                                                allBroadcastMessage={allBroadcastMessage.filter((data) => data.messageStatus === 0).sort((a, b) => a.updatedAt > b.updatedAt ? -1 : 1)}
                                                statusType={0}
                                                setDraftObj={setDraftObj}
                                                setIsUpdateCall={setIsUpdateCall}
                                                setPageState={setPageState}
                                                getBroadcast={getBroadcast}
                                            />
                                        </TabPane>
                                    </Tabs>
                                </div>
                            </div>
                        )
                    }
                    {
                        pageState === 2 && (
                            <CreateNewMessage
                                setPageState={setPageState}
                                draftObj={draftObj}
                                setDraftObj={setDraftObj}
                                isUpdateCall={isUpdateCall}
                                setIsUpdateCall={setIsUpdateCall}
                            />
                        )
                    }
                </Content>
            </section>
        </div>
    )
}

export default BroadcastMessage;