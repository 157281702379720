
import { combineReducers } from 'redux';
import auth from './modules/auth';
import admin from "./modules/admin";

/**
 * Creates the main reducer with the dynamically injected ones
 */
export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    auth: auth.getReducer(),
    admin: admin.getReducer(),
    ...injectedReducers,
  });

  // Wrap the root reducer and return a new root reducer with router state
  return rootReducer;
}
