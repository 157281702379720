import { CheckCircleOutlined, IssuesCloseOutlined } from "@ant-design/icons";
import { Input, Skeleton, Table, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { getAllLogsByUserID } from "../../apis/auth";
import config from "../../utils/config";
const { Title } = Typography;
const { categoriesOfLogs } = config;
function Logs({ user }) {
    const [allLogs, setAllLogs] = useState([]);
    const [duplicateLogs, setDuplicateLogs] = useState([]);
    const [apiLoading, setApiLoading] = useState(false);

    useEffect(() => {
        setDuplicateLogs(allLogs.sort((a, b) => a.dateTime < b.dateTime).reverse());
    }, [allLogs])

    const onSearch = (value) => {
        let SearchParams = value.toLowerCase();
        if (SearchParams.trim() !== "") {
            let newOrganization = [];
            newOrganization = allLogs.filter((val) => {
                return val['message'].toString().toLowerCase().includes(SearchParams)
            });
            setDuplicateLogs([...newOrganization]);
        } else {
            setDuplicateLogs(allLogs);
        }
    }
    useEffect(() => {
        if (user) {
            setApiLoading(true)
            getAllLogsByUserID(user._id)
                .then((res) => { setAllLogs(res.data); setDuplicateLogs(res.data) })
                .catch((err) => {
                    setAllLogs([]);
                    setDuplicateLogs([])
                });
            setApiLoading(false)
        }
    }, [user]);

    const columns = [
        {
            title: "Activity ID",
            dataIndex: "_id",
            key: "message",
            render: (id) => {
                return (
                    <b>
                        <code>{id}</code>
                    </b>
                );
            },
        },
        {
            title: "Activity",
            dataIndex: "message",
            key: "message",
            render: (message) => {
                return (
                    <span>
                        {message && message.length > 50
                            ? message.substr(0, 50) + "..."
                            : message || ""}
                    </span>
                );
            },
        },
        {
            title: "Category",
            dataIndex: "categoryDetail",
            key: "categoryDetail",
            filters: [...Object.values(categoriesOfLogs).map(item => { return { text: <span>{item}</span>, value: item } })],
            onFilter: (value, record) => record.categoryDetail.startsWith(value),
            filterSearch: true,
        },
        {
            title: "Date & Time",
            dataIndex: "dateTime",
            key: "dateTime",
            render: (dateTime) =>
                dateTime
                    ? new Date(dateTime).toDateString() +
                    " - " +
                    new Date(dateTime).toTimeString()
                    : "",
        },
        { title: "IP Address", dataIndex: "ipAddress", key: "ipAddress" },
        {
            title: "Activity Status",
            dataIndex: "mark",
            key: "mark",
            render: (mark) =>
                mark === 0 ? (
                    <CheckCircleOutlined title='Trustful' style={{ color: "green" }} />
                ) : (
                    <IssuesCloseOutlined title='Suspicious' style={{ color: "red" }} />
                ),
        },
    ];

    return (
        <div className='logs-section'>
            <Skeleton loading={apiLoading} active paragraph={10}>
                <div className="flex justify-between">
                    <div>
                        <Title level={2}>Logs</Title>
                        <p>All Log History</p>
                    </div>
                    <div>
                        <Input.Search
                            onPressEnter={(e) => { onSearch(e.target.value) }}
                            onSearch={(value) => onSearch(value)}
                            onClear
                            enterButton={true}
                            className="search-field"
                            placeholder="Activity Search ..."
                            allowClear
                        />
                    </div>
                </div>
                <div>
                    <Table
                        columns={columns}
                        expandable={{
                            expandedRowRender: (record) => (
                                <p style={{ margin: 0 }}>{record.message || ""}</p>
                            ),
                            rowExpandable: (record) =>
                                record.message && record.message.length >= 50,
                        }}
                        dataSource={duplicateLogs}
                    />
                </div>
            </Skeleton>
        </div>
    );
}

export default Logs;
