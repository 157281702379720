import { Avatar, Card, Drawer, Spin, Table, Tabs } from "antd";
import TabPane from "antd/lib/tabs/TabPane";
import { useEffect, useState } from "react";
import { getAllJannForm12BBDocument, getTransactionForUser } from "../../../apis/jann";
import config from "../../../utils/config";

const { deductionObjectList } = config;

const getStatus = (key) => {
  if (key === 1) {
    return 'Paid';
  } else if (key === 2) {
    return 'Signed';
  }
  return 'Unsigned';
};

const getEmployerStatus = (key) => {
  if (key === 1) {
    return 'Acknowledged By HR';
  } else if (key === 2) {
    return 'Accepted By HR';
  } else if (key === 3) {
    return 'Rejected By HR';
  }
  return 'Pending At HR';
};




const BB12 = ({ financialYear }) => {
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const [listCount, setListCount] = useState(0);

  const [currDetails, setCurrDetails] = useState({});
  const [dataSource, setDataSource] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (financialYear) {
      setLoader(true)
      getAllJannForm12BBDocument(financialYear).then(res => {
        if (res.data.success) {
          setDataSource(res.data.data);
        } else {
          setDataSource([]);
        }
      }).catch(err => {
        setDataSource([]);
      });
      setLoader(false)
    }
  }, [financialYear]);


  const GetIcon = ({ documentKey }) => {
    const colors = { pdf: "#EB455F", jpeg: "#BAD7E9", png: "#0081B4" };
    const splitter = typeof documentKey === "string" ? documentKey.split(".")[1] : "pdf";
    return (
      <Avatar
        shape='square'
        style={{
          backgroundColor: colors[splitter],
          borderRadius: "5px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
        size={"large"}>
        <span style={{ color: "white", fontSize: '12px' }}>{splitter}</span>
      </Avatar>
    );
  };

  const DocumentView = ({ sectionName, documentKey, status, verified, id }) => {
    return (
      <Card key={documentKey} style={verified === 3 ? { opacity: 0.5 } : {}}>
        <div className='flex justify-between align-center'>
          <div className='flex justify-between align-center'>
            <GetIcon documentKey={documentKey} />
            <div className='flex justify-between flex-direction-column'>
              <h4 style={verified === 3 ? { textDecoration: "line-through" } : {}} className='m-left-10'>{sectionName}</h4>
              <p className='m-left-10'>Status: {getStatus(status)} | {getEmployerStatus(verified)}</p>
            </div>
          </div>
        </div>
      </Card >
    );
  };


  const columns = [
    {
      width: "20%",
      title: "User details",
      dataIndex: "id",
      key: "name",
      render: (key, data) => (
        <div className="user-details-container">
          <div className="user-name-and-email">
            <p className="user-name">{data?.name}</p>
            <p className="user-email">{data?.email}</p>
          </div>
        </div>
      ),
    },
    {
      title: "Sent to (Mail ID)",
      width: "10%",
      dataIndex: "organisationEmail",
      key: "email",
    },
    {
      width: "10%",
      dataIndex: "id",
      key: "ViewMore",
      render: (key, data) => (
        <button
          className="link"
          onClick={() => {
            setCurrDetails(data);
            showDrawer(true);
          }}
        >
          View more
        </button>
      ),
    },
  ];

  const PaymentHistory = () => {
    const [data, setData] = useState([]);
    useEffect(() => {
      getTransactionForUser(currDetails.id).then(res => {
        if (res.data.success) {
          setData(res.data.data);
        } else {
          setData([]);
        }
      }).catch(err => {
        setData([])
      })
    }, [])
    const columnsArray = [
      {
        title: "Order ID",
        dataIndex: "item",
        key: "item",
        render: (key, data) => {
          return (
            <span style={{ color: "black" }}>
              {data.orderDetails ? data.orderDetails?.order_id : ""}
            </span>
          )
        }
      },
      {
        title: "Order Amount",
        dataIndex: "paymentSessionID",
        key: "documentKeys",
        render: (key, data) => {
          return (<span>
            {data.orderDetails ? data.orderDetails?.order_amount : 0}
          </span>)
        }
      },
      {
        title: "Payment Status",
        dataIndex: "paymentOrderID",
        key: "paymentOrderID",
        render: (key, data) => {
          return (<span style={data.orderDetails && data.orderDetails?.order_status === "PAID" ? { color: "green" } : { color: "red" }}>
            {data.orderDetails && data.orderDetails?.order_status === "PAID" ? "Paid" : "Failed"}
          </span>)
        }
      },
    ];
    return (
      <Table dataSource={data} columns={columnsArray} />
    )
  }

  const DocumentSection = () => {
    return (<div style={{ padding: "10px 20px" }}>
      <div>
        <h2 className='m-10'>Form12BB Document</h2>
        {
          currDetails.form12BB ? <DocumentView
            sectionName={"Form12BB Document"}
            documentKey={currDetails.form12BB.key}
            status={currDetails.form12BB.status}
            verified={currDetails.form12BB.verified}
            id={currDetails.id}
          /> : ""
        }
      </div>
      <div>
        <h2 className='m-10'>Leave Travel Concession </h2>
        {
          currDetails.leaveTravelObject && currDetails.leaveTravelObject.documents ? currDetails.leaveTravelObject.documents.map(item => {
            return (<DocumentView
              key={item.key}
              sectionName={item.name}
              documentKey={item.key}
              status={item.status}
              verified={item.verified}
              id={currDetails.id}
            />)
          }) : ""
        }
      </div>
      <div>
        <h2 className='m-10'>Deduction Of Interest</h2>
        {
          currDetails.deductionsObject && currDetails.deductionsObject.documents ? currDetails.deductionsObject.documents.map(item => {
            return (<DocumentView
              key={item.key}
              sectionName={item.name}
              documentKey={item.key}
              status={item.status}
              verified={item.verified}
              id={currDetails.id}
            />)
          }) : ""
        }
      </div>
      <div>
        <h2 className='m-10'>Declarations </h2>
        {
          Object.keys(deductionObjectList).map(key => {
            return (
              Object.keys(deductionObjectList[key].subSections).map(item => {
                if (currDetails.declarations && currDetails.declarations[item] && currDetails.declarations[item].documents?.length) {
                  return (<div key={item}>
                    <h3 className="m-left-10 m-top-8" style={{ fontSize: "18px" }}>{deductionObjectList[key]?.sectionName}</h3>
                    <div className="m-left-20 m-top-10">
                      <h4>{deductionObjectList[key].subSections[item]}</h4>
                      <div className="m-top-10">
                        {
                          currDetails.declarations[item] ? currDetails.declarations[item].documents.map(doc => {
                            return (<DocumentView
                              key={doc.key}
                              sectionName={doc.name}
                              documentKey={doc.key}
                              status={doc.status}
                              verified={doc.verified}
                              id={currDetails.id}
                            />)
                          }) : ""
                        }
                      </div>
                    </div>
                  </div>)
                } else {
                  return ""
                }
              })
            )
          })
        }

      </div>
    </div>)
  }


  return (
    <div className="app-white-card active-client-management-card">
      <Spin spinning={loader}>
        <div className="paper-header-container">
          <h3 className="paper-heading">List ({listCount})</h3>
        </div>
        <Table
          columns={columns}
          dataSource={dataSource}
          onChange={(pagination, filters, sorter, extra) => {
            setListCount(extra.currentDataSource.length);
          }}
          pagination={{ pageSize: 6 }}
        />
        <Drawer
          title={
            <div>
              <p>{currDetails.name}’s 12bb summary</p>
              <p>{currDetails.email}</p>
            </div>
          }
          width={700}
          placement="right"
          onClose={onClose}
          open={open}
          className="drawer"
        >
          <Tabs
            defaultActiveKey={"1"}
            size="large"
          >
            <TabPane tab="Documents" key="1">
              <DocumentSection />
            </TabPane>
            <TabPane tab="Payment History" key="2">
              <PaymentHistory />
            </TabPane>
          </Tabs>
        </Drawer>
      </Spin>
    </div>
  )
};

export default BB12;
