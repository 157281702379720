import "./status.less";
import { verifyError, verifyNo, verifyYes } from "../../../assets/common/verifiedLogos"

const Status = ({ type, text, fontSize, padding, margin, block }) => {

    let imgStyles = {
        height: fontSize,
        width: fontSize,
    }

    let styles = {
        fontSize: fontSize,
        padding: padding ? `${padding[0]}px ${padding[1]}px` : "",
        margin: margin ? `${margin[0]}px ${margin[1]}px` : "",
        display: block ? "block" : "",
    }

    return (
        <span styles={styles} className={`status status-${type}`}>
            <img
                src={
                    type === 1
                        ? verifyNo
                        : type === 2
                            ? verifyYes
                            : verifyError
                }
                styles={imgStyles}
                className="status-img"
                alt="current status logo"
            >
            </img>
            <span>
                {text}
            </span>
        </span>
    )
}

export default Status;