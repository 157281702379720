import { Table } from "antd";
import { getSubscribeLeads } from "../../../apis/admin";
import { useEffect, useState } from "react";
import moment from "moment";

const SubscriberLeads = () => {
  const [sub, setSub] = useState([]);
  const [listCount, setListCount] = useState(0);

  useEffect(() => {
    getSubscribeLeads()
      .then((res) => {
        if (res.data) {
          setSub(res.data);
        } else {
          setSub([]);
        }
      })
      .catch((err) => {
        setSub([]);
      });
  }, []);
  // useEffect(() => {
  //   getSubscribeLeads().then((res) => {
  //     console.log(res);
  //   });
  // });

  const columns = [
    {
      width: "30%",
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (email, data) => (
        <div className="sub-list-email">
          <div className="user-email">
            <p>{data.email}</p>
          </div>
        </div>
      ),
    },
    {
      title: "Date",
      width: "10%",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => (
        <span className="date-of-joining">
          {moment(createdAt).format("MMMM Do YYYY")}
        </span>
      ),
    },
    {
      title: "Time",
      width: "10%",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => (
        <span className="time-of-joining">
          {moment(createdAt).format("hh: mm a")}
        </span>
      ),
    },
  ];

  return (
    <div className="app-white-card active-client-management-card">
      <div className="paper-header-container">
        <h3 className="paper-heading">List ({listCount})</h3>
      </div>
      <Table
        columns={columns}
        pagination={{ pageSize: 8 }}
        dataSource={sub}
        onChange={(pagination, filters, sorter, extra) => {
          setListCount(extra.currentDataSource.length);
        }}
      />
    </div>
  );
};

export default SubscriberLeads;
