import { Row, Col } from "antd";
import NumberCards from "./components/numberCards.js";
import ChartsCards from "./components/chartsCards.js";
import { HeaderComponent } from '../Common'
import activeDashboard from "../../assets/icons/activeIcons/activeDashboard.svg"
import './Dashboard.less';

const Dashboard = (props) => {
    return (
        <div className="site-layout">
            <HeaderComponent icon={activeDashboard} name={"Organisation Dashboard"} />
            <div className="app-main-content-container">
                <Row>
                    <Col xs={24} sm={24} md={17} lg={17}>
                        <NumberCards />
                        <Row gutter={[20, 20]} className="chart-cards-container" >
                            <Col xs={24} sm={24} md={24} lg={12}>
                                <ChartsCards
                                    heading="TDS Contribution"
                                    currency="INR"
                                    input="tds"
                                    type={"bar"}
                                />
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12}>
                                <ChartsCards
                                    type={"area"}
                                    input="pf"
                                    heading="PF Contribution"
                                    currency="INR"
                                />

                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24} sm={24} md={6} lg={6} offset={1}>
                    </Col>
                </Row>
            </div>
        </div>
    )
}



export default Dashboard;
