/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Col, Modal, Row, Skeleton, Button, Input, notification, message, Spin } from "antd";
import payoutEmpty from "../../../assets/icons/AllowanceEmpty.svg";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { getDocumentURL, updateOrgInstance } from "../../../apis/admin";
import Pen from "../../../assets/icons/Pen.svg";
import Cross from "../../../assets/icons/Cross.svg";
import { useEffect, useState } from "react";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const Payouts = ({ user, payout, getOrganisationData }) => {
    const [documents, setDocuments] = useState("");
    const [modalstatus, setModalStatus] = useState(false);
    const [format, setFormat] = useState("");
    const [pageNumber, setPageNumber] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [updateDataModal, setUpdateDataModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const [OrgDetails, setOrgDetails] = useState({
        clientId: "",
        secretId: "",
        cashfreeAccount: "",
        cashfreeIFSC: "",
        publicKey: "",
    });

    useEffect(() => {
        setOrgDetails({
            clientId: payout.clientId || "",
            secretId: payout.secretId || "",
            cashfreeAccount: payout.cashfreeAccount || "",
            cashfreeIFSC: payout.cashfreeIFSC || "",
            publicKey: payout.publicKey || "",
        });
    }, [payout]);

    const getDocumentURLFromKey = async (key) => {
        setFormat(key.split(".").pop());
        let document = await getDocumentURL({ Key: key });
        setDocuments(document.data);
    };
    const increaseCurrentPage = () => {
        let currPage = currentPage;
        currPage = currPage < pageNumber ? currPage + 1 : currPage;
        setCurrentPage(currPage);
    };

    const decreaseCurrentPage = () => {
        let currPage = currentPage;
        currPage = currPage > 1 ? currPage - 1 : currPage;
        setCurrentPage(currPage);
    };

    const updateCashfreeDetails = async () => {
        if (!OrgDetails.cashfreeAccount) {
            message.error("Please enter cashfree account number")
            return
        }
        if (!OrgDetails.cashfreeIFSC) {
            message.error("Please enter cashfree IFSC Code")
            return
        }
        if (!OrgDetails.secretId) {
            message.error("Please enter cashfree secret key")
            return
        }
        if (!OrgDetails.clientId) {
            message.error("Please enter cashfree client ID")
            return
        }
        if (!OrgDetails.publicKey) {
            message.error("Please enter cashfree public key")
            return
        }
        setLoading(true);
        let res = await updateOrgInstance({ ...OrgDetails, organisationId: user.organisationId })
        if (res.data.success) {
            notification.success({ message: "Successfully Updated Cashfree Details" })
        }
        else {
            notification.error({ message: "Sorry Unable To Update The Cashfree Details" })
        }
        await getOrganisationData(user.PAN);
        setLoading(false);
        setUpdateDataModal(false);

    }
    const DocumentAllowances = ({ data, type }) => {
        return (
            <div className="document-container">
                <div style={{ display: "flex", alignItems: "center" }}>
                    <a
                        onClick={() => {
                            setModalStatus(true);
                            getDocumentURLFromKey(data.Key);
                        }}
                        className="file-typeHeader"
                    >
                        {data.Key.split(".").pop()}
                    </a>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <span>{type}</span>
                        <span className="date-contianer">
                            {new Date(data.createdAt).toDateString()}
                        </span>
                    </div>
                </div>
            </div>
        );
    };
    return (
        <div className="paper-wrapper w-full mt-3">
            {Object.keys(payout).length === 0 ? (
                <div className="flex justify-center align-center w-full">
                    <img alt="img" className="payout-state-0-image" src={payoutEmpty} />
                </div>
            ) : (
                <div className="w-full">
                    <div className="w-full flex justify-between align-center">
                        <p className="app-heading-2">Payout Information</p>
                        <Button
                            type="link"
                            className="edit-card-button"
                            onClick={() => setUpdateDataModal(true)}
                        >
                            <img alt="img" src={Pen} />
                            Update
                        </Button>
                        <Modal
                            className="subscription-modal"
                            visible={updateDataModal}
                            onCancel={() => setUpdateDataModal(false)}
                            centered
                            footer={null}
                            closable={false}
                        >
                            <Spin spinning={loading} size="large">
                                <div className="modal-header-section">
                                    <p className="modal-section-heading">Update Payout Information</p>
                                    <Button
                                        type="link"
                                        className="modal-close"
                                        onClick={() => setUpdateDataModal(false)}
                                    >
                                        <img alt="img" src={Cross}></img>
                                    </Button>
                                </div>
                                <div className="modal-body-section">
                                    <div className="subscription-type">
                                        <p className="subscription-type-heading">Cashfree Account</p>
                                        <Input
                                            className="general-input-field"
                                            style={{ width: "97%" }}
                                            name="subscriptionPrice"
                                            value={OrgDetails.cashfreeAccount}
                                            onChange={(e) => {
                                                let data = e.target.value
                                                setOrgDetails({
                                                    ...OrgDetails,
                                                    cashfreeAccount: data,
                                                });
                                            }}
                                            size={"large"}
                                        />
                                    </div>
                                    <div className="subscription-pricing-info">
                                        <h3 className="subscription-pricing-heading">
                                            Cashfree IFSC
                                        </h3>
                                        <Input
                                            className="general-input-field"
                                            style={{ width: "97%" }}
                                            name="subscriptionPrice"
                                            value={OrgDetails.cashfreeIFSC}
                                            onChange={(e) => {
                                                let data = e.target.value.toUpperCase()
                                                setOrgDetails({
                                                    ...OrgDetails,
                                                    cashfreeIFSC: data,
                                                });
                                            }}
                                            size={"large"}
                                        />
                                    </div>
                                    <div className="subscription-pricing-info">
                                        <h3 className="subscription-pricing-heading">
                                            Cashfree Secret Key
                                        </h3>
                                        <Input
                                            className="general-input-field"
                                            style={{ width: "97%" }}
                                            name="subscriptionPrice"
                                            value={OrgDetails.secretId}
                                            onChange={(e) => {
                                                let data = e.target.value
                                                setOrgDetails({
                                                    ...OrgDetails,
                                                    secretId: data,
                                                });
                                            }}
                                            size={"large"}
                                        />
                                    </div>
                                    <div className="subscription-pricing-info">
                                        <h3 className="subscription-pricing-heading">
                                            Cashfree Client ID
                                        </h3>
                                        <Input
                                            className="general-input-field"
                                            style={{ width: "97%" }}
                                            name="subscriptionPrice"
                                            value={OrgDetails.clientId}
                                            onChange={(e) => {
                                                let data = e.target.value
                                                setOrgDetails({
                                                    ...OrgDetails,
                                                    clientId: data,
                                                });
                                            }}
                                            size={"large"}
                                        />
                                    </div>
                                    <div className="subscription-pricing-info">
                                        <h3 className="subscription-pricing-heading">
                                            Cashfree Public Key File Name
                                        </h3>
                                        <Input
                                            className="general-input-field"
                                            style={{ width: "97%" }}
                                            name="subscriptionPrice"
                                            value={OrgDetails.publicKey}
                                            onChange={(e) => {
                                                let data = e.target.value
                                                setOrgDetails({
                                                    ...OrgDetails,
                                                    publicKey: data,
                                                });
                                            }}
                                            size={"large"}
                                        />
                                    </div>
                                    <div className="subscription-button">
                                        <Button
                                            className="submit-button"
                                            style={{
                                                width: "30%",
                                                marginTop: "20px",
                                                marginRight: "20px",
                                                padding: "0px 24px",
                                            }}
                                            onClick={updateCashfreeDetails}
                                        >
                                            {" "}
                                            Save{" "}
                                        </Button>
                                    </div>
                                </div>
                            </Spin>
                        </Modal>
                    </div>
                    <Row gutter={[40, 2]}>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <div className="employee-info">
                                <h3 className="headings fields-heading">Business Email</h3>
                                <p className="profile-data-display">{payout.businessEmail}</p>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <div className="employee-info">
                                <h3 className="headings fields-heading">Business Phone</h3>
                                <p className="profile-data-display">{payout.businessNumber}</p>
                            </div>
                        </Col>

                        <Col xs={24} sm={24} md={12} lg={12}>
                            <div className="employee-info">
                                <h3 className="headings fields-heading">Bank Name</h3>
                                <p className="profile-data-display">{payout.bankName}</p>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <div className="employee-info">
                                <h3 className="headings fields-heading">IFSC Code</h3>
                                <p className="profile-data-display">{payout.IFSC}</p>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <div className="employee-info">
                                <h3 className="headings fields-heading">Account Number</h3>
                                <p className="profile-data-display">{payout.bankAccountNo}</p>
                            </div>
                        </Col>

                        {/* Cashfree details start here */}

                        <Col xs={24} sm={24} md={12} lg={12}>
                            <div className="employee-info">
                                <h3 className="headings fields-heading">ClientId</h3>
                                <p className="profile-data-display">
                                    {payout.clientId || "N/A"}
                                </p>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <div className="employee-info">
                                <h3 className="headings fields-heading">Secret key</h3>
                                <p className="profile-data-display">
                                    {payout.secretId || "N/A"}
                                </p>
                            </div>
                        </Col>

                        <Col xs={24} sm={24} md={12} lg={12}>
                            <div className="employee-info">
                                <h3 className="headings fields-heading">Cashfree Account</h3>
                                <p className="profile-data-display">
                                    {payout.cashfreeAccount || "N/A"}
                                </p>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <div className="employee-info">
                                <h3 className="headings fields-heading">Cashfree IFSC</h3>
                                <p className="profile-data-display">
                                    {payout.cashfreeIFSC || "N/A"}
                                </p>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <div className="employee-info">
                                <h3 className="headings fields-heading">Public Key</h3>
                                <p className="profile-data-display">
                                    {payout.publicKey || "N/A"}
                                </p>
                            </div>
                        </Col>

                        {/* Cashfree details end */}
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <div className="employee-info">
                                <h3 className="headings fields-heading">Address</h3>
                                <p className="profile-data-display">
                                    {payout.addess}, {payout.city}, {payout.state},{" "}
                                    {payout.pinCode}
                                </p>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <DocumentAllowances
                                type="Account Proof"
                                data={payout.accountProof}
                            />
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <DocumentAllowances
                                type="Certificate For Business Incorporation"
                                data={payout.Cert_incorporation_business}
                            />
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <DocumentAllowances type="Other Uploads" data={payout.uploads} />
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <DocumentAllowances
                                type="GST Certificate"
                                data={payout.GSTCertificate}
                            />
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <DocumentAllowances
                                type="Director PAN"
                                data={payout.directorPAN}
                            />
                        </Col>
                    </Row>
                    <Modal
                        onCancel={() => {
                            setDocuments("");
                            setModalStatus(false);
                            setFormat("");
                            setCurrentPage(1);
                            setPageNumber(1);
                        }}
                        visible={modalstatus}
                        footer={null}
                        centered
                        width={800}
                    >
                        <Skeleton
                            loading={!documents}
                            active
                            avatar
                            paragraph={{ rows: 5 }}
                        >
                            <a download target="_blank" href={documents}>
                                Download Document
                            </a>
                            {format === "pdf" ? (
                                <>
                                    <Document
                                        className="document-viewer"
                                        file={documents}
                                        onLoadSuccess={(pn) => setPageNumber(pn.numPages)}
                                    >
                                        <Page pageNumber={currentPage} />
                                    </Document>
                                    <div className="pdf-document-action">
                                        <Button onClick={decreaseCurrentPage} type="default">
                                            <LeftOutlined />
                                        </Button>
                                        <p>
                                            Page {currentPage} of {pageNumber}
                                        </p>
                                        <Button onClick={increaseCurrentPage} type="default">
                                            <RightOutlined />
                                        </Button>
                                    </div>
                                </>
                            ) : (
                                <img alt="img" src={documents} className="document-display" />
                            )}
                        </Skeleton>
                    </Modal>
                </div>
            )}
        </div>
    );
};


export default Payouts;
