/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from "react";
import { HeaderComponent } from "../Common";
import activeDashboard from "../../assets/icons/activeIcons/activeDashboard.svg";
import "./newCampaign.less";
import { Input, Row, Col, Upload, Checkbox, notification } from "antd";
import { PictureOutlined, DeleteOutlined } from "@ant-design/icons";
import { NormalButton } from "../ComponentLibrary";
import { newCampaignStart, removeAdsDocument, updateCampaign } from "../../apis/admin";
import config from "../../utils/config";
import { withRouter } from "react-router-dom";

const { Dragger } = Upload;

const NewCampaign = ({ history }) => {
  const { isUpdate, objData } = history.location.state;

  const [newCampaign, setNewCampaign] = useState({
    nameOfBusiness: "",
    destinationLink: "",
    ad: { title: "", headline: "", description: "", graphicsUrl: "", Key: "" },
    targeting: null,
    status: null,
  });
  const [checkedOptions, setCheckedOptions] = useState([]);

  useEffect(() => {
    if (isUpdate) {
      let tempObj = {
        nameOfBusiness: objData.nameOfBusiness,
        destinationLink: objData.destinationLink,
        ad: objData.ad,
        targeting: objData.targeting,
        status: objData.status,
      };
      setNewCampaign(tempObj);
      let targetArray = [];
      let targeting = objData.targeting;
      if (
        targeting === 0 ||
        targeting === 1 ||
        targeting === 4 ||
        targeting === 5
      ) {
        targetArray.push("1");
      }
      if (
        targeting === 0 ||
        targeting === 3 ||
        targeting === 5 ||
        targeting === 6
      ) {
        targetArray.push("2");
      }
      if (
        targeting === 0 ||
        targeting === 2 ||
        targeting === 4 ||
        targeting === 6
      ) {
        targetArray.push("3");
      }
      setCheckedOptions([...targetArray]);
    }
  }, [isUpdate, objData.ad, objData.destinationLink, objData.nameOfBusiness, objData.status, objData.targeting]);

  const saveAndClose = async () => {
    let tempNewCampaign = newCampaign;
    tempNewCampaign.status = 0;
    setNewCampaign({
      ...tempNewCampaign,
    });
    if (
      !tempNewCampaign.nameOfBusiness ||
      !tempNewCampaign.destinationLink ||
      !tempNewCampaign.ad.title ||
      !tempNewCampaign.ad.headline ||
      !tempNewCampaign.ad.description ||
      !tempNewCampaign.ad.graphicsUrl ||
      !tempNewCampaign.targeting === null
    ) {
      notification.error({ message: "Please enter every details" });
      return;
    }
    if (isUpdate) {
      let adId = objData.adId;
      let response = await updateCampaign(adId, newCampaign);
      if (response.data.success) {
        notification.success({
          message: "Campaign has been edited successfully",
        });
        history.push("/admin/manageAds");
      } else {
        notification.error({
          message: "Unable edit campaign.Please try again!!",
        });
      }
    } else {
      let response = await newCampaignStart(tempNewCampaign);
      if (response.data.success) {
        setNewCampaign({
          nameOfBusiness: "",
          destinationLink: "",
          ad: {
            title: "",
            headline: "",
            description: "",
            graphicsUrl: "",
            Key: "",
          },
          targeting: null,
          status: null,
        });
        setCheckedOptions([]);
        notification.success({ message: "New Campaign created successfully" });
        history.push("/admin/manageAds");
      } else {
        notification.error({
          message: "Unable to create new campaign.Please try again!!",
        });
      }
    }
  };

  const launchCampaign = async () => {
    let tempNewCampaign = newCampaign;
    tempNewCampaign.status = 1;
    setNewCampaign({
      ...tempNewCampaign,
    });
    if (
      !tempNewCampaign.nameOfBusiness ||
      !tempNewCampaign.destinationLink ||
      !tempNewCampaign.ad.title ||
      !tempNewCampaign.ad.headline ||
      !tempNewCampaign.ad.description ||
      !tempNewCampaign.ad.graphicsUrl ||
      !tempNewCampaign.targeting === null
    ) {
      notification.error({ message: "Please enter every details" });
      return;
    }
    if (isUpdate) {
      let adId = objData.adId;
      let response = await updateCampaign(adId, newCampaign);
      if (response.data.success) {
        notification.success({
          message: "Campaign has been edited successfully",
        });
        history.push("/admin/manageAds");
      } else {
        notification.error({
          message: "Unable edit campaign.Please try again!!",
        });
      }
    } else {
      let response = await newCampaignStart(tempNewCampaign);
      if (response.data.success) {
        setNewCampaign({
          nameOfBusiness: "",
          destinationLink: "",
          ad: { title: "", headline: "", description: "", graphicsUrl: "" },
          targeting: null,
          status: null,
        });
        setCheckedOptions([]);
        notification.success({ message: "New Campaign created successfully" });
        history.push("/admin/manageAds");
      } else {
        notification.error({
          message: "Unable to create new campaign.Please try again!!",
        });
      }
    }
  };

  function onChange(checkedValues) {
    setCheckedOptions(checkedValues);
  }
  if (checkedOptions.includes("1")) {
    if (checkedOptions.includes("2")) {
      if (checkedOptions.includes("3")) {
        newCampaign.targeting = 0;
      } else {
        newCampaign.targeting = 5;
      }
    } else if (checkedOptions.includes("3")) {
      newCampaign.targeting = 4;
    } else {
      newCampaign.targeting = 1;
    }
  } else if (checkedOptions.includes("2")) {
    if (checkedOptions.includes("3")) {
      newCampaign.targeting = 6;
    } else {
      newCampaign.targeting = 3;
    }
  } else if (checkedOptions.includes("3")) {
    newCampaign.targeting = 2;
  }
  const options = [
    { label: "Organisation", value: "1" },
    { label: "Employees", value: "2" },
    { label: "CA", value: "3" },
  ];

  const draggerProps = {
    maxCount: 1,
    name: "files",
    multiple: false,
    action: `${config.rootUrl}/api/upload/campaign`,
    headers: {
      authorization: localStorage.getItem("mool_admin"),
    },
    showUploadList: false,
    beforeUpload: config.beforeFileUpload,
    async onChange(info) {
      let { response } = info.file;
      if (response) {
        let documentDetails = { ...response.data.stored };
        documentDetails.verified = false;
        documentDetails.createdAt = new Date();
        delete documentDetails.Bucket;
        delete documentDetails.Etag;
        let getGraphicsUrl = newCampaign;
        getGraphicsUrl.ad.graphicsUrl = documentDetails.Location;
        getGraphicsUrl.ad.Key = documentDetails.Key;
        setNewCampaign({
          ...getGraphicsUrl,
        });
      }
    },
  };

  const AddDocument = ({ Key, location }) => {
    return (
      <div className="document-container">
        <div style={{ display: "flex", alignItems: "center" }}>
          <DeleteOutlined
            onClick={async () => {
              let tempNewCampaign = newCampaign;
              tempNewCampaign.ad.graphicsUrl = "";
              tempNewCampaign.ad.Key = "";
              await removeAdsDocument(newCampaign.ad.Key);
              setNewCampaign({
                ...tempNewCampaign,
              });
            }}
          />
          <a target="_blank" href={newCampaign.ad.graphicsUrl} className="file-typeHeader">{Key.split(".").pop()}</a>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>{Key}</span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="site-layout">
      <HeaderComponent icon={activeDashboard} name="Manage Ads" />
      <div className="app-main-content-container">
        <div className="app-white-card newCampaignContainer">
          <div className="newCampaignHeader">
            {isUpdate ? (
              <p className="newCampaignHeading">Edit Campaign</p>
            ) : (
              <p className="newCampaignHeading">New Campaign</p>
            )}
          </div>
          <div className="initialInformation">
            <p className="information-heading">Initial information</p>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={8} >
                <div className="information-content">
                  <p className="general-ant-field-label">Name of business</p>
                  <Input
                    name="nameOfBusiness"
                    value={newCampaign.nameOfBusiness}
                    onChange={(e) => {
                      let newCampaignInfo = newCampaign;
                      setNewCampaign({
                        ...newCampaignInfo,
                        nameOfBusiness: e.target.value,
                      });
                    }}
                    className="general-ant-field-input"
                    placeholder="Type here…"
                    size={"large"}
                    bordered={false}
                  />
                </div>
              </Col>
              <Col xs={24} sm={24} md={8} >
                <div className="information-content">
                  <p className="general-ant-field-label">Destination Link</p>
                  <Input
                    name="destinationLink"
                    value={newCampaign.destinationLink}
                    onChange={(e) => {
                      let newCampaignInfo = newCampaign;
                      setNewCampaign({
                        ...newCampaignInfo,
                        destinationLink: e.target.value,
                      });
                    }}
                    className="general-ant-field-input"
                    placeholder="https://"
                    size={"large"}
                    bordered={false}
                  />
                </div>
              </Col>
            </Row>
          </div>
          <div className="initialInformation">
            <p className="information-heading">Create Ad</p>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={8} >
                <div className="information-content">
                  <p className="general-ant-field-label">Campaign Name</p>
                  <Input
                    name="title"
                    value={newCampaign.ad.title}
                    onChange={(e) => {
                      let newCampaignInfo = newCampaign;
                      newCampaignInfo.ad.title = e.target.value;
                      setNewCampaign({
                        ...newCampaignInfo,
                      });
                    }}
                    className="general-ant-field-input"
                    placeholder="Title"
                    size={"large"}
                    bordered={false}
                  />
                </div>
                <div className="information-content">
                  <p className="general-ant-field-label">Headline</p>
                  <Input
                    name="headline"
                    value={newCampaign.ad.headline}
                    onChange={(e) => {
                      let newCampaignInfo = newCampaign;
                      newCampaignInfo.ad.headline = e.target.value;
                      setNewCampaign({
                        ...newCampaignInfo,
                      });
                    }}
                    className="general-ant-field-input"
                    placeholder="Title"
                    size={"large"}
                    bordered={false}
                  />
                </div>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <div className="information-content">
                  <p className="general-ant-field-label">Description</p>
                  <div className="description-box-container">
                    <Input
                      name="description"
                      value={newCampaign.ad.description}
                      onChange={(e) => {
                        let newCampaignInfo = newCampaign;
                        newCampaignInfo.ad.description = e.target.value;
                        setNewCampaign({
                          ...newCampaignInfo,
                        });
                      }}
                      className="description-box-input"
                      size={"large"}
                      bordered={false}
                    />
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={24} md={8} >
                <div className="information-content">
                  <p className="general-ant-field-label">Upload Graphic</p>
                  {newCampaign.ad.graphicsUrl === "" ? (
                    <Dragger
                      showUploadList={false}
                      className="dragger"
                      {...draggerProps}
                    >
                      <div className="dragger-info-container">
                        <PictureOutlined className="uploadLogo" />
                        <p className="ant-upload-text">
                          Click or drag file to this area to upload
                        </p>
                      </div>
                    </Dragger>
                  ) : (
                    <AddDocument Key={newCampaign.ad.Key} />
                  )}
                </div>
              </Col>
            </Row>
          </div>
          <div className="initialInformation">
            <p className="information-heading">Targeting</p>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={8} >
                <div className="information-content">
                  <p className="general-ant-field-label">
                    Whom to show the Ad?
                  </p>
                  <Checkbox.Group
                    className="checkbox-container"
                    value={checkedOptions}
                    options={options}
                    onChange={onChange}
                  />
                </div>
              </Col>
            </Row>
          </div>
          <div className="buttonContainer">
            <NormalButton
              onClick={saveAndClose}
              buttonText="Save & Close"
              type={5}
            />
            <NormalButton
              onClick={launchCampaign}
              buttonText="Launch Campaign"
              type={6}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(NewCampaign);
