import "./inputField.less";

const InputField = ({ icon, block, background, value, type, name, onChange, border, placeholder, disabled, maxLength }) => {

    let styles = {
        cursor: disabled ? "not-allowed" : "",
        display: block ? "block" : "inline-block",
        backgroundColor: background ? background : "",
        borderBottom: border ? `${border} 2px solid` : "",
    }

    return (
        <input
            className={`input-field-container input-field-container-${type}`}
            style={styles}
            value={value}
            type={type}
            name={name}
            onChange={onChange}
            placeholder={placeholder}
            disabled={disabled}
            maxLength={maxLength}
            autoComplete={"off"}
        >
            {
                icon
            }
        </input>
    )
}

InputField.defaultProps = {
    type: 1
}

export default InputField;