import { HistoryOutlined, InfoCircleOutlined, SettingOutlined } from "@ant-design/icons";
import { Breadcrumb, Col, Row, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { getUserFromPAN } from '../../apis/admin';
import { HeaderComponent } from '../Common';
import Logs from '../Logs/Logs';
import Settings from '../OrganisationDetails/Components/Settings';
import EmployeeUpdate from './EmployeeUpdate';
import OtherEmployeeDetails from './OtherEmployeeDetails';

const { TabPane } = Tabs;
const Index = () => {
    const [userDetails, setUserDetails] = useState({});
    const { PAN, orgPAN } = useParams();
    useEffect(() => {
        if (PAN) getOrganisationData(PAN);
    }, [PAN]);

    const getOrganisationData = async (PAN) => {
        let userDetails = await getUserFromPAN(PAN);
        setUserDetails(userDetails.data.user);
    };
    return (
        <div className="site-layout">
            <HeaderComponent />
            <div className="app-breadcrum-container">
                <Breadcrumb className="app-breadcrum">
                    <Breadcrumb.Item>
                        <Link to={`/admin/client-management`}>Client Management</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to={`/admin/organisationDetails/${orgPAN}`}>{orgPAN}</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item className="app-breadcrum-active">
                        {userDetails.displayName}
                    </Breadcrumb.Item>
                </Breadcrumb>
                <Tabs defaultActiveKey="1" >
                    <TabPane
                        tab={
                            <div className="tabs">
                                <span className="tab-name"><InfoCircleOutlined />Overview</span>
                            </div>
                        }
                        key="1"
                    >
                        <div className="app-main-content" style={{ marginLeft: "-30px" }}>
                            <Row gutter={[0, 10]}>
                                <Col xs={24} sm={24} md={24} lg={12}>
                                    <div className="org-details-column">
                                        <EmployeeUpdate user={userDetails} />
                                    </div>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12}>
                                    <div className="org-details-column">
                                        <OtherEmployeeDetails user={userDetails} />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </TabPane>

                    <TabPane
                        tab={
                            <div className="tabs">
                                <span className="tab-name"><HistoryOutlined />Logs</span>
                            </div>
                        }
                        key="5"
                    >
                        <div className="organisation-details-container">
                            <Logs user={userDetails} />
                        </div>
                    </TabPane>
                    <TabPane
                        tab={
                            <div className="tabs">
                                <span className="tab-name"><SettingOutlined />Settings</span>
                            </div>
                        }
                        key="6"
                    >
                        <Settings user={userDetails} />
                    </TabPane>
                </Tabs>
            </div>
        </div>
    );
}

export default Index;