import axios from './index';

export async function getAllJannForm12BBDocument(financialYear) {
    return axios.get(`/fetch/all/form12BB/${financialYear}`);
}
export async function getTransactionForUser(id) {
    return axios.get(`/fetch/transaction/${id}`);
}

export async function getAllJannRentReceiptDocument() {
    return axios.get(`fetch/all/rent-receipt`);
}

export async function getAllJannUsers() {
    return axios.get(`fetch/all/jann_users`);
}
