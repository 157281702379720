import React from 'react'
import BlockCard from './components/BlockCard'
import LoginStateChange from './components/LoginState'
import OriginalityCard from './components/OriginalityCard'
import PayrollOutsourcing from './components/PayrollOutsourcing'
import PayrollProcessed from './components/PayrollProcessed'
import RemoveAllEmployees from './components/RemoveAllEmployees'
import RemoveOrganisation from './components/RemoveOrg'

const Settings = ({ user }) => {
    return (
        <div>
            <div className="organisation-details-container">
                <h1>Organisation Zone</h1>
                <OriginalityCard orgId={user.organisationId} original={user.original ?? false} />
                <LoginStateChange user={user} />
                <PayrollProcessed orgId={user.organisationId} pay={user.original ?? false} />
                <PayrollOutsourcing user={user} />
            </div>
            <div className="organisation-details-container box-danger" style={{ marginTop: "30px" }}>
                <h1>Danger Zone</h1>
                <BlockCard user={user} blocked={user.disabled ?? false} />
                <RemoveAllEmployees />
                <RemoveOrganisation />
            </div>
        </div>
    )
}

export default Settings