import { useState,useEffect } from "react";
import { HeaderComponent } from "../Common";
import { Row, Col } from "antd";
import activeDashboard from "../../assets/icons/activeIcons/activeDashboard.svg";
import "./manageAds.less";
import ManageAdsTable from "./components/manageAdsTable";
import NumCard from "./components/numCard";
import { getCampaignList } from "../../apis/admin";

const ManageAds = () => {
  const [campaignList, setCampaignList] = useState([]);

  useEffect(() => {
    fetchCampaign();
  }, []);

  const fetchCampaign = async () => {
    let campaignData = await getCampaignList();
    campaignData.data.forEach((element,index) => {
      let impressions = 0;
      let clicks = 0;
      let averageCTR = 0;
      element.impressions = impressions;
      element.clicks = clicks;
      element.averageCTR = averageCTR;
      element.key = index;
    });
    setCampaignList(campaignData.data);
  };
  return (
    <div className="site-layout">
      <HeaderComponent icon={activeDashboard} name="Manage Ads" />
      <div className="app-main-content-container">
        <Row gutter={[20, 20]}>
          <Col xs={24} sm={24} md={18} lg={18}>
            <ManageAdsTable campaignList={campaignList} fetchCampaign={fetchCampaign}/>
          </Col>
          <Col xs={24} sm={24} md={6} lg={6}>
            <NumCard campaignList={campaignList}/>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ManageAds;
