import { DeleteOutlined } from '@ant-design/icons';
import { Spin } from "antd";
import { useState } from "react";

const RemoveAllEmployees = ({ orgId, blocked }) => {
    // let [blockedState, setBlockedState] = useState(false)
    let [apiLoading] = useState(false)

    // useEffect(() => {
    //     setBlockedState(blocked)
    // }, [blocked])

    // let onChange = async (status) => {
    //     try {
    //         setApiLoading(true)
    //         setBlockedState(status)
    //         let response = await setAccountBlockedStatus({ orgId, blocked: status })
    //         if (response.status !== 200) {
    //             setBlockedState(!status)
    //         }
    //     } catch (error) {
    //         setBlockedState(!status)
    //     } finally {
    //         setApiLoading(false)
    //     }
    // }

    return (
        <div className="originality-container">
            <Spin spinning={apiLoading}>
                <div className={"flex"}>
                    <div className="header-description">
                        <p className="heading">Delete Employees</p>
                        <span className='description'>You will lost your all data!</span>
                    </div>
                    <div className="switch-container ">
                        <DeleteOutlined style={{ fontSize: "18px", padding: "7px 7px", color: "white", borderRadius: "5px", cursor: "pointer", backgroundColor: "#e57373" }} />
                    </div>
                </div>
            </Spin>
        </div>
    )
}
export default RemoveAllEmployees




