import {
    DeleteOutlined
} from "@ant-design/icons";
import { Input, notification, Popconfirm, Table } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { deleteUserPermanently } from '../../../apis/admin';
import EmployeeLogo from "../../../assets/header/EmployeeLogo.svg";
import payoutEmpty from "../../../assets/icons/AllowanceEmpty.svg";
import UserVerification from '../../Common/UserVerification';

const EmployeesDetails = ({ employeeList, organisation }) => {
    const [displayEmployees, setDisplayEmployee] = useState([]);
    const [visible, setVisible] = useState(false);
    const [authenticated, setAuthenticated] = useState(false);
    const [id, setId] = useState("");

    useEffect(() => {
        if (authenticated && id) {
            deleteUserPermanently(id).then(res => {
                const { employee, declaration, onboarding, ctc, employeeLeave, previousEmployment } = res.data;
                notification.open({ message: "Employee Deleted Successfully", description: <h3>`UserName: ${employee.username}, Declarations: ${declaration}, Onboarding: ${onboarding}, CTC:${ctc}, Employee Leave:${employeeLeave}, Previous Employment:${previousEmployment}<br /> - False fields should be deleted manually!`</h3> })
            })
        }
    }, [authenticated, id])
    const columns = [
        {
            width: "25%",
            title: 'Name',
            dataIndex: 'displayName',
            key: 'displayName',
            render: (name, data) => (
                <Link to={`/admin/employeeDetails/${organisation.PAN}/${data.PAN}`}>
                    <div className="org-list-name-container">
                        <img className={data.image ? "org-list-logo" : "org-list-logo org-list-logo-color"} src={data.image ? data.image : EmployeeLogo} alt="img" />
                        <div className="org-list-name-and-pan">
                            <p className="org-list-name">{name}</p>
                            <p className="org-list-pan">{data.username}</p>
                        </div>
                    </div>
                </Link>
            )
        },
        {
            title: "Employee Id",
            width: "25%",
            dataIndex: "currentOrganisationMeta",
            key: "currentOrganisationMeta",
            render: (currentOrganisationMeta, data) => (
                <span className="date-of-joining">{currentOrganisationMeta.employeeId}</span>
            )
        },
        {
            title: "Email",
            width: "25%",
            dataIndex: "primaryEmail",
            key: "primaryEmail",
            render: primaryEmail => (
                <span className="date-of-joining">{primaryEmail}</span>
            )
        },
        {
            title: "Date of Joining",
            width: "25%",
            dataIndex: "currentOrganisationMeta",
            key: "createdAt",
            render: currentOrganisationMeta => (
                <span className="date-of-joining">{moment(currentOrganisationMeta.dateOfJoining).format('MMMM Do YYYY')}</span>
            )
        },
        {
            className: "table-row-center-align",
            title: 'Delete',
            width: "25%",
            dataIndex: '_id',
            key: '_id',
            render: (key) => {
                return <Popconfirm title="Delete Permanently!" onConfirm={() => { setVisible(key); setId(key) }} ><DeleteOutlined style={{ color: "red", cursor: "pointer" }} /></Popconfirm>
            }

        },
    ];
    useEffect(() => {
        setDisplayEmployee(employeeList.sort((a, b) => a.createdAt < b.createdAt).reverse());
    }, [employeeList])

    const searchEmployees = (value) => {
        let SearchParams = value.toLowerCase();
        if (SearchParams.trim() !== "") {
            let newEmployees = [];
            newEmployees = employeeList.filter((val) => {
                return val['displayName'].toString().toLowerCase().includes(SearchParams) || val['PAN'].toString().toLowerCase().includes(SearchParams)
            });
            setDisplayEmployee([...newEmployees.sort((a, b) => a.createdAt < b.createdAt).reverse()]);
        } else {
            setDisplayEmployee(employeeList.sort((a, b) => a.createdAt < b.createdAt).reverse());
        }
    }

    return (
        <div className="organisation-details-container" >
            <UserVerification visible={visible} setVisible={setVisible} setAuthenticated={setAuthenticated} />
            {
                employeeList.length > 0 ?
                    <>
                        <div className="paper-header-container">
                            <h3 className="paper-heading">Employees</h3>
                            <div className="paper-heading-action">
                                <Input.Search
                                    onPressEnter={(e) => { searchEmployees(e.target.value) }}
                                    onSearch={(value, e) => { searchEmployees(value) }}
                                    onClear
                                    enterButton={true}
                                    className="search-field"
                                    size={"large"}
                                    placeholder="Search Name or PAN"
                                    allowClear
                                />
                            </div>
                        </div>
                        <Table dataSource={displayEmployees} columns={columns} pagination={{ pageSize: 8 }} />
                    </>
                    : <div className="flex justify-center align-center w-full">
                        <img alt="img" className="payout-state-0-image" src={payoutEmpty} />
                    </div>
            }
        </div>
    )
}

export default EmployeesDetails
