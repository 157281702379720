import { Tabs } from 'antd';
import { useState } from "react";
import activeDashboard from "../../assets/icons/activeIcons/activeDashboard.svg";
import { HeaderComponent } from "../Common";
import "./clientManagement.less";
import CharteredAccountant from "./components/charteredAccountant";
import Organisation from "./components/Organisation";
import RequestClientManagement from "./components/RequestClientManagement";

const { TabPane } = Tabs;

const ClientManagement = (props) => {
    const [active, setActive] = useState(1)
    return (
        <section className="site-layout">
            <HeaderComponent icon={activeDashboard} name="Client Management" />
            <div className="app-main-content-container">
                <Tabs defaultActiveKey={active} size="large" onClick={() => setActive(1)}>
                    <TabPane tab="Requests" key="1">
                        <RequestClientManagement />
                    </TabPane>
                    <TabPane tab="Active Organisation" key="2" style={{ marginBottom: "50px" }} onClick={() => setActive(2)}>
                        <Tabs defaultActiveKey={active} size="large" onClick={() => setActive(1)}>
                            <TabPane tab="Organisations" key="1">
                                <Organisation setActive={setActive} />
                            </TabPane>
                            <TabPane tab="Chartered Accountants" key="2">
                                <CharteredAccountant />
                            </TabPane>
                        </Tabs>

                    </TabPane>
                </Tabs>
            </div>
        </section >
    )
}

export default ClientManagement;