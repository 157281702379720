import { Spin, Table } from "antd";
import { useEffect, useState } from "react";
import { getAllJannUsers } from "../../../apis/jann";

const JannUsers = () => {
  const [listCount, setListCount] = useState(0);

  const [dataSource, setDataSource] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setLoader(true)
    getAllJannUsers().then(res => {
      if (res.data.success) {
        setDataSource(res.data.data.reverse());
        setListCount(res.data.data.length)
      } else {
        setDataSource([]);
      }
    }).catch(err => {
      setDataSource([]);
    });
    setLoader(false)
  }, []);




  const columns = [
    {
      title: "ID",
      dataIndex: "_id",
      key: "ID",
    },
    {
      width: "20%",
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (key, data) => (
        <p className="user-name">{data?.name?.first + " " + data?.name?.last}</p>
      ),
    },
    {
      title: "Date of birth",
      width: "10%",
      dataIndex: "dateOfBirth",
      key: "dateOfBirth",
    },
    {
      title: "Gender",
      width: "10%",
      dataIndex: "gender",
      key: "gender",
    },
    {
      title: "Status",
      width: "10%",
      dataIndex: "status",
      key: "startDate",
    },
    {
      title: "Phone",
      width: "10%",
      dataIndex: ["phone", "number"],
      key: "phone",
    },
  ];



  return (
    <div className="app-white-card active-client-management-card">
      <Spin spinning={loader}>
        <div className="paper-header-container">
          <h3 className="paper-heading">List ({listCount})</h3>
        </div>
        <Table
          columns={columns}
          dataSource={dataSource}
          onChange={(pagination, filters, sorter, extra) => {
            setListCount(extra.currentDataSource.length);
          }}
          pagination={{ pageSize: 6 }}
        />
      </Spin>
    </div>
  )
};

export default JannUsers;
