import { CopyOutlined, SyncOutlined } from "@ant-design/icons";
import { Button, Col, notification, Row, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import generalEmployeeLogo from "../../../assets/common/generalEmployeeLogo.svg";
import Cross from "../../../assets/icons/Cross.svg";
import Pen from "../../../assets/icons/Pen.svg";
import Submit from "../../../assets/common/verifiedLogos/verify-yes.svg";
import { InputField } from "../../ComponentLibrary";
import { updateOrganisation } from "../../../apis/auth";

const OrganisationName = ({ user, employeeList }) => {

  const [disabled, setDisabled] = useState(true)
  const [organisation, setOrganisation] = useState({
    displayName: "",
    organisationId: "",
    PAN: "",
    username: "",
    pointOfContact: "",
    primaryEmail: "",
    phone: "",
    designation: "",
  });

  const handleChange = (e) => {
    setOrganisation({ ...organisation, [e.target.name]: e.target.value })
  }

  const onUpdate = () => {
    updateOrganisation(organisation).then(res => {
      notification.success({ message: "Updated Organisation Basic Info Successfully!" })
    }).catch(err => {
      notification.error({ message: "Error While Updating Organisation" })
    })
  }

  useEffect(() => {
    if (user.organisationId) {
      setOrganisation({
        displayName: user.displayName,
        organisationId: user.organisationId,
        PAN: user.PAN,
        username: user.username,
        pointOfContact: user.pointOfContact,
        primaryEmail: user.primaryEmail,
        phone: user.phone,
        designation: user.designation,
      });
    }
  }, [user])
  return (
    <div >
      <div className="organisation-basic-info organisation-details-container" style={{ padding: "35px 20px" }}>
        <Skeleton loading={!user.organisationId} active avatar>
          <div className="organisation-profile-container">
            <div className="organisation-logo-container">
              <img
                alt="img"
                className="organisation-logo"
                src={generalEmployeeLogo}
              ></img>
            </div>
            <div className="organisation-name-container">
              <h1 className="organisation-name-displayname">{user.displayName}</h1>
              <span className="copy-unique-id"><p className="unique-id">{user.organisationId}</p><span className="copy-btn"><CopyOutlined /></span></span>
              <h3 className="employee-number">{employeeList.length} Employees</h3>
            </div>
          </div>
          <div className="password-container">
            <Button type="link" className="button-password">
              <SyncOutlined />
              Reset Password
            </Button>
          </div>
        </Skeleton>
      </div>

      <div className=" organisation-details-container">
        <Skeleton loading={!user.organisationId} active avatar paragraph={{ rows: 10 }}>
          <div className="information-section">
            <div className="section-header">
              <p className="information-category-heading">
                Organisation Information
              </p>
              <div className="flex">
                {
                  !disabled && <Button type="link" className="edit-button" style={{ marginTop: "13px" }} onClick={onUpdate}>
                    <span style={{ color: "rgb(64, 210, 172)" }}><img alt="img" src={Submit} />
                      {" "}Save</span>
                  </Button>
                }
                <Button type="link" className="edit-card-button" style={{ marginTop: "13px" }} onClick={() => setDisabled(!disabled)}>
                  {disabled ? <span style={{ color: "rgb(254, 161, 1)" }}><img alt="img" src={Pen} />
                    {" "}Edit</span> : <span style={{ color: "rgb(118, 123, 130)" }}><img alt="img" src={Cross} />
                    {" "}Cancel</span>}
                </Button>
              </div>
            </div>
            <Row gutter={[40, 2]}>
              <Col xs={24} sm={24} md={12} lg={12}>
                <div className="employee-info">
                  <h3 className="headings fields-heading">Organisation Name</h3>
                  <InputField onChange={handleChange} name="displayName" disabled={disabled} className="profile-data-display" value={organisation.displayName} />
                </div>
                <div className="employee-info">
                  <h3 className="headings fields-heading">PAN Number</h3>
                  <InputField onChange={handleChange} name="PAN" disabled={disabled} className="profile-data-display" value={organisation.PAN} />
                </div>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12}>
                <div className="employee-info">
                  <h3 className="headings fields-heading">Email</h3>
                  <InputField onChange={handleChange} name="primaryEmail" disabled={disabled} className="profile-data-display" value={organisation.primaryEmail} />
                </div>
                <div className="employee-info">
                  <h3 className="headings fields-heading">UserName</h3>
                  <InputField onChange={handleChange} name="username" disabled={disabled} className="profile-data-display" value={organisation.username} />
                </div>
              </Col>
            </Row>
          </div>
          <div className="information-section">
            <div className="section-header">
              <p className="information-category-heading">Extra Information</p>
            </div>
            <Row gutter={[40, 2]}>
              <Col xs={24} sm={24} md={12} lg={12}>
                <div className="employee-info">
                  <h3 className="headings fields-heading">POC Full Name</h3>
                  <InputField onChange={handleChange} name="pointOfContact" disabled={disabled} className="profile-data-display" value={organisation.pointOfContact} />
                </div>
                <div className="employee-info">
                  <h3 className="headings fields-heading">Phone number</h3>
                  <div className="phone-number-holder">
                    <InputField onChange={handleChange} name="phone" disabled={disabled} className="profile-data-display" value={organisation.phone} />
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12}>
                <div className="employee-info">
                  <h3 className="headings fields-heading">POC Designation</h3>
                  <InputField onChange={handleChange} name="designation" disabled={disabled} className="profile-data-display" value={organisation.designation} />
                </div>
                <div className="employee-info">
                  <h3 className="headings fields-heading">Organisation heading</h3>
                  <p className="profile-data-display">Self Managed</p>
                </div>
              </Col>
            </Row>
          </div>
        </Skeleton>
      </div>
    </div>
  );
};

export default OrganisationName;
