import { Button, Card, Col, InputNumber, notification, Row, Select, Spin } from "antd";
import { useEffect, useState } from "react";
import Pen from "../../../assets/icons/Pen.svg";
import {
    InboxOutlined,
    DeleteOutlined,
} from "@ant-design/icons";
import { removeS3Object } from "../../../apis/auth";
import config from "../../../utils/config";
import Dragger from "antd/lib/upload/Dragger";
import { getDocumentURL, updateComplaince } from "../../../apis/admin";

const { Option } = Select;

const statuses = ["Pending", "Initiated", "Proccessed"]
const ComplianceDocument = ({ organisationId, month, financialYear, complianceType, Name, complaince, updateData }) => {
    const [loading, setLoading] = useState(false);
    const [edits, setEdits] = useState({
        status: false,
        amount: false,
        document: false,
    })
    const [status, setStatus] = useState(0);
    const [amount, setAmount] = useState(0);
    const [document, setDocument] = useState([]);
    const [newDoc, setNewDoc] = useState(false);
    useEffect(() => {
        if (Object.keys(complaince).length) {
            setStatus(complaince.status ? complaince.status : 0)
            setAmount(complaince.amount ? complaince.amount : 0);
            setDocument(complaince.document.length ? [...complaince.document] : []);
        }
    }, [complaince])

    const uploadProps = {
        name: "file",
        action: `${config.rootUrl}/api/document/complaince`,
        headers: {
            authorization: localStorage.getItem("mool_admin"),
        },
        multiple: false,
        async onChange(info) {
            let { response } = info.file;
            if (response) {
                let tempDocumentList = document;
                let documentDetails = { ...response.data.stored };
                documentDetails.createdAt = new Date();
                documentDetails.name = info.file.name.substring(
                    0,
                    info.file.name.lastIndexOf(".")
                );
                delete documentDetails.Bucket;
                delete documentDetails.ETag;
                setNewDoc(true);
                tempDocumentList.push(documentDetails);
                setDocument([...tempDocumentList]);
            }
        },
    };

    const getDocumentURLFromKey = async (key) => {
        let document = await getDocumentURL({ Key: key });
        window.open(document.data, '_blank')
    };

    const updateComplainces = async (updateFieldType) => {
        if (updateFieldType < 3 && updateFieldType >= 0) {
            setLoading(true);
            let postBody = {
                organisationId,
                month,
                financialYear,
                complianceType,
                updateFieldType,
                compliance: updateFieldType === 0 ? status : updateFieldType === 1 ? amount : document
            }
            let response = await updateComplaince(postBody);
            setNewDoc(false);
            setLoading(false)
            setEdits({
                status: false,
                amount: false,
                document: false,
            })
            if (response.data.success) {
                notification.success({ message: "Successfully updated the complaince" });
                updateData()
            }
            else {
                notification.error({ message: "Sorry Unable to update the complaince" })
            }
        }
        else {
            notification.error({ message: "Please Select correct complaince section" })
        }
    }

    const removeDocument = async (doc, index) => {
        let tempDocumentList = document;
        let tempDocument = tempDocumentList.filter(
            (val, i) => i !== index
        );
        await removeS3Object({ Key: doc.key });
        setNewDoc(true);
        tempDocumentList = [...tempDocument];
        setDocument([...tempDocumentList]);
    };

    return (
        <Card
            className="document-group"
            bordered={false}
            hoverable
            loading={loading}
            style={{
                borderBottom: 16,
            }}
        >
            <Spin spinning={loading} size="large">
                <h1 className="app-label-2">{Name}</h1>
                <Row gutter={[10, 10]}>
                    <Col xs={18} lg={18} md={18} sm={18}>
                        <div className="employee-info">
                            <h3 className="headings fields-heading">Status</h3>
                            <Select
                                className="general-input-field"
                                name="status"
                                disabled={!edits.status}
                                value={status}
                                onChange={(e) => {
                                    setStatus(parseInt(e));
                                }}
                                style={{ width: "97%" }}
                                bordered={false}
                                size={"large"}
                            >
                                {statuses.map((val, index) => {
                                    return <Option key={`complaince-status-${index}`} value={index}>{val}</Option>;
                                })}
                            </Select>
                        </div>
                    </Col>
                    <Col xs={6} lg={6} md={6} sm={6} className="flex align-center justify-center">
                        {!edits.status ? <Button
                            type="link"
                            className="edit-card-button"
                            onClick={() => setEdits({
                                ...edits,
                                status: true,
                            })}
                        >
                            <img alt="img" src={Pen} />
                            Update
                        </Button> : <Button type="primary" onClick={() => { updateComplainces(0) }}>Update</Button>}
                    </Col>
                </Row>
                <Row gutter={[10, 10]}>
                    <Col xs={18} lg={18} md={18} sm={18}>
                        <div className="employee-info">
                            <h3 className="headings fields-heading">Amount</h3>
                            <InputNumber
                                className="general-input-field"
                                name="amount"
                                disabled={!edits.amount}
                                value={amount}
                                onChange={(e) => {
                                    setAmount(e);
                                }}
                                style={{ width: "97%" }}
                                bordered={false}
                                size={"large"}
                            />

                        </div>
                    </Col>
                    <Col xs={6} lg={6} md={6} sm={6} className="flex align-center justify-center">
                        {!edits.amount ? <Button
                            type="link"
                            className="edit-card-button"
                            onClick={() => setEdits({
                                ...edits,
                                amount: true
                            })}
                        >
                            <img alt="img" src={Pen} />
                            Update
                        </Button> : <Button type="primary" onClick={() => { updateComplainces(1) }}>Update</Button>}
                    </Col>
                </Row>
                <div className="flex justify-between flex align-center flex-wrap w-full">
                    {
                        document.map((val, index) => <div
                            className="document-container"
                            key={`doc-container-${index}`}
                        >
                            <div style={{ display: "flex", alignItems: "center" }}>
                                {status < 2 && <DeleteOutlined
                                    key={`doc-container-delete-${index}`}
                                    onClick={() => removeDocument(val, index)}
                                />}
                                <div 
                                    onClick={() => {getDocumentURLFromKey(val.Key)}}
                                    key={`doc-container-filetype-${index}`}
                                    className="file-typeHeader"
                                >
                                    {val.Key.split(".").pop()}
                                </div>
                                <span key={`doc-container-name-${index}`}>{val.Key}</span>
                            </div>
                        </div>)
                    }
                    {
                        status < 2 && newDoc && <Button style={{ margin: "10px 0" }} type="primary" onClick={() => { updateComplainces(2) }}>Update Documents</Button>
                    }
                </div>
                {
                    status === 1 && <Dragger
                        {...uploadProps}
                        multiple={true}
                        showUploadList={false}
                        beforeUpload={config.beforeFileUpload}
                        className="dragger-container"
                    >
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">
                            Click or drag file to this area to upload
                        </p>
                        <p className="ant-upload-hint">upload max of 3 files</p>
                    </Dragger>
                }


            </Spin>
        </Card>
    )
}

export default ComplianceDocument;