import ReducerFactory from "../utils/reducerFactory";
import { notification } from "antd";
import { check, login } from "../../apis/auth";

const initialState = {
  loading: false,
  user: {},
  image: "",
  address: {},
  isLoggedIn: false,
  error: null,
  loginstate: false,
  showNewPassword: false,
};
const reducerFactory = new ReducerFactory("auth", initialState);

reducerFactory.addAction(
  "LOGIN_LOADING",
  "loading",
  (status) => status,
  (state, action) => {
    state.loading = action.data;
    const newState = Object.assign({}, state);
    return newState;
  }
);

reducerFactory.addAction(
  "LOGOUT",
  "logout",
  (status) => status,
  (state, action) => {
    state.isLoggedIn = false;
    state.user = {};
    localStorage.removeItem("mool_admin");
    const newState = Object.assign({}, state);
    return newState;
  }
);

reducerFactory.addAction(
  "LOGIN",
  "login",
  async (data) => {
    let res = await login(data);
    return res.data;
  },
  (state, action) => {
    if (action.data.success) {
      let user = action.data.data.user;
      state.user = user;
      state.image = user.image;
      state.address = user.address;
      state.isLoggedIn = true;
      localStorage.setItem("mool_admin", action.data.data.auth);
    } else {
      notification.error({ message: action.data.message });
    }
    state.loading = false;
    const newState = Object.assign({}, state);
    return newState;
  }
);

reducerFactory.addAction(
  "CHECK_LOGIN",
  "check",
  async (data) => {
    reducerFactory.action("loading", true);
    let authExists = localStorage.getItem("mool_admin");
    if (!authExists) {
      return { data: { success: false } };
    }
    let res = await check();
    return res.data;
  },
  (state, action) => {
    if (action.data.success) {
      let user = action.data.data.user;
      state.user = user;
      state.image = user.image;
      state.address = user.address;
      state.isLoggedIn = true;
      localStorage.setItem("mool_admin", action.data.data.auth);
    }
    state.loading = false;
    const newState = Object.assign({}, state);
    return newState;
  }
);

reducerFactory.addAction(
  "UPDATE_IMAGE",
  "updateImage",
  (status) => status,
  (state, action) => {
    state.image = action.data;
    state.loading = false;
    const newState = Object.assign({}, state);
    return newState;
  }
);

export default reducerFactory;
