import React from 'react'
import { Button } from 'antd';
import viewCard from "../../../assets/icons/viewCard.svg";

const ViewLog = () => {
    return (
        <div className="viewLog-container">
            <div className="view-log-data-container">
                <p className="issue-number" style={{ color:"#444444"}}>23</p>
                <p className="issue-type">Issues raised</p>
                <p className="issue-number" style={{ color:"#40d2ac"}}>20</p>
                <p className="issue-type">Resolved</p>
                <p className="issue-number" style={{ color:"#405cd2"}}>3</p>
                <p className="issue-type">Pending</p>
            </div>
            <div>
                <Button type="link" className="button-container"><img alt="img" src={viewCard}/><p className="button-text">View Log</p></Button>
            </div>
        </div>
    )
}

export default ViewLog
