import {
  Button, InputNumber, Modal, notification, Select
} from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getSubscriptionByPAN, subscriptionChangeRequest
} from "../../../apis/admin";
import Cross from "../../../assets/icons/Cross.svg";
import Heartwave from "../../../assets/icons/Heartwave.svg";
import Pen from "../../../assets/icons/Pen.svg";
const { Option } = Select;

const NumCard = ({ user, PAN, employeeList }) => {
  const [isSubscriptionModalVisible, setIsSubscriptionModalVisible] = useState(
    false
  );
  const [subscriptionData, setSubscriptionData] = useState({
    plan: {
      name: "Small",
    },
    perUnitValue: 0,
    PAN: "",
  });

  useEffect(() => {
    if (PAN !== undefined) {
      getSubscriptionDetails(PAN);
    }
  }, [PAN]);

  const getSubscriptionDetails = async (PAN) => {
    let subscriptionInfo = await getSubscriptionByPAN(PAN);
    let responseData = subscriptionInfo.data;
    delete responseData._id;
    delete responseData.createdAt;
    delete responseData.updatedAt;
    setSubscriptionData(responseData);
  };
  const showSubscriptionModal = () => {
    setIsSubscriptionModalVisible(true);
  };
  const closeSubscriptionModal = () => {
    setIsSubscriptionModalVisible(false);
  };

  const requestSubscription = async () => {
    await setSubscriptionData({
      ...subscriptionData,
      PAN,
    });
    let response = await subscriptionChangeRequest(subscriptionData);
    if (response.data.success) {
      setSubscriptionData({
        plan: {
          name: "",
        },
        perUnitValue: 0,
        PAN: "",
      });
      getSubscriptionDetails(PAN);
      setIsSubscriptionModalVisible(false);
      notification.success({
        message: "New Subscription Request Created Successfully",
      });
    } else {
      notification.error({
        message: "Failed to create Subscription request,Please try again!!",
      });
    }
  };
  const List = [
    {
      plan: {
        name: "Executive",
      },
    },
    {
      plan: {
        name: "Standard",
      },
    },
    {
      plan: {
        name: "Basic",
      },
    },
  ];
  return (
    <>
      <div className="numCard-container">
        <div className="basic-numCard-container-left">
          <div>
            <div className="subscription-type">
              <p className="subscription-type-heading">Subscription Type</p>
              <p className="subscription-type-label">
                {subscriptionData.plan && subscriptionData.plan.name ? subscriptionData.plan.name : "No Plan Selected"}
              </p>
            </div>
            <div className="subscription-type">
              <p className="subscription-type-heading">Amount Per Employee</p>
              <p className="subscription-type-label">
                INR{" "}
                {subscriptionData.perUnitValue
                  ? subscriptionData.perUnitValue
                  : 1000} /-
              </p>
            </div>
          </div>
          <div>
            <Button
              type="link"
              className="edit-card-button"
              onClick={() => showSubscriptionModal()}
            >
              <img alt="img" src={Pen} />
              Update
            </Button>
          </div>
        </div>

        <div className="basic-numCard-container-right">
          <div className="icon-holder">
            <img src={Heartwave} alt="img"></img>
          </div>
          <div className="revenue-data-holder">
            <p className="revenue-heading">Revenue Created</p>
            <p className="revenue-amount">
              {subscriptionData.perUnitValue
                ? (subscriptionData.perUnitValue * employeeList.length)
                : (1000 * employeeList.length)
              } INR
            </p>
          </div>
        </div>
      </div>
      <Modal
        className="subscription-modal"
        visible={isSubscriptionModalVisible}
        onCancel={closeSubscriptionModal}
        onOk={() => this.setModal2Visible(false)}
        centered
        footer={null}
        closable={false}
      >
        <div className="modal-header-section">
          <p className="modal-section-heading">Update Subscription</p>
          <Button
            type="link"
            className="modal-close"
            onClick={() => closeSubscriptionModal()}
          >
            <img src={Cross} alt="img"></img>
          </Button>
        </div>
        <div className="modal-body-section">
          <div className="subscription-type">
            <p className="subscription-type-heading">Subscription Type</p>
            <Select
              className="general-input-field"
              name="subscriptionType"
              value={subscriptionData.plan.name}
              onChange={(e) => {
                let subscriptionDataInfo = subscriptionData;
                setSubscriptionData({
                  ...subscriptionDataInfo,
                  plan: {
                    name: e,
                  },
                });
              }}
              style={{ width: "97%" }}
              bordered={false}
              size={"large"}
            >
              {List.map((val) => {
                return <Option value={val.plan.name}>{val.plan.name}</Option>;
              })}
            </Select>
          </div>
          <div className="subscription-pricing-info">
            <h3 className="subscription-pricing-heading">Price per employee</h3>
            <InputNumber
              className="general-input-field"
              style={{ width: "97%" }}
              name="subscriptionPrice"
              value={parseInt(subscriptionData.perUnitValue)}
              onChange={(e) => {
                let subscriptionDataInfo = subscriptionData;
                setSubscriptionData({
                  ...subscriptionDataInfo,
                  perUnitValue: e,
                });
              }}
              size={"large"}
            />
          </div>
          <div className="subscription-button">
            <Button
              className="submit-button"
              style={{
                width: "30%",
                marginTop: "20px",
                marginRight: "20px",
                padding: "0px 24px",
              }}
              onClick={requestSubscription}
            >
              {" "}
              Save{" "}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};
const NumCardContainer = connect((state) => ({
  user: state.auth.user,
}))(NumCard);

export default NumCardContainer;
