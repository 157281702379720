import { Col, Row, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";
import React from "react";
import DataItem from "../../Common/DataItems/DataItems";
const { Paragraph } = Typography;

const CredentialDetails = ({ userData, user }) => {
  return (
    <Content className='paper-wrapper'>
      <h2>Credential Details</h2>
      <div className='credential-space-top'>
        <DataItem itemName="PAN" value={user.PAN} />
        <DataItem itemName="Name" value={user.displayName} />
        <DataItem itemName="Phone" value={user.phone} />
        <DataItem itemName="Email" value={user.primaryEmail} />
        <div className='credential-box'>
          <h2 className="app-heading-2">PF Credentials</h2>
          <Row gutter={[20,20]}>
            <Col sm={12} md={12} xs={12} lg={12}>
              <div className="employee-info">
                <h3 className="headings fields-heading">UserName</h3>
                <Paragraph copyable className="profile-data-display">{userData.PF.userName
                  ? userData.PF.userName
                  : "N/A"}</Paragraph>
              </div>
            </Col>
            <Col sm={12} md={12} xs={12} lg={12}>
              <div className="employee-info">
                <h3 className="headings fields-heading">Password</h3>
                <Paragraph copyable className="profile-data-display">{userData.PF.password
                  ? userData.PF.password
                  : "N/A"}</Paragraph>
              </div>
            </Col>
          </Row>
        </div>
        <div className='credential-box'>
          <Row className='justify-between mt-3'>
            <h1>PT Credentials</h1>
          </Row>
          <Row gutter={[20,20]}>
            <Col sm={12} md={12} xs={12} lg={12}>
              <div className="employee-info">
                <h3 className="headings fields-heading">UserName</h3>
                <Paragraph copyable className="profile-data-display">{userData.PT.userName
                  ? userData.PT.userName
                  : "N/A"}</Paragraph>
              </div>
            </Col>
            <Col sm={12} md={12} xs={12} lg={12}>
              <div className="employee-info">
                <h3 className="headings fields-heading">Password</h3>
                <Paragraph copyable className="profile-data-display">{userData.PT.password
                  ? userData.PT.password
                  : "N/A"}</Paragraph>
              </div>
            </Col>
          </Row>
        </div>
        <div className='credential-box'>
          <Row className='justify-between mt-3'>
            <h1>ESIC Credentials</h1>
          </Row>
          <Row gutter={[20,20]}>
            <Col sm={12} md={12} xs={12} lg={12}>
              <div className="employee-info">
                <h3 className="headings fields-heading">UserName</h3>
                <Paragraph copyable className="profile-data-display">{userData.ESIC.userName
                  ? userData.ESIC.userName
                  : "N/A"}</Paragraph>
              </div>
            </Col>
            <Col sm={12} md={12} xs={12} lg={12}>
              <div className="employee-info">
                <h3 className="headings fields-heading">Password</h3>
                <Paragraph copyable className="profile-data-display">{userData.ESIC.password
                  ? userData.ESIC.password
                  : "N/A"}</Paragraph>
              </div>
            </Col>
          </Row>
        </div>
        <div className='credential-box'>
          <Row className='justify-between mt-3'>
            <h1>TDS Credentials</h1>
          </Row>
          <Row gutter={[20,20]}>
            <Col sm={12} md={12} xs={12} lg={12}>
              <div className="employee-info">
                <h3 className="headings fields-heading">UserName</h3>
                <Paragraph copyable className="profile-data-display">{userData.TDS.userName
                  ? userData.TDS.userName
                  : "N/A"}</Paragraph>
              </div>
            </Col>
            <Col sm={12} md={12} xs={12} lg={12}>
              <div className="employee-info">
                <h3 className="headings fields-heading">Password</h3>
                <Paragraph copyable className="profile-data-display">{userData.TDS.password
                  ? userData.TDS.password
                  : "N/A"}</Paragraph>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Content>
  );
};

export default CredentialDetails;
