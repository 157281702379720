import React, { useState } from "react";
import { NormalButton } from "../../ComponentLibrary";
import { EditFilled, DeleteOutlined } from "@ant-design/icons";
import { Button, Table, Switch, Modal, notification } from "antd";
import { deleteLogo, editLogo } from "../../../assets/editAndDeleteLogos";
import { withRouter } from "react-router-dom";
import {
  removeCampaignFromAdId,
} from "../../../apis/admin";

const ManageAdsTable = ({ campaignList, fetchCampaign, history }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [
    isDeleteCampaignModalVisible,
    setIsDeleteCampaignModalVisible,
  ] = useState(false);
  const [currentDeleteCampaignObj, setCurrentDeleteCampaignObj] = useState({});

  const onSelectChange = (rows) => {
    setSelectedRowKeys([...rows]);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const removeCampaign = (CampaignData) => {
    setIsDeleteCampaignModalVisible(true);
    setCurrentDeleteCampaignObj(CampaignData);
  };

  const closeDeleteCampaignModal = () => {
    setIsDeleteCampaignModalVisible(false);
    setCurrentDeleteCampaignObj({});
  };

  const deleteCampaignRequest = async () => {
    let response = await removeCampaignFromAdId(currentDeleteCampaignObj.adId);
    if (response.data.success) {
      fetchCampaign();
      closeDeleteCampaignModal();
      notification.success({
        message: "Campaign request has been deleted",
      });
    } else {
      notification.error({ message: "Failed to delete request, Try again" });
    }
  };

  const removeMultipleCampaign = async () => {
    const adIdsToGetRemoved = selectedRowKeys.map((val) => {
      return campaignList[val];
    });
    let deleteResponseData = [];
    for (let i = 0; i < adIdsToGetRemoved.length; i++) {
      let response = await removeCampaignFromAdId(adIdsToGetRemoved[i].adId);
      let tempResponseData = {
        name: adIdsToGetRemoved[i].nameOfBusiness,
        success: response.data.success,
        message: response.data.success
          ? "Campaign Successfully Removed"
          : "Unable to Remove Campaign",
      };
      deleteResponseData.push(tempResponseData);
    }
    if (deleteResponseData.length > 0) {
      fetchCampaign();
      setSelectedRowKeys([]);
      notification.success({
        message: "Selected Campaign has been deleted",
      });
    } else {
      notification.error({ message: "Failed to delete request, Try again" });
    }
  };

  const columns = [
    {
      title: "",
      dataIndex: "nameOfBusiness",
      ellipsis: true,
      key: "nameOfBusiness",
      width: "10%",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "10%",
      render: (status) => (
        <Switch disabled={true} size="small" defaultChecked={status === 1 ? true : false} />
      ),
    },
    {
      title: "Impressions",
      dataIndex: "impressions",
      key: "impressions",
      width: "15%",
    },
    {
      title: "Clicks",
      dataIndex: "clicks",
      key: "clicks",
      width: "10%",
    },
    {
      title: "Average CTR",
      dataIndex: "averageCTR",
      key: "averageCTR",
      width: "10%",
    },
    {
      title: "Target Audience",
      dataIndex: "targeting",
      key: "targeting",
      width: "20%",
      render: (targeting) => (
        <div className="targetedPeople">
          {(targeting === 0 ||
            targeting === 1 ||
            targeting === 4 ||
            targeting === 5) && <div className="targetAudience">Org</div>}{" "}
          {(targeting === 0 ||
            targeting === 3 ||
            targeting === 5 ||
            targeting === 6) && <div className="targetAudience">Emp</div>}{" "}
          {(targeting === 0 ||
            targeting === 2 ||
            targeting === 4 ||
            targeting === 6) && <div className="targetAudience">CA</div>}
        </div>
      ),
    },
    {
      title: "Link",
      dataIndex: "destinationLink",
      ellipsis: true,
      key: "destinationLink",
      width: "10%",
      render: (destinationLink) => (
        // eslint-disable-next-line react/jsx-no-target-blank
        <a target="_blank" href={destinationLink}>{destinationLink}</a>
      ),
    },
    {
      dataIndex: "adId",
      key: "adId",
      width: "15%",
      render: (adId, rowData) => (
        <>
          <NormalButton
            type={5}
            icon={editLogo}
            onClick={() =>
              history.push("/admin/manageAds/newCampaign", {
                isUpdate: true,
                objData: rowData,
              })
            }
          />
          <NormalButton
            type={5}
            icon={deleteLogo}
            onClick={() => removeCampaign(rowData)}
          />
        </>
      ),
    },
  ];

  return (
    <>
      <div className="app-white-card manageAds-table-container">
        <div className="ads-table-header">
          <p className="app-heading-2">All Campaigns</p>
          <div className="table-header-right">
            {selectedRowKeys.length > 0 && (
              <DeleteOutlined
                className="table-header"
                size="large"
                onClick={removeMultipleCampaign}
              />
            )}
            <Button
              type="link"
              className="new-campaign-button"
              onClick={() =>
                history.push("/admin/manageAds/newCampaign", {
                  isUpdate: false
                })
              }
            >
              <EditFilled /> Create New Campaign
            </Button>
          </div>
        </div>
        <Table
          pagination={campaignList.length < 8 ? false : { pageSize: 8 }}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={campaignList}
        />
      </div>
      <Modal
        className="rejection-modal"
        visible={isDeleteCampaignModalVisible}
        onCancel={closeDeleteCampaignModal}
        centered
        footer={null}
        closable={false}
      >
        <h3 className="rejection-modal-header">
          Are you sure you want to delete the campaign record
        </h3>
        <div className="rejection-button">
          <NormalButton
            type={2}
            buttonText={"Cancel"}
            onClick={closeDeleteCampaignModal}
          ></NormalButton>
          <NormalButton
            type={1}
            buttonText="Delete"
            onClick={deleteCampaignRequest}
            margin={[0, 8]}
          />
        </div>
      </Modal>
    </>
  );
};

export default withRouter(ManageAdsTable);
