import axios from "./index";

export async function getAllOrg() {
  return axios.get(`admin/organizations`);
}

export async function getAllCa() {
  return axios.get(`admin/ca`);
}

export async function getAllRequest() {
  return axios.get(`admin/user/request/`);
}

export async function acceptOrgCaRequest(data) {
  return axios.post("admin/update/user/status", data);
}

export async function subscriptionChangeRequest(data) {
  return axios.post("organisation/create/update/subscription", data);
}

export async function getAllSupportTickets() {
  return axios.get(`support/requests`);
}

export async function updateSupportTicketStatus(data) {
  return axios.put("support/update/request", data);
}

export async function getDocumentURL(data) {
  return axios.get(`document`, { params: data });
}

export async function getUserFromPAN(data) {
  return axios.get(`user/form/pan/${data}`);
}

export async function getSubscriptionByPAN(data) {
  return axios.get(`organisation/subscriptions/by/${data}`);
}

export async function getNewCreditRequests(data) {
  return axios.get(`/mooldhan_request/${data}`);
}

export async function updateCreditStatus(data) {
  return axios.put("mooldhan_request", data);
}

export async function updateAddCreditStatus(data) {
  return axios.put("mooldhan", data);
}

export async function getSubscriptionDeductionData(data) {
  return axios.get(`subscription_deduction/${data}`);
}
//subscription_deduction

export async function deductSubscription(data) {
  return axios.post(`subscription_deduction`, data);
}

export async function getEmployeeInfo(params) {
  return axios.get(`user/info/next_month/${params}`);
}

export async function askMool(data) {
  let response = await axios.post(
    "https://payslip.moolfinance.com/salary/structure/",
    data
  );
  return response;
}

export async function fetchEmployees(data) {
  return axios.post("organisation/employee/list", data);
}

export async function getMoolCoinTransactions(data) {
  return axios.get(`mooldhan/get/transactions/${data}`);
}

export async function getBroadcastByStatus(data) {
  return axios.get(`get/broadcast/${data}`);
}

export async function newBroadcast(data) {
  return axios.post("new/broadcast", data);
}

export async function updateBroadcast(broadcastId, data) {
  return axios.put(`update/broadcast/${broadcastId}`, data);
}

export async function deleteBroadcast(broadcastId) {
  return axios.delete(`remove/broadcast/${broadcastId}`);
}

export async function newCampaignStart(data) {
  return axios.post("new/campaign", data);
}

export async function getCampaignList() {
  return axios.get(`get/campaign`);
}

export async function getCampaignFromAdId(data) {
  return axios.get(`get/campaign/${data}`);
}

export async function removeCampaignFromAdId(data) {
  return axios.delete(`remove/campaign/${data}`);
}

export async function updateCampaign(adId, data) {
  return axios.put(`update/campaign/${adId}`, data);
}

export async function removeAdsDocument(data) {
  return axios.delete(`remove/campaign/document/${data}`);
}

export async function getOrgInfo(organisationId) {
  return axios.get(`/org/info/${organisationId}`);
}

export async function updateOrgInstance(data) {
  return axios.put("/payout/update/organisationInstance", data);
}

// Compliance credentials

export async function getComplianceCredentialsByPANAndOrgId(
  PAN,
  organisationId
) {
  return axios.get(`/compliance/credential/${organisationId}/${PAN}`);
}

export async function getAllCredentials() {
  return axios.get("/compliance/credentials");
}

export async function getAllCredentialsByOrgId(organisationId) {
  return axios.get(`/compliance/credentials/${organisationId}`);
}

export async function updateComplaince(data) {
  return axios.put("compliance", data);
}

export async function getUsersStats(data) {
  return axios.get("admin/dashboard-stats", data);
}

export async function setAccountOriginalityStatus(data) {
  return axios.patch(
    `admin/organisation/set-originality/${data.orgId}/${data.original}`
  );
}

export async function setAccountBlockedStatus(data) {
  return axios.put(`admin/organisation/set-block-state`, data);
}

export async function deleteUserPermanently(userId) {
  return axios.delete(`admin/delete/user/${userId}`);
}

export async function verifyUserPassword(data) {
  return axios.post(`admin/self/authentication`, data);
}

export async function changedLoginStateAPI(data) {
  return axios.post(`change/loginState`, data);
}

export async function changedPayrollOutsourcing(data) {
  return axios.post(`admin/update/user/payrollOutsourcing`, data);
}

export async function getCalculator() {
  return axios.get(`calculator`);
}

export async function getSubscribeLeads() {
  return axios.get(`subscribe/list`);
}
