const { message } = require("antd");
const { endpoint } = require("./env");

const reimbursementTypes = {
  fuelReimbursement: "Fuel Reimbursement",
  travelReimbursement: "Travel Reimbursement",
  businessExpenseReimbursement: "Business Expense Reimbursement",
  medicalExpenseReimbursement: "Medical Expense Reimbursement",
  foodReimbursement: "Food Reimbursement",
  otherReimbursement: "Other Reimbursement",
}

const config = {
  monthList: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  reimbursementTypes,
  finMonthList: [
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
    "January",
    "February",
    "March",
  ],
  categoriesOfLogs: {
    0: "Default",
    1: "Payroll",
    2: "Profile",
    3: "Declaration",
    4: "Allowance",
    5: "Advance Tax",
    6: "Attendance",
    7: "Salary",
    8: "Access",
    9: "Proofs",
    10: "Perquisite",
    11: "Reimbursement",
    12: "Arrears And Bonuses",
    13: "House Rent Allowances",
    14: "Settings",
    15: "Data Sync",
    16: "Employee Manage",
    17: "Payout",
    18: "Full And Final",
  },
  rootUrl: endpoint,
  numformatter: function numFormatter(num) {
    num = Math.ceil(num);
    num = num + "" || "";
    let n1 = num.split(".");
    let n2 = n1[1] || null;
    n1 = n1[0].replace(/(\d)(?=(\d\d)+\d$)/g, "$1,");
    num = n2 ? n1 + "." + n2 : n1;
    return num;
  },
  validateEmail: function (email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  },
  deductionData: [
    {
      section: "80C",
      declaration: "employeeContributionToPF",

      label: "Employee Contribution to Provident Fund",
      bucket: "sec_80C_OnlyPF",
      bucketLabel: "Section 80C - Investments",
      deductionPresentationGroup: "Section 80C, 80CCC, 80CCD",
    },
    {
      section: "80C",
      declaration: "fiveYearFixedDepositScheduledBank",
      label: "5 Years of Fixed Deposit in Scheduled Bank",
      bucket: "sec_80C_OtherThanPF",
      bucketLabel: "Section 80C - Investments",
      deductionPresentationGroup: "Section 80C, 80CCC, 80CCD",
    },
    {
      section: "80C",
      declaration: "childrenTuitionFees",
      label: "Children Tuition Fees",
      bucket: "sec_80C_OtherThanPF",
      bucketLabel: "Section 80C - Investments",
      deductionPresentationGroup: "Section 80C, 80CCC, 80CCD",
    },
    {
      section: "80C",
      declaration: "depositInNSC",
      label: "Deposit in National Savings Certificate",
      bucket: "sec_80C_OtherThanPF",
      bucketLabel: "Section 80C - Investments",
      deductionPresentationGroup: "Section 80C, 80CCC, 80CCD",
    },
    {
      section: "80C",
      declaration: "depositInNSS",
      label: "Deposit in National Savings Schemes",
      bucket: "sec_80C_OtherThanPF",
      bucketLabel: "Section 80C - Investments",
      deductionPresentationGroup: "Section 80C, 80CCC, 80CCD",
    },
    {
      section: "80C",
      declaration: "depositInPostOfficeSavingsSchemes",
      label: "Deposit in Post Office Savings Schemes",
      bucket: "sec_80C_OtherThanPF",
      bucketLabel: "Section 80C - Investments",
      deductionPresentationGroup: "Section 80C, 80CCC, 80CCD",
    },
    {
      section: "80C",
      declaration: "equityLinkedSavingsScheme",
      label: "Equity Linked Savings Scheme ( ELSS )",
      bucket: "sec_80C_OtherThanPF",
      bucketLabel: "Section 80C - Investments",
      deductionPresentationGroup: "Section 80C, 80CCC, 80CCD",
    },
    {
      section: "80C",
      declaration: "infrastructureBonds",
      label: "Infrastructure Bonds",
      bucket: "sec_80C_OtherThanPF",
      bucketLabel: "Section 80C - Investments",
      deductionPresentationGroup: "Section 80C, 80CCC, 80CCD",
    },
    {
      section: "80C",
      declaration: "interestReinvestedNSC",
      label: "Interest on NSC Reinvested",
      bucket: "sec_80C_OtherThanPF",
      bucketLabel: "Section 80C - Investments",
      deductionPresentationGroup: "Section 80C, 80CCC, 80CCD",
    },
    {
      section: "80C",
      declaration: "kisanVikasPatra",
      label: "Kisan Vikas Patra (KVP)",
      bucket: "sec_80C_OtherThanPF",
      bucketLabel: "Section 80C - Investments",
      deductionPresentationGroup: "Section 80C, 80CCC, 80CCD",
    },
    {
      section: "80C",
      declaration: "lifeInsurancePremium",
      label: "Life Insurance Premium",
      bucket: "sec_80C_OtherThanPF",
      bucketLabel: "Section 80C - Investments",
      deductionPresentationGroup: "Section 80C, 80CCC, 80CCD",
    },
    {
      section: "80C",
      declaration: "longTermInfrastructureBonds",

      label: "Long term Infrastructure Bonds",
      bucket: "sec_80C_OtherThanPF",
      bucketLabel: "Section 80C - Investments",
      deductionPresentationGroup: "Section 80C, 80CCC, 80CCD",
    },
    {
      section: "80C",
      declaration: "mutualFunds",

      label: "Mutual Funds",
      bucket: "sec_80C_OtherThanPF",
      bucketLabel: "Section 80C - Investments",
      deductionPresentationGroup: "Section 80C, 80CCC, 80CCD",
    },
    {
      section: "80C",
      declaration: "ruralBondsNABARD",
      label: "NABARD Rural Bonds",
      bucket: "sec_80C_OtherThanPF",
      bucketLabel: "Section 80C - Investments",
      deductionPresentationGroup: "Section 80C, 80CCC, 80CCD",
    },
    {
      section: "80C",
      declaration: "nationalPensionScheme",

      label: "National Pension Scheme",
      bucket: "sec_80C_OtherThanPF",
      bucketLabel: "Section 80C - Investments",
      deductionPresentationGroup: "Section 80C, 80CCC, 80CCD",
    },
    {
      section: "80C",
      declaration: "schemeNHB",

      label: "NHB Scheme",
      bucket: "sec_80C_OtherThanPF",
      bucketLabel: "Section 80C - Investments",
      deductionPresentationGroup: "Section 80C, 80CCC, 80CCD",
    },
    {
      section: "80C",
      declaration: "postOfficeTimeDepositForFiveYears",

      label: "Post office time deposit for 5 years",
      bucket: "sec_80C_OtherThanPF",
      bucketLabel: "Section 80C - Investments",
      deductionPresentationGroup: "Section 80C, 80CCC, 80CCD",
    },
    {
      section: "80C",
      declaration: "pradhanMantriSurakshaBimaYojana",

      label: "Pradhan Mantri Suraksha Bima Yojana",
      bucket: "sec_80C_OtherThanPF",
      bucketLabel: "Section 80C - Investments",
      deductionPresentationGroup: "Section 80C, 80CCC, 80CCD",
    },
    {
      section: "80C",
      declaration: "publicProvidentFund",

      label: "Public Provident Fund",
      bucket: "sec_80C_OtherThanPF",
      bucketLabel: "Section 80C - Investments",
      deductionPresentationGroup: "Section 80C, 80CCC, 80CCD",
    },
    {
      section: "80C",
      declaration: "repaymentOfHousingLoanPrincipal",

      label: "Repayment of Housing loan(Principal amount)",
      bucket: "sec_80C_OtherThanPF",
      bucketLabel: "Section 80C - Investments",
      deductionPresentationGroup: "Section 80C, 80CCC, 80CCD",
    },
    {
      section: "80C",
      declaration: "stampDutyAndRegistrationCharges",

      label: "Stamp duty and Registration charges",
      bucket: "sec_80C_OtherThanPF",
      bucketLabel: "Section 80C - Investments",
      deductionPresentationGroup: "Section 80C, 80CCC, 80CCD",
    },
    {
      section: "80C",
      declaration: "sukanyaSamriddhiYojana",

      label: "Sukanya Samriddhi Yojana",
      bucket: "sec_80C_OtherThanPF",
      bucketLabel: "Section 80C - Investments",
      deductionPresentationGroup: "Section 80C, 80CCC, 80CCD",
    },
    {
      section: "80C",
      declaration: "unitLinkedInsurancePremium",

      label: "Unit Linked Insurance Premium (ULIP)",
      bucket: "sec_80C_OtherThanPF",
      bucketLabel: "Section 80C - Investments",
      deductionPresentationGroup: "Section 80C, 80CCC, 80CCD",
    },
    {
      section: "80CCC",
      declaration: "contributionPensionFund",

      label: "Contribution to Pension Fund",
      bucket: "sec_80C_OtherThanPF",
      bucketLabel: "Section 80CCC - Insurance Premium",
      deductionPresentationGroup: "Section 80C, 80CCC, 80CCD",
    },
    {
      section: "80D",
      declaration: "preventiveHealthCheckupDependantParents",

      label: "Preventive Health Checkup - Dependant Parents",
      limit: 5000,
      bucket: "sec_80D",
      bucketLabel: "Section 80D - Medical Insurance",
      deductionPresentationGroup: "Section 80D, 80DD, 80DDB, 80U",
    },
    {
      section: "80D",
      declaration: "medicalBillsVerySeniorCitizen",

      label: "Medical Bills - Very Senior Citizen",
      limit: 50000,
      bucket: "sec_80D",
      bucketLabel: "Section 80D - Medical Insurance",
      deductionPresentationGroup: "Section 80D, 80DD, 80DDB, 80U",
    },
    {
      section: "80D",
      declaration: "medicalInsurancePremiumBelow60",

      label: "Medical Insurance Premium For Person Below 60",
      limit: 25000,
      bucket: "sec_80D",
      bucketLabel: "Section 80D - Medical Insurance",
      deductionPresentationGroup: "Section 80D, 80DD, 80DDB, 80U",
    },
    {
      section: "80D",
      declaration: "medicalInsurancePremium60YearsAndAbove",

      label: "Medical Insurance Premium For Person 60 Years And Above",
      limit: 50000,
      bucket: "sec_80D",
      bucketLabel: "Section 80D - Medical Insurance",
      deductionPresentationGroup: "Section 80D, 80DD, 80DDB, 80U",
    },
    {
      section: "80D",
      declaration: "medicalInsurancePremiumDependantParentsBelow60",
      label: "Medical Insurance Premium - Dependant Parents Below 60",
      limit: 25000,
      bucket: "sec_80D",
      bucketLabel: "Section 80D - Medical Insurance",
      deductionPresentationGroup: "Section 80D, 80DD, 80DDB, 80U",
    },
    {
      section: "80D",
      declaration: "medicalInsurancePremiumDependantParents60YearsAbove",
      label: "Medical Insurance Premium - Dependant Parents 60 Years and Above",
      limit: 50000,
      bucket: "sec_80D",
      bucketLabel: "Section 80D - Medical Insurance",
      deductionPresentationGroup: "Section 80D, 80DD, 80DDB, 80U",
    },
    {
      section: "80D",
      declaration: "preventiveHealthCheckUp",
      label: "Preventive Health Check-up",
      limit: 5000,
      bucket: "sec_80D",
      bucketLabel: "Section 80D - Medical Insurance",
      deductionPresentationGroup: "Section 80D, 80DD, 80DDB, 80U",
    },
    {
      section: "80DD",
      declaration: "medicalTreatmentOrInsuranceHandicappedDependant",
      label: "Medical Treatment / Insurance of handicapped Dependant",
      limit: 75000,
      bucket: "sec_80DD_generalDisability",
      bucketLabel: "Section 80DD - Disabled Dependent",
      deductionPresentationGroup: "Section 80D, 80DD, 80DDB, 80U",
    },
    {
      section: "80DD",
      declaration: "medicalTreatmentOrInsuranceSevereHandicappedDependant",

      label: "Medical Treatment / Insurance of handicapped Dependant (Severe)",
      limit: 125000,
      bucket: "sec_80DD_severeDisability",
      bucketLabel: "Section 80DD - Disabled Dependent",
      deductionPresentationGroup: "Section 80D, 80DD, 80DDB, 80U",
    },
    {
      section: "80DDB",
      declaration: "medicalTreatmentSpecifiedDisease",

      label: "Medical Treatment ( Specified Disease only )",
      limit: 40000,
      bucket: "sec_80DDB_Others",
      bucketLabel: "Section 80DDB - Medical Expenditure",
      deductionPresentationGroup: "Section 80D, 80DD, 80DDB, 80U",
    },
    {
      section: "80DDB",
      declaration: "medicalTreatmentSpecifiedDiseaseSeniorCitizen",

      label: "Medical Treatment (Specified Disease only)- Senior Citizen",
      limit: 100000,
      bucket: "sec_80DDB_Senior",
      bucketLabel: "Section 80DDB - Medical Expenditure",
      deductionPresentationGroup: "Section 80D, 80DD, 80DDB, 80U",
    },
    {
      section: "80DDB",
      declaration: "medicalTreatmentSpecifiedDiseaseVerySeniorCitizen",

      label: "Medical Treatment (Specified Disease only)- Very Senior Citizen",
      limit: 100000,
      bucket: "sec_80DDB_Senior",
      bucketLabel: "Section 80DDB - Medical Expenditure",
      deductionPresentationGroup: "Section 80D, 80DD, 80DDB, 80U",
    },
    {
      section: "80U",
      declaration: "permanentPhysicalDisabilityBelow40Prcnt",

      label: "Permanent Physical disability (Below 40%)",
      limit: 75000,
      bucket: "sec_80U_generalDisability",
      bucketLabel: "Section 80U - Physical Disability",
      deductionPresentationGroup: "Section 80D, 80DD, 80DDB, 80U",
    },
    {
      section: "80U",
      declaration: "permanentPhysicalDisabilityAbove40Prcnt",

      label: "Permanent Physical disability (Above 40%)",
      limit: 125000,
      bucket: "sec_80U_criticalDisability",
      bucketLabel: "Section 80U - Physical Disability",
      deductionPresentationGroup: "Section 80D, 80DD, 80DDB, 80U",
    },
    {
      section: "80CCD1(B)",
      declaration: "contributionNPS2015_CCD1B",

      label: "80CCD1(B) - Contribution to NPS 2015",
      limit: 50000,
      bucket: "sec_80CCD_1B",
      deductionPresentationGroup: "Other Sections",
    },
    {
      section: "80CCG",
      declaration: "rajivGandhiEquityScheme",

      label: "80CCG - Rajiv Gandhi Equity Scheme",
      limit: 25000,
      bucket: "sec_80CCG",
      deductionPresentationGroup: "Other Sections",
    },
    {
      section: "80E",
      declaration: "interestLoanHigherSelfEducation",

      label: "80E - Interest on Loan of higher Self education",
      bucket: "sec_80E",
      deductionPresentationGroup: "Other Sections",
    },

    {
      section: "80EE",
      declaration: "additionalInterestOnHousingLoanBorrowed1stApr2016",

      label:
        "80EE - Additional Interest on housing loan borrowed as on 1st Apr 2016",
      limit: 50000,
      bucket: "sec_80EE",
      deductionPresentationGroup: "Other Sections",
    },
    {
      section: "80EEA",
      declaration: "additionalInterestOnHousingLoanBorrowed1stApr2019",

      label:
        "80EEA - Additional Interest on Housing loan borrowed as on 1st Apr 2019",
      limit: 150000,
      bucket: "sec_80EE",
      deductionPresentationGroup: "Other Sections",
    },
    {
      section: "80EEB",
      declaration: "interestOnElectricVehicleBorrowed1stApr2019",

      label: "80EEB - Interest on Electric Vehicle borrowed as on 1st Apr 2019",
      limit: 150000,
      bucket: "sec_80EE",
      deductionPresentationGroup: "Other Sections",
    },
    {
      section: "80TTA",
      declaration:
        "interestSavingsAccountDepositFDPostOfficeCooperativeSociety",

      label:
        "80TTA - Interest on Deposits in Savings Account, FDs, Post Office And Cooperative Society",
      limit: 10000,
      bucket: "sec_80TTA",
      deductionPresentationGroup: "Other Sections",
    },
    {
      section: "80TTB",
      declaration:
        "interestSavingsAccountDepositFDPostOfficeCooperativeSocietySeniorCitizen",

      label:
        "80TTB - Interest on Deposits in Savings Account, FDs, Post Office And Cooperative Society for Senior Citizen",
      limit: 50000,
      bucket: "sec_80TTB",
      deductionPresentationGroup: "Other Sections",
    },
    {
      section: "80G",
      declaration: "donation100PrcntExemption",

      label: "80G - Donation - 100% Exemption",
      bucket: "sec_80G",
      deductionPresentationGroup: "Other Sections",
    },
    {
      section: "80G",
      declaration: "donation50PrcntExemption",

      label: "80G - Donation - 50% Exemption",
      bucket: "sec_80G",
      deductionPresentationGroup: "Other Sections",
    },
    {
      section: "80G",
      declaration: "donationChildrenEducation",

      label: "80G - Donation - Children Education",
      bucket: "sec_80G",
      deductionPresentationGroup: "Other Sections",
    },
    {
      section: "80G",
      declaration: "donationPoliticalParties",

      label: "80G - Donation - Political Parties",
      bucket: "sec_80G",
      deductionPresentationGroup: "Other Sections",
    },
  ],
  deducationList: {
    "80C": {
      sectionName: "Section 80C ",
      sectionType: "Investment",
    },
    "80D": {
      sectionName: "Section 80D",
      sectionType: "Medical Insurance",
    },
    "80E": {
      sectionName: "Section 80E",
      sectionType: "Education Loan",
    },
    "80EE": {
      sectionName: "Section 80EE",
      sectionType: "Home Loan Interest",
    },
    "80TTA": {
      sectionName: "Section 80TTA",
      sectionType: "Interest on savings account",
    },
    "80G": {
      sectionName: "Section 80G ",
      sectionType: "Donation to charitable Organisation",
    },
    "80CCC": {
      sectionName: "Section 80CCC",
      sectionType: "Contribution to pension plan/ Annuity fund",
    },
    "80CCD(2)": {
      sectionName: "Section 80CCD(2)",
      sectionType: "Employer contribution in NPS",
    },
    "80DD": {
      sectionName: "Section 80DD",
      sectionType: "Disabled Dependent Deduction",
    },
    "80U": {
      sectionName: "Section 80U",
      sectionType: "Disability",
    },
    "80DDB": {
      sectionName: "Section 80DDB",
      sectionType: "Disability",
    },
  },
  deductionObjectList: {
    "80CCG": {
      declaration: "rajivGandhiEquityScheme",
      sectionType: "Savings",
      sectionName: "Section 80CCG ",
      subSections: {
        rajivGandhiEquityScheme: "80CCG - Rajiv Gandhi Equity Scheme",
      },
    },
    "80EEA": {
      declaration: "additionalInterestOnHousingLoanBorrowed1stApr2019",
      sectionName: "Section 80EEA ",
      sectionType: "Savings",
      subSections: {
        additionalInterestOnHousingLoanBorrowed1stApr2019:
          "80EEA - Additional Interest on Housing loan borrowed as on 1st Apr 2019",
      },
    },
    "80EEB": {
      declaration: "additionalInterestOnHousingLoanBorrowed1stApr2019",
      sectionName: "Section 80EEB ",
      sectionType: "Savings",
      subSections: {
        additionalInterestOnHousingLoanBorrowed1stApr2019:
          "80EEA - Additional Interest on Housing loan borrowed as on 1st Apr 2019",
      },
    },
    "24B": {
      sectionName: "Section 24B",
      sectionType: "Home Loan Interest",
      subSections: {
        preConstructionHomeLoanInterest:
          "Home Loan Interest - Pre Construction Period",
        currentYearHomeLoanInterest: "Home Loan Interest - Current Year ",
      },
    },
    "80TTB": {
      declaration: "rajivGandhiEquityScheme",
      sectionName: "Section 80TTB ",
      sectionType: "Savings",
      subSections: {
        interestSavingsAccountDepositFDPostOfficeCooperativeSocietySeniorCitizen:
          "80TTB - Interest on Deposits in Savings Account, FDs, Post Office And Cooperative Society for Senior Citizen",
      },
    },
    "80C": {
      sectionName: "Section 80C ",
      sectionType: "Investment",
      subSections: {
        employeeContributionToPF: "Employee Contribution to Provident Fund",
        fiveYearFixedDepositScheduledBank:
          "5 Years of Fixed Deposit in Scheduled Bank",
        childrenTuitionFees: "Children Tuition Fees",
        depositInNSC: "Deposit in National Savings Certificate",
        depositInNSS: "Deposit in National Savings Schemes",
        depositInPostOfficeSavingsSchemes:
          "Deposit in Post Office Savings Schemes",
        equityLinkedSavingsScheme: "Equity Linked Savings Scheme ( ELSS )",
        infrastructureBonds: "Infrastructure Bonds",
        interestReinvestedNSC: "Interest on NSC Reinvested",
        kisanVikasPatra: "Kisan Vikas Patra (KVP)",
        lifeInsurancePremium: "Life Insurance Premium",
        longTermInfrastructureBonds: "Long term Infrastructure Bonds",
        mutualFunds: "Mutual Funds",
        ruralBondsNABARD: "NABARD Rural Bonds",
        nationalPensionScheme: "National Pension Scheme",
        schemeNHB: "NHB Scheme",
        postOfficeTimeDepositForFiveYears:
          "Post office time deposit for 5 years",
        pradhanMantriSurakshaBimaYojana: "Pradhan Mantri Suraksha Bima Yojana",
        publicProvidentFund: "Public Provident Fund",
        repaymentOfHousingLoanPrincipal:
          "Repayment of Housing loan(Principal amount)",
        stampDutyAndRegistrationCharges: "Stamp duty and Registration charges",
        sukanyaSamriddhiYojana: "Sukanya Samriddhi Yojana",
        unitLinkedInsurancePremium: "Unit Linked Insurance Premium (ULIP)",
      },
    },
    "80D": {
      sectionName: "Section 80D",
      sectionType: "Medical Insurance",
      subSections: {
        preventiveHealthCheckupDependantParents:
          "Preventive Health Checkup - Dependant Parents",
        medicalBillsVerySeniorCitizen: "Medical Bills - Very Senior Citizen",
        medicalInsurancePremiumBelow60:
          "Medical Insurance Premium For Person Below 60",
        medicalInsurancePremium60YearsAndAbove:
          "Medical Insurance Premium For Person 60 Years And Above",
        medicalInsurancePremiumDependantParentsBelow60:
          "Medical Insurance Premium - Dependant Parents Below 60",
        medicalInsurancePremiumDependantParents60YearsAbove:
          "Medical Insurance Premium - Dependant Parents 60 Years and Above",
        preventiveHealthCheckUp: "Preventive Health Check-up",
      },
    },
    "80E": {
      sectionName: "Section 80E",
      sectionType: "Education Loan",
      subSections: {
        interestLoanHigherSelfEducation:
          "80E - Interest on Loan of higher Self education",
      },
    },
    "80EE": {
      sectionName: "Section 80EE",
      sectionType: "Home Loan Interest",
      subSections: {
        additionalInterestOnHousingLoanBorrowed1stApr2016:
          "80EE - Additional Interest on housing loan borrowed as on 1st Apr 2016",
      },
    },
    "80TTA": {
      sectionName: "Section 80TTA",
      sectionType: "Interest on savings account",
      subSections: {
        interestSavingsAccountDepositFDPostOfficeCooperativeSociety:
          "80TTA - Interest on Deposits in Savings Account, FDs, Post Office And Cooperative Society",
      },
    },
    "80G": {
      sectionName: "Section 80G ",
      sectionType: "Donation to charitable Organisation",
      subSections: {
        donation100PrcntExemption: "80G - Donation - 100% Exemption",
        donation50PrcntExemption: "80G - Donation - 50% Exemption",
        donationChildrenEducation: "80G - Donation - Children Education",
        donationPoliticalParties: "80G - Donation - Political Parties",
      },
    },
    "80CCC": {
      sectionName: "Section 80CCC",
      sectionType: "Contribution to pension plan/ Annuity fund",
      subSections: {
        contributionPensionFund: "Contribution to Pension Fund",
      },
    },
    "80CCD(2)": {
      sectionName: "Section 80CCD(2)",
      sectionType: "Employer contribution in NPS",
      subSections: {},
    },
    "80CCD1(B)": {
      sectionName: "Section 80CCD(2)",
      sectionType: "Employer contribution in NPS",
      subSections: {},
    },
    "80DD": {
      sectionName: "Section 80DD",
      sectionType: "Disabled Dependent Deduction",
      subSections: {
        medicalTreatmentOrInsuranceHandicappedDependant:
          "Medical Treatment / Insurance of handicapped Dependant",
        medicalTreatmentOrInsuranceSevereHandicappedDependant:
          "Medical Treatment / Insurance of handicapped Dependant (Severe)",
      },
    },
    "80U": {
      sectionName: "Section 80U",
      sectionType: "Disability",
      subSections: {
        permanentPhysicalDisabilityBelow40Prcnt:
          "Permanent Physical disability (Below 40%)",
        permanentPhysicalDisabilityAbove40Prcnt:
          "Permanent Physical disability (Above 40%)",
      },
    },
    "80DDB": {
      sectionName: "Section 80DDB",
      sectionType: "Disability",
      subSections: {
        medicalTreatmentSpecifiedDisease:
          "Medical Treatment ( Specified Disease only )",
        medicalTreatmentSpecifiedDiseaseSeniorCitizen:
          "Medical Treatment (Specified Disease only)- Senior Citizen",
        medicalTreatmentSpecifiedDiseaseVerySeniorCitizen:
          "Medical Treatment (Specified Disease only)- Very Senior Citizen",
      },
    },
  },
  getFinancialYears: () => {
    let startYear = 2023;
    let prevYear = 2022;
    let financialYears = [{ value: '2022-2023', label: '2022-2023' }];
    for (let i = 0; i < 30; i++) {
      startYear -= 1;
      prevYear -= 1;
      financialYears = [
        ...financialYears,
        {
          value: `${prevYear}-${startYear}`,
          label: `${prevYear}-${startYear}`,
        },
      ];
    }
    return financialYears;
  },
  getCurrentFinancialYear: () => {
    const currentDate = new Date();
    const month = currentDate.getMonth();
    const year = currentDate.getFullYear();
    let financialYear;
    if (month < 3) {
      financialYear = `${year - 1}-${year}`;
    } else {
      financialYear = `${year}-${year + 1}`;
    }
    return financialYear;
  },

  beforeFileUpload: function (file) {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "application/pdf";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      message.error("Image must smaller than 5MB!");
    }
    return isJpgOrPng && isLt2M;
  },
  createPayslipJSON: (data) => {
    const {
      deductions,
      allowances,
      user,
      customAllowances,
      organisationSettings,
      hra,
      ctc,
      reimbursements,
      monthClose,
      group
    } = data;
    let declarations = deductions;
    let declarationBucketNames = {};
    let chapter6A = [];

    let monthCloseData = monthClose.map((mclose) => {
      return {
        type: mclose.type,
        taxable: mclose.taxable,
        name: mclose.details ? mclose.details : mclose.othertxt,
        amount: parseInt(mclose.amount),
      };
    });

    let reimbursementsData = reimbursements.forEach((reim) => {
      if (reimbursementTypes[reim.type] && reim.amount > 0) {
        return {
          name: reimbursementTypes[reim.type],
          amount: parseInt(reim.amount),
        };
      }
    });

    declarations.forEach((d) => {
      let newDecl = { name: d.declaration, amount: parseInt(d.amount) };
      chapter6A.push(newDecl);
      declarationBucketNames[d.bucket] = {};
    });
    let declarationBuckets = [];
    Object.keys(declarationBucketNames).forEach((bucket) => {
      let declarationBucket = { declBucketName: bucket, totalAmount: 0 };
      declarations.forEach((decl) => {
        if (decl.bucket === bucket) {
          declarationBucket.totalAmount += parseInt(decl.amount);
        }
      });
      declarationBuckets.push(declarationBucket);
    });
    let allowance = Object.keys(allowances)
      .filter((k) => {
        return allowances[k].applicable;
      })
      .map((allowance) => {
        return {
          name: allowance,
          amount: allowances[allowance].amount ? parseInt(allowances[allowance].amount) : parseInt(allowances[allowance].actualExpense),
          actualExpense: parseInt(allowances[allowance].actualExpense)
            ? parseInt(allowances[allowance].actualExpense)
            : 0,
          meta: allowances[allowance].meta ? allowances[allowance].meta : {},
        };
      });

    let customAllowance = Object.keys(customAllowances)
      .filter((k) => {
        return customAllowances[k].applicable;
      })
      .map((allowance) => {
        return {
          name: allowance,
          amount: customAllowances[allowance].amount ? parseInt(customAllowances[allowance].amount) : parseInt(customAllowances[allowance].actualExpense),
          actualExpense: parseInt(customAllowances[allowance].actualExpense)
            ? parseInt(customAllowances[allowance].actualExpense)
            : 0,
          meta: customAllowances[allowance].meta
            ? customAllowances[allowance].meta
            : {},
        };
      });
    let houseRent = {
      status: hra.applicable,
      isMetro: hra.isMetro,
      rent: hra.rent ? hra.rent : 0,
    };
    let dataCustomPoint = 0;
    dataCustomPoint = parseFloat(dataCustomPoint);

    let pfEnabled = true;

    if (organisationSettings.pfEnabled !== undefined) {
      pfEnabled = Object.keys(group).length > 0 ? group.groupSettings.pfEnabled : organisationSettings.pfEnabled;
    }

    let employeeData = {
      regimeOptedStatus: user.regimeOptedStatus < 1 ? 0 : 1,
      customPoint: dataCustomPoint,
      sendGraphData: true,
      sendDebugInfo: false,
      pfEnabled,
      pfPaid: 0,
      taxPaid: 0,
      monthRemaining: 12,
      annualCTC: parseInt(ctc.ctc),
      stateAbbrev: "MH",
      feesOrCommissions: [],
      ctcVariableComponent: {
        status: true,
        percentage: ctc.variablePercentage
          ? parseInt(ctc.variablePercentage)
          : 0,
        variableCompensationReceived: 0,
        isOverAndAboveCTC: Object.keys(group).length > 0 ? group.groupSettings.variableAboveCTC : ctc.variableAboveCTC,
      },
      gratuity: organisationSettings.gratuity,
      houseRent,
      monthClose: monthCloseData,
      allowances: allowance,
      employerContributionToNPS_80CCD_2: 0,
      bonuses: [],
      reimbursements: reimbursementsData,
      customAllowances: customAllowance,
      perks: [],
      chapter6A,
    };
    return employeeData;
  },
};

export default config;