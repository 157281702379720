import {Row, Col, Skeleton} from "antd";
import { connect } from "react-redux";
import employeeNum from "../../../assets/icons/noOfEmployee.svg";

const NumberCards = (props) => {
  return (
        <Row gutter={[20, 20]} className="number-card-container">
          <Col xs={24} sm={12} md={12} lg={8}>
            <div className="number-card">
              <Skeleton loading={props.userStats.organisationCount === undefined} avatar active>
                <div className="card-logo" style={{ backgroundColor: "#f2eeff" }}>
                  <img alt="img" src={employeeNum} height="20" width="20" />
                </div>
                <div className="card-data-container">
                  <h4 className="card-data-heading">No of Organizations</h4>
                  <p className="card-data">{props.userStats.organisationCount}</p>
                </div>
              </Skeleton>
            </div>
          </Col>
          <Col xs={24} sm={12} md={12} lg={8}>
            <div className="number-card">
              <Skeleton loading={props.userStats.organisationCount === undefined} avatar active>
                <div className="card-logo" style={{ backgroundColor: "#f2eeff" }}>
                  <img alt="img" src={employeeNum} height="20" width="20" />
                </div>
                <div className="card-data-container">
                  <h4 className="card-data-heading">No of Employees</h4>
                  <p className="card-data">{props.userStats.employeeCount}</p>
                </div>
              </Skeleton>
            </div>
          </Col>
          <Col xs={24} sm={12} md={12} lg={8}>
            <div className="number-card">
              <Skeleton loading={props.userStats.organisationCount === undefined} avatar active>
                <div className="card-logo" style={{ backgroundColor: "#e4fff8" }}>
                  <img alt="img"  src={employeeNum} height="25" width="25" />
                </div>
                <div className="card-data-container">
                  <h4 className="card-data-heading">No of CA</h4>
                  <p className="card-data">{props.userStats.caCount}</p>
                </div>
              </Skeleton>
            </div>
          </Col>
        </Row>
  );
};

const NumberCardsContainer = connect((state) => ({
  allOrg: state.admin.allOrg,
  allCa: state.admin.allCa,
  userStats: state.admin.userStats
}))(NumberCards);

export default NumberCardsContainer;
