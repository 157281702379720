import { useState } from "react";
import {
  Input,
  Layout,
  Slider,
  Button,
  Skeleton,
  Row,
  Col,
  message,
} from "antd";
import config from "../../utils/config";
import { HeaderComponent } from "../Common";
import { getEmployeeInfo, askMool } from "../../apis/admin";

const { Content } = Layout;

const SalaryManagement = () => {
  const [loading, setLoading] = useState(false);
  const [finalPayslip, setFinalPayslip] = useState({
    basic: 0,
    bonuses: {},
    HRA: 0,
    LTA: 0,
    DA: 0,
    reimbursements: {},
    monthClose: {},
    customAllowances: {},
    employee_ESI: 0,
    employee_PF: 0,
    newRegime: {
      deductions: 0,
      incomeTax: 0,
      takeHome: 0,
    },
    oldRegime: {
      deductions: 0,
      incomeTax: 0,
      takeHome: 0,
    },
    otherAllowances: {},
    perks: {},
    proffTax: 0,
    specialAllowance: 0,
    totalEarnings: 0,
    totalFixedIncome: 0,
  });
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [PAN, setPAN] = useState("");
  const [moolData, setMoolData] = useState([]);

  const calculatePayslip = async () => {
    if (PAN.length < 1) {
      message.error("Please Enter PAN");
      return;
    }
    setLoading(true);
    const EmployeeBasicDetails = await getEmployeeInfo(PAN);
    if (!EmployeeBasicDetails.data.success) {
      message.error("Wrong User Info");
      setLoading(false);
      return;
    }
    const {
      user,
      organisation,
      declaration,
      ctc,
      reimbursements,
      monthClose,
      group
    } = EmployeeBasicDetails.data.data;
    const {
      houseRent,
      deductions,
      allowances,
      customAllowances,
    } = declaration.declarations;
    const { organisationSettings } = organisation;
    const payslipRequestBody = config.createPayslipJSON({
      ctc,
      reimbursements,
      monthClose,
      hra: houseRent,
      deductions,
      allowances,
      customAllowances,
      user,
      organisationSettings,
      group
    });
    const responseBody = await askMool(payslipRequestBody);
    const fp = responseBody.data.finalPayslip;
    const selIndex = ctc.selectedIndex || 0;
    setFinalPayslip({ ...fp });
    if (selIndex > 0) {
      setFinalPayslip({ ...responseBody.data.graphData[selIndex][3] });
    }
    setSelectedIndex(selIndex);
    setMoolData([...responseBody.data.graphData]);
    setLoading(false);
  };

  const DataItem = ({ itemName, amount }) => {
    return (
      <div className="individual-structure" key={itemName}>
        <p className="break-label">{itemName}</p>
        <p className="break-value">{config.numformatter(amount)} INR</p>
      </div>
    );
  };
  const updateValue = (e) => {
    setSelectedIndex(e);
    setFinalPayslip({ ...moolData[e][3] });
  };

  return (
    <div className="site-layout">
      <HeaderComponent name="Manage Salary" />
      <Content className="salary-container">
        <Row
          className="manage-salary"
          gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
        >
          <Col sm={24} md={10} className="graph-data">
            <h2>Calculate Next Month Salary</h2>
            <div>
              <Input
                placeholder="PAN Number"
                size="larger"
                value={PAN}
                onChange={(e) => {
                  setPAN(e.target.value.toUpperCase());
                }}
              />
              <hr />
              <Button onClick={calculatePayslip} type="primary" size="large">
                Calculate Payslip
              </Button>
            </div>
          </Col>
          <Col xs={24} sm={24} md={14} lg={14}>
            <section className="slider">
              <div className="slider-header">
                <h3>Modification scale</h3>
              </div>
              <div className="slider-container">
                <p>Takehome</p>
                <p>Savings</p>
              </div>
              <Slider
                value={selectedIndex}
                onChange={(val) => updateValue(val)}
                max={1000}
                min={0}
                handleStyle={{ height: "17px", width: "17px" }}
              />
            </section>
            <section className="break-down">
              <Row
                gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                className="padding-breakdown"
              >
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  className="break-down-section break-down-section-left "
                >
                  <Skeleton loading={loading} active paragraph={{ rows: 12 }}>
                    <h3>Earning</h3>
                    <DataItem
                      itemName={"Basic"}
                      amount={finalPayslip.basic}
                    ></DataItem>

                    {finalPayslip.HRA !== 0 && (
                      <DataItem
                        itemName={"House Rent ALlowances"}
                        amount={finalPayslip.HRA}
                      ></DataItem>
                    )}
                    {finalPayslip.DA !== 0 && (
                      <DataItem
                        itemName={"Dearness Allowances"}
                        amount={finalPayslip.DA}
                      ></DataItem>
                    )}
                    {finalPayslip.LTA !== 0 && (
                      <DataItem
                        itemName={"Leave Travel Allowances"}
                        amount={finalPayslip.LTA}
                      ></DataItem>
                    )}
                    <DataItem
                      itemName={"Special Allowances"}
                      amount={finalPayslip.specialAllowance}
                    ></DataItem>
                    {Object.keys(finalPayslip.otherAllowances).map((val) => (
                      <DataItem
                        itemName={val}
                        amount={finalPayslip.otherAllowances[val]}
                      ></DataItem>
                    ))}
                    {Object.keys(finalPayslip.customAllowances).map((val) => (
                      <DataItem
                        itemName={val}
                        amount={finalPayslip.customAllowances[val]}
                      ></DataItem>
                    ))}
                    {finalPayslip.reimbursements &&
                      Object.keys(finalPayslip.reimbursements).map((val) => (
                        <DataItem
                          itemName={val}
                          amount={finalPayslip.reimbursements[val]}
                        ></DataItem>
                      ))}

                    {Object.keys(finalPayslip.bonuses).map((val) => (
                      <DataItem
                        itemName={val}
                        amount={finalPayslip.bonuses[val]}
                      ></DataItem>
                    ))}
                    <DataItem
                      itemName={"Earning"}
                      amount={finalPayslip.totalEarnings}
                    ></DataItem>
                  </Skeleton>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  className="break-down-section"
                >
                  <Skeleton loading={loading} active paragraph={{ rows: 12 }}>
                    <h3>Deduction</h3>
                    {finalPayslip.employee_PF !== 0 && (
                      <DataItem
                        itemName={"PF"}
                        amount={finalPayslip.employee_PF}
                      ></DataItem>
                    )}
                    <DataItem
                      itemName={"Income Tax"}
                      amount={finalPayslip.oldRegime.incomeTax}
                    ></DataItem>
                    <DataItem
                      itemName={"Professional Tax"}
                      amount={finalPayslip.proffTax}
                    ></DataItem>
                    {finalPayslip.monthClose &&
                      Object.keys(finalPayslip.monthClose).map((val) => (
                        <DataItem
                          itemName={val}
                          amount={finalPayslip.monthClose[val]}
                        ></DataItem>
                      ))}
                    <div className="individual-structure">
                      <p className="break-label-deduction">Deduction</p>
                      <p className="break-value-deduction">
                        {config.numformatter(finalPayslip.oldRegime.deductions)}{" "}
                        INR
                      </p>
                    </div>
                  </Skeleton>
                </Col>
              </Row>
              <div className="individual-structure-takehome">
                <p className="break-label">Takehome</p>
                <p className="break-value">
                  {config.numformatter(finalPayslip.oldRegime.takeHome)} INR
                </p>
              </div>
            </section>
          </Col>
        </Row>
      </Content>
    </div>
  );
};

export default SalaryManagement;
