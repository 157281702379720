import { Table, Drawer } from "antd";
import { useEffect, useState } from "react";
import { getCalculator } from "../../../apis/admin";
import moment from "moment";
import { BsArrowUpRight } from "react-icons/bs";

const CalcDataCapturing = () => {
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const [calc, setCalc] = useState([]);
  const [listCount, setListCount] = useState(0);

  useEffect(() => {
    getCalculator()
      .then((res) => {
        if (res.data) {
          setCalc(res.data);
        } else {
          setCalc([]);
        }
      })
      .catch((err) => {
        setCalc([]);
      });
  }, []);
  const [currentRecord, setCurrentRecord] = useState([]);

  const columns = [
    {
      width: "20%",
      title: "User details",
      dataIndex: "name",
      key: "name",
      render: (name, data) => (
        <div className="user-details-container">
          <div className="user-name-and-email">
            <p className="user-name">{data.name ? data.name : "-"}</p>
            <p className="user-email">{data.email ? data.email : "-"}</p>
          </div>
        </div>
      ),
    },
    {
      title: "Timestamp",
      width: "15%",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => (
        <div className="date-of-joining">
          <p className="date-join">
            {moment(createdAt).format("MMMM Do YYYY")}
          </p>
          <p className="time-join">{moment(createdAt).format("hh: mm a")}</p>
        </div>
      ),
    },
    {
      title: "Gross salary",
      width: "10%",
      dataIndex: "salary",
      key: "salary",
      render: (ctc, data) => (
        <span className="gross-salary">{data.ctc ? data.ctc : 0}</span>
      ),
    },
    {
      title: "Inhand",
      width: "10%",
      dataIndex: "inhand",
      key: "inhand",
      render: (inhand, data) => (
        <span className="inhand">{data.inhand ? data.inhand : 0}</span>
      ),
    },
    {
      title: "HRA",
      width: "7%",
      dataIndex: "hra",
      key: "hra",
      render: (hra, data) => (
        <span className="hra">{data.hra ? data.hra : 0}</span>
      ),
    },
    {
      title: "TDS",
      width: "7%",
      dataIndex: "tds",
      key: "tds",
      render: (tds, data) => (
        <span className="tds">{data.tds ? data.tds : 0}</span>
      ),
    },
    {
      title: "PF",
      width: "7%",
      dataIndex: "pf",
      key: "pf",
      render: (pf, data) => <span className="pf">{data.pf ? data.pf : 0}</span>,
    },
    {
      width: "10%",
      dataIndex: "viewmore",
      key: "ViewMore",
      render: (key, data) => (
        <button
          className="link view-more"
          onClick={() => {
            showDrawer(true);
            setCurrentRecord(data);
          }}
        >
          View more
        </button>
      ),
    },
    {
      width: "20%",
      dataIndex: "moolGenerated",
      key: "MoolGenerated",
      render: () => (
        <a href="/" className="mool-generated">
          Mool Generated
          <span>
            <BsArrowUpRight />
          </span>
        </a>
      ),
    },
  ];

  return (
    <div className="app-white-card active-client-management-card">
      <div className="paper-header-container">
        <h3 className="paper-heading">List ({listCount})</h3>
      </div>
      <Table
        columns={columns}
        dataSource={calc}
        onChange={(pagination, filters, sorter, extra) => {
          setListCount(extra.currentDataSource.length);
        }}
        pagination={{ pageSize: 6 }}
      />

      <Drawer
        title={
          <div>
            <span className="drawer-name">
              {currentRecord.name ? currentRecord.name : "-"}
            </span>
            <p className="drawer-email">
              {currentRecord.email ? currentRecord.email : "-"}
            </p>
          </div>
        }
        placement="right"
        onClose={onClose}
        open={open}
        className="drawer"
      >
        <div>
          <h1>House details</h1>
          <table>
            <tbody>
              <tr>
                <td>User lives in metro ?</td>
                <td>:</td>
                <td>{currentRecord.isMetro ? "yes" : "no"}</td>
              </tr>
              <tr>
                <td>Rent of the house</td>
                <td>:</td>
                <td>{currentRecord.rent ? currentRecord.rent : 0}</td>
              </tr>
              <h1>Allowance (per month)</h1>
              <tr>
                <td>Food</td>
                <td>:</td>
                <td>{currentRecord.food ? currentRecord.food : 0}</td>
              </tr>
              <tr>
                <td>Dearness</td>
                <td>:</td>
                <td>{currentRecord.dearness ? currentRecord.dearness : 0}</td>
              </tr>
              <tr>
                <td>Health and insurance</td>
                <td>:</td>
                <td>{currentRecord.health ? currentRecord.health : 0}</td>
              </tr>
              <h1>Declaration</h1>
              <tr>
                <td>80C</td>
                <td>:</td>
                <td>{currentRecord.c ? currentRecord.c : 0}</td>
              </tr>
              <tr>
                <td>80D</td>
                <td>:</td>
                <td>{currentRecord.d ? currentRecord.d : 0}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </Drawer>
    </div>
  );
};

export default CalcDataCapturing;
