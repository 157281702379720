import { Spin, Switch } from "antd";
import { changedPayrollOutsourcing } from "../../../../../apis/admin";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const PayrollOutsourcing = ({ user }) => {

    let [payrollOutsourcingState, setPayrollOutsourcingState] = useState(false)
    let [apiLoading, setApiLoading] = useState(false)

    useEffect(() => {
        if (user)
            setPayrollOutsourcingState(user.isPayrollOutsourcing ?? false)
    }, [user])

    let onChange = async (status) => {
        try {
            setApiLoading(true)
            setPayrollOutsourcingState(status)
            let response = await changedPayrollOutsourcing({ organisationId: user.organisationId, isPayrollOutsourcing: status })
            if (response.status !== 200) {
                setPayrollOutsourcingState(!status)
            }
        } catch (error) {
            setPayrollOutsourcingState(!status)
        } finally {
            setApiLoading(false)
        }
    }
    return (
        <div className="originality-container">
            <Spin spinning={!user.organisationId || apiLoading}>
                <div className={"flex"}>
                    <div className="header-description">
                        <p className="heading">Enable PayrollOutsourcing</p>
                        <span className='description'>Enabling would add a organisation into special case <Link>Know More</Link>!</span>
                    </div>
                    <div className={"switch-container"}>
                        <p className={"switch-container-text-left"}>False</p>
                        <Switch onChange={(status) => { onChange(status) }} checked={payrollOutsourcingState ? true : false} />
                        <p className={"switch-container-text-right"}>True</p>
                    </div>
                </div>
            </Spin>
        </div>
    )
}

export default PayrollOutsourcing