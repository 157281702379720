import React, { useEffect, useState } from "react";
import { Dashboard } from "./../../components";
import { Switch, Route, Link } from "react-router-dom";
import { Layout, Menu } from "antd";
import "./PrivateRoutes.less";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import activeDashboard from "../../assets/icons/activeIcons/activeDashboard.svg";
import dashboard from "../../assets/icons/dashboard.svg";
import ClientManagement from "../../components/ClientManagement";
import OrganisationDetails from "../../components/OrganisationDetails";
import EmployeesDetails from "../../components/EmployeeDetails";
import {
  BroadcastMessage,
  Support,
  SubscriptionManagement,
  SalaryManagement,
  ManageAds,
  NewCampaign,
} from "../../components";
import admin from "../../redux/modules/admin";
import activeSupportIcon from "../../assets/icons/activeIcons/ActiveSupportIcon.svg";
import activeBroadcastLogo from "../../assets/icons/activeIcons/activeBroadcastLogo.svg";
import logo from "../../assets/icons/sidebarLogo.svg";
import ComplianceCredential from "../../components/ComplianceCredentials/ComplianceCredential";
import ManageCredentials from "../../components/ComplianceCredentials/ManageCredential";
import WebsiteData from "../../components/WebsiteData/WebsiteData";
import DocumentCheck from "../../components/DocumentCheck/documentCheck";

const { Sider } = Layout;

const routes = {
  redirect: "/employee/dashboard",
  routes: {
    dashboard: {
      name: "Dashboard",
      path: "/dashboard",
      component: Dashboard,
      icon: dashboard,
      activeIcon: activeDashboard,
      view: true,
      subpath: "/admin",
    },
    clientManagement: {
      name: "Client Management",
      path: "/client-management",
      component: ClientManagement,
      icon: dashboard,
      activeIcon: activeDashboard,
      view: true,
      subpath: "/admin",
    },
    organisationDetails: {
      name: "Organisation Details",
      path: "/organisationDetails/:PAN",
      component: OrganisationDetails,
      icon: activeSupportIcon,
      activeIcon: activeSupportIcon,
      view: false,
      subpath: "/admin",
    },
    employeeDetails: {
      name: "Employee Details",
      path: "/employeeDetails/:orgPAN/:PAN",
      component: EmployeesDetails,
      icon: activeSupportIcon,
      activeIcon: activeSupportIcon,
      view: false,
      subpath: "/admin",
    },
    manageAds: {
      name: "Manage Ads",
      path: "/manageAds",
      component: ManageAds,
      icon: dashboard,
      activeIcon: activeDashboard,
      view: true,
      subpath: "/admin",
    },
    newCampaign: {
      name: "New Campaign",
      path: "/manageAds/newCampaign",
      component: NewCampaign,
      icon: dashboard,
      activeIcon: activeDashboard,
      view: false,
      subpath: "/admin",
    },
    // newCampaign: {
    //   name: "New Campaign",
    //   path: "/manageAds/campaign",
    //   component: NewCampaign,
    //   icon: dashboard,
    //   activeIcon: activeDashboard,
    //   view: false,
    //   subpath: "/admin",
    // },
    subscription_management: {
      name: "Subscription Management",
      path: "/subscription_management",
      component: SubscriptionManagement,
      icon: dashboard,
      activeIcon: activeDashboard,
      view: true,
      subpath: "/admin",
    },
    // creditManagement: {
    //   name: "Credit Management",
    //   path: "/credit-management",
    //   component: CreditManagement,
    //   icon: dashboard,
    //   activeIcon: activeDashboard,
    //   view: true,
    //   subpath: "/admin",
    // },
    complianceCredentials: {
      name: "Compliance Credentials",
      path: "/compliance-credentials",
      component: ComplianceCredential,
      icon: dashboard,
      activeIcon: activeDashboard,
      view: true,
      subpath: "/admin",
    },
    individualComplianceCredential: {
      name: "Personal Credential",
      path: "/compliance-credential/:organisationId/:PAN",
      component: ManageCredentials,
      icon: dashboard,
      activeIcon: activeDashboard,
      view: false,
      subpath: "/admin",
    },
    Support: {
      name: "Support",
      path: "/support",
      component: Support,
      icon: activeSupportIcon,
      activeIcon: activeSupportIcon,
      view: true,
      subpath: "/admin",
    },
    salary_check: {
      name: "Salary Check",
      path: "/salary_check",
      component: SalaryManagement,
      icon: activeSupportIcon,
      activeIcon: activeSupportIcon,
      view: true,
      subpath: "/admin",
    },
    BroadcastMessage: {
      name: "Broadcast",
      path: "/broadcast-message",
      component: BroadcastMessage,
      icon: activeBroadcastLogo,
      activeIcon: activeBroadcastLogo,
      view: true,
      subpath: "/admin",
    },
    websiteData: {
      name: "Website Data",
      path: "/website-data",
      component: WebsiteData,
      icon: dashboard,
      activeIcon: activeDashboard,
      view: true,
      subpath: "/admin",
    },
    documentCheck: {
      name: "Jann",
      path: "/jann",
      component: DocumentCheck,
      icon: dashboard,
      activeIcon: activeDashboard,
      view: true,
      subpath: "/admin",
    },
  },
};

function PrivateRoute({ loginstate, isLoggedIn, adminReducer, user }) {
  const [openedTab, setOpenedTab] = useState("menu-link-image-0");
  useEffect(() => {
    adminReducer.initiateAdmin();
  }, [adminReducer, user]);
  return (
    <Switch>
      <Layout className="display-container">
        {!loginstate && (
          <Sider
            theme="light"
            style={{
              background: "#FFF",
              minWidth: "300px!important",
              width: "300px!important",
            }}
          >
            <div className="logo">
              <img src={logo} alt="mool logo" className="mool-logo"></img>
            </div>
            <Menu
              className="sider"
              theme="light"
              mode="inline"
              defaultSelectedKeys={`admin/dashboard`}
              onSelect={({ key }) => setOpenedTab(key)}
            >
              {Object.keys(routes["routes"]).map((val, index) => {
                return (
                  routes["routes"][val].view && (
                    <Menu.Item
                      style={{
                        height: 40,
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                      key={`menu-link-${index}`}
                      icon={
                        <img
                          alt="img"
                          style={{
                            height: 12,
                            width: 12,
                          }}
                          key={`menu-link-image-${index}`}
                          className="sidebar-icon"
                          src={
                            openedTab === `menu-link-${index}`
                              ? routes["routes"][val].activeIcon
                              : routes["routes"][val].icon
                          }
                        />
                      }
                    >
                      <Link
                        key={`menu-link-text-${index}`}
                        to={
                          routes["routes"][val].subpath +
                          routes["routes"][val].path
                        }
                      >
                        {routes["routes"][val].name}
                      </Link>
                    </Menu.Item>
                  )
                );
              })}
            </Menu>
          </Sider>
        )}
        <Layout className="site-layout">
          {Object.keys(routes["routes"]).map((val, index) => {
            return (
              <Route
                exact
                key={`route-path-${index}`}
                path={
                  routes["routes"][val].subpath + routes["routes"][val].path
                }
                component={routes["routes"][val].component}
              />
            );
          })}
        </Layout>
      </Layout>
    </Switch>
  );
}

PrivateRoute.propTypes = {
  user: PropTypes.object.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  loginstate: PropTypes.bool.isRequired,
};

const PrivateContainer = connect(
  (state) => ({
    user: state.auth.user,
    isLoggedIn: state.auth.isLoggedIn,
    loginstate: state.auth.loginstate,
  }),
  (dispatch) => ({
    adminReducer: admin.getActions(dispatch),
  })
)(PrivateRoute);

export default PrivateContainer;
