import React, { useState, useEffect } from "react";
import { Table, Input, Button, Menu, Dropdown,Spin } from "antd";
import { DownOutlined } from "@ant-design/icons";
import moment from "moment";
import { NormalButton } from "../../ComponentLibrary";
import { getNewCreditRequests } from "../../../apis/admin";
import { connect } from "react-redux";
import orgTempLogo from "../../../assets/common/organisation_temporary_logo.svg";

const CreditLogs = ({ allOrg,allCa,fileView }) => {
  const [userCredits, setUserCredits] = useState([]);
  const [ organisationDisplayData,setOrganisationDisplayData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getUserCredited();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getUserCredited = async () => {
    setLoading(true);
    let creditData = await getNewCreditRequests(1);
    (creditData.data).forEach(element => {
      let name = fetchOrgName(element.PAN) ? fetchOrgName(element.PAN) : fetchCaName(element.PAN)
      element.name = name
    });
    (creditData.data).forEach(element => {
      let img = fetchOrgImg(element.PAN) ? fetchOrgImg(element.PAN) : fetchCaImg(element.PAN)
      element.img = img
    });
    setUserCredits(creditData.data);
    setOrganisationDisplayData(creditData.data);
    setLoading(false);
  };

  
  const getUserRejected = async () => {
    setLoading(true);
    let creditData = await getNewCreditRequests(2);
    (creditData.data).forEach(element => {
      let name = fetchOrgName(element.PAN) ? fetchOrgName(element.PAN) : fetchCaName(element.PAN)
      element.name = name
    });
    (creditData.data).forEach(element => {
      let img = fetchOrgImg(element.PAN) ? fetchOrgImg(element.PAN) : fetchCaImg(element.PAN)
      element.img = img
    });
    setUserCredits(creditData.data);
    setOrganisationDisplayData(creditData.data);
    setLoading(false);
  };

  const fetchOrgName = (PAN) => {
    let companyName = "Company";
    for (let i = 0; i < allOrg.length; i++) {
      if (allOrg[i].PAN === PAN) {
        companyName = allOrg[i].displayName;
        break;
      }
    }
    return companyName;
  };

  const fetchOrgImg = (PAN) => {
    let companyImg = "";
    for (let i = 0; i < allOrg.length; i++) {
      if (allOrg[i].PAN === PAN) {
        companyImg = allOrg[i].image;
        break;
      }
    }
    return companyImg;
  };

  const fetchCaName = (PAN) => {
    let companyName = "Company";
    for (let i = 0; i < allCa.length; i++) {
      if (allCa[i].PAN === PAN) {
        companyName = allCa[i].displayName;
        break;
      }
    }
    return companyName;
  };

  const fetchCaImg = (PAN) => {
    let companyImg = "";
    for (let i = 0; i < allCa.length; i++) {
      if (allCa[i].PAN === PAN) {
        companyImg = allCa[i].image;
        break;
      }
    }
    return companyImg;
  };

  const menu = (
    <Menu>
      <Menu.Item key="0" onClick={() => getUserCredited()}>
        Credited
      </Menu.Item>
      <Menu.Item key="1" onClick={() => getUserRejected()}>
        Rejected
      </Menu.Item>
    </Menu>
  );
  const searchOrganisation = (e) => {
    let newOrganisation = [];
    let SerachParams = e.target.value.toLowerCase();
    newOrganisation = userCredits.filter((val) => {
      return Object.keys(val).some((keys) =>
        val[keys]
          .toString()
          .toLowerCase()
          .includes(SerachParams)
      );
    });
    setOrganisationDisplayData(newOrganisation);
  };
  const columns = [
    {
      width: "15%",
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name, rowdata) => (
        <>
          <div className="org-list-name-container">
            <img
            alt="img"
              className={
                rowdata.img
                  ?"org-list-logo":"org-list-logo org-list-logo-color"}
              src={
                rowdata.img ? rowdata.img : orgTempLogo}
            ></img>
            <div className="org-list-name-and-pan">
            <p className="org-list-name">
                {rowdata.name}
              </p>
              <p className="org-list-pan">PAN:{rowdata.PAN}</p>
            </div>
          </div>
        </>
      ),
    },
    {
      width: "10%",
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => (
        <span className="date-of-joining">{moment(date).format("ll")}</span>
      ),
    },
    {
      width: "10%",
      title: "Requested On",
      dataIndex: "requestedOn",
      key: "requestedOn",
      render: (requestedOn) => (
        <span className="date-of-joining">
          {moment(requestedOn).format("ll")}
        </span>
      ),
    },
    {
      title: "Attachment",
      dataIndex: "documents",
      key: "documents",
      ellipsis: true,
      width: "15%",
      render: (documents, rowData) =>
        rowData.proofs.map((item, i) => {
          return (
            <NormalButton
              type={5}
              buttonText={`${item.name}`}
              padding={[0, 0]}
              color="blue"
              onClick={() => fileView(item)}
              className="attachment-buttons"
            />
          );
        }),
    },
    {
      width: "10%",
      title: "Added On",
      dataIndex: "addedOn",
      key: "addedOn",
      render: (addedOn, rowData) => (
        <span className="date-of-joining">
          {moment(rowData.updatedOn).format("ll")}
        </span>
      ),
    },
    {
      key: "credits",
      width: "10%",
      title: "Mool Credit",
      dataIndex: "credits",
      render: (credits, rowData) => (
        <span className={rowData.status === 1 ? "credited" :"rejected"}>{rowData.status === 1 ? `+ ${rowData.amount}` : "Rejected"}</span>
      ),
    },
  ];

  return (
    <>
    <Spin spinning={loading}>
      <div className="app-white-card active-client-management-card">
        <div className="app-flex-Logs-container">
          <h3 className="app-heading-2">Credit Logs</h3>
          <div className="app-heading-items">
            <Input
              onChange={searchOrganisation}
              className="credit-input-field"
              size={"large"}
              placeholder="search"
              bordered={false}
            ></Input>
            <Dropdown overlay={menu}>
              <Button className="edit-button">
                Type <DownOutlined />
              </Button>
            </Dropdown>
          </div>
        </div>
        <Table
          pagination={{ pageSize: 6 }}
          columns={columns}
          dataSource={organisationDisplayData.reverse()}
          size="middle"
        />
      </div>
    </Spin>
    </>
  );
};

const LogsContainer = connect((state) => ({
  allOrg: state.admin.allOrg,
  allCa: state.admin.allCa,
}))(CreditLogs);

export default LogsContainer;
