import { Input, Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import orgTempLogo from "../../../assets/common/organisation_temporary_logo.svg";
import admin from "../../../redux/modules/admin";


const Organisation = ({ allOrg }) => {
    const [displayOrganization, setDisplayOrganization] = useState([]);
    const [orgCount, setOrgCount] = useState(0);
    const columns = [
        {
            width: "38%",
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (name, data) => (
                <Link to={`/admin/organisationDetails/${data.PAN}`}>
                    <div className="org-list-name-container">
                        <img className={data.image ? "org-list-logo" : "org-list-logo org-list-logo-color"} src={data.image ? data.image : orgTempLogo} alt="img" />
                        <div className="org-list-name-and-pan">
                            <p className="org-list-name">{data.displayName}</p>
                            <p className="org-list-pan">{data.PAN}</p>
                        </div>
                    </div>
                </Link>
            ),
            sorter: (a, b) => a.displayName.localeCompare(b.displayName)
        },
        {
            title: "Date of Creation",
            width: "25%",
            dataIndex: "createdAt",
            key: "createdAt",
            render: createdAt => (
                <span className="date-of-joining">{moment(createdAt).format('MMMM Do YYYY')}</span>
            ),
            sorter: (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        },
        {
            className: "table-row-center-align",
            title: 'Status',
            width: "25%",
            dataIndex: 'status',
            key: 'status',
            render: (text) => {
                return text ? <span className="accepted">Accepted</span> : <span className="pending">Pending</span>
            },
            filters: [{ text: <span>Accepted</span>, value: true }, { text: <span>Pending</span>, value: false }],
            onFilter: (value, record) => record.status === value,
            filterSearch: true,
        },
        {
            className: "table-row-center-align",
            title: 'Account Originality',
            width: "25%",
            dataIndex: 'original',
            key: 'original',
            render: (original) => {
                return original ? <span className="accepted">Original</span> : <span className="pending">Not Original</span>
            },
            filters: [{ text: <span>original</span>, value: true }, { text: <span>not original</span>, value: false }],
            onFilter: (value, record) => record.original === value,
            filterSearch: true,

        },
    ];


    useEffect(() => {
        setOrgCount(displayOrganization.length);
    }, [displayOrganization])

    useEffect(() => {
        setDisplayOrganization(allOrg.sort((a, b) => a.createdAt < b.createdAt).reverse());
    }, [allOrg])

    const searchOrganization = (value) => {
        let SearchParams = value.toLowerCase();
        if (SearchParams.trim() !== "") {
            let newOrganization = [];
            newOrganization = allOrg.filter((val) => {
                return val['displayName'].toString().toLowerCase().includes(SearchParams) || val['PAN'].toString().toLowerCase().includes(SearchParams)
            });
            setDisplayOrganization([...newOrganization.sort((a, b) => a.createdAt < b.createdAt).reverse()]);
        } else {
            setDisplayOrganization(allOrg);
        }
    }


    return (
        <div className="app-white-card organisation-list-container">
            <div className="paper-header-container">
                <h3 className="paper-heading">Organizations ({orgCount})</h3>
                <div className="paper-heading-action">
                    <Input.Search
                        onPressEnter={(e) => { searchOrganization(e.target.value) }}
                        onSearch={(value, e) => { searchOrganization(value) }}
                        onClear
                        enterButton={true}
                        className="search-field"
                        size={"large"}
                        placeholder="Search Name or PAN"
                        allowClear
                    />
                </div>
            </div>
            <Table dataSource={displayOrganization} onChange={(pagination, filters, sorter, extra) => { setOrgCount(extra.currentDataSource.length) }} columns={columns} pagination={{ pageSize: 8 }} />
        </div>
    )
}


const OrganisationContainer = connect(
    state => ({
        allOrg: state.admin.allOrg,
    }),
    (dispatch) => ({
        adminReducer: admin.getActions(dispatch),
    })
)(Organisation)

export default OrganisationContainer;
