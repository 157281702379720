import { Table, Avatar, Image, Modal, notification } from "antd";
import moment from "moment";
import CALogo from "../../../assets/header/EmployeeLogo.svg";
import OrgLogo from "../../../assets/common/organisation_temporary_logo.svg";
import { NormalButton } from "../../ComponentLibrary";
import { useState } from "react";
import { deleteBroadcast } from "../../../apis/admin";

const SentMessage = ({ allBroadcastMessage, statusType, setDraftObj, setPageState, setIsUpdateCall, getBroadcast }) => {

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteBroadcastId, setDeleteBroadcastId] = useState("");

    const handleShowModalClose = () => {
        setDeleteBroadcastId("")
        setShowDeleteModal(false);
    }

    const handelShowModalOpen = (broadcastId) => {
        setDeleteBroadcastId(broadcastId.broadcastId)
        setShowDeleteModal(true);
    }

    const deleteDraft = async () => {
        let res = await deleteBroadcast(deleteBroadcastId)
        if (res.data.success) {
            getBroadcast();
            notification.success({ message: "Broadcast was succesfully deleted." })
            handleShowModalClose();
        } else {
            notification.error({ message: "Failed to delete the broadcast, please try again." });
        }
    }

    const columns = [
        {
            width: "10%",
            title: "Date",
            dataIndex: "updatedAt",
            key: "updatedAt",
            render: updatedAt => (
                <p>{moment(updatedAt).format("DD/MM/YYYY")}</p>
            )
        },
        {
            width: "20%",
            title: "Title",
            dataIndex: "title",
            key: "title",
        },
        {
            width: "50%",
            title: "Message",
            dataIndex: "message",
            key: "message",
        },
        {
            width: "15%",
            title: "Recipients",
            dataIndex: "status",
            key: "status",
            render: status => (
                <div className="flex">
                    {
                        (status === 0 || status === 1 || status === 4 || status === 5) && (
                            <div className="mx-8">
                                <Avatar
                                    style={{ backgroundColor: '#fff1de' }}
                                    src={<Image
                                        src={OrgLogo}
                                        preview={false}
                                        width={40}
                                    />}
                                    gap={50}
                                    size="large"
                                />
                            </div>
                        )
                    }
                    {
                        (status === 0 || status === 3 || status === 5 || status === 6) && (
                            <div className="mx-8">
                                <Avatar
                                    style={{ backgroundColor: '#fea101' }}
                                    src={<Image
                                        src={CALogo}
                                        preview={false}
                                        width={40}
                                    />}
                                    gap={50}
                                    size="large"
                                />
                            </div>
                        )
                    }
                    {
                        (status === 0 || status === 2 || status === 4 || status === 6) && (
                            <div className="mx-8">
                                <Avatar
                                    style={{ backgroundColor: '#43de90' }}
                                    src={<Image
                                        src={CALogo}
                                        preview={false}
                                    />}
                                    gap={50}
                                    size="large"
                                />
                            </div>
                        )
                    }
                </div >
            )
        },
        {
            width: "15%",
            title: "",
            dataIndex: "message",
            key: "edit",
            render: (message, rowData) => (
                <>
                    <NormalButton
                        buttonText="Delete"
                        type={5}
                        onClick={() => handelShowModalOpen(rowData)}
                    />
                </>
            )
        },
    ]

    const columnsWithEditButton = [
        {
            width: "10%",
            title: "Date",
            dataIndex: "updatedAt",
            key: "updatedAt",
            render: updatedAt => (
                <p>{moment(updatedAt).format("DD/MM/YYYY")}</p>
            )
        },
        {
            width: "15%",
            title: "Title",
            dataIndex: "title",
            key: "title",
        },
        {
            width: "45%",
            title: "Message",
            dataIndex: "message",
            key: "message",
        },
        {
            width: "15%",
            title: "Recipients",
            dataIndex: "status",
            key: "status",
            render: status => (
                <div className="flex">
                    {
                        (status === 0 || status === 1 || status === 4 || status === 5) && (
                            <div className="mx-8">
                                <Avatar
                                    style={{ backgroundColor: '#fff1de' }}
                                    src={<Image
                                        src={OrgLogo}
                                        preview={false}
                                        width={40}
                                    />}
                                    gap={50}
                                    size="large"
                                />
                            </div>
                        )
                    }
                    {
                        (status === 0 || status === 3 || status === 5 || status === 6) && (
                            <div className="mx-8">
                                <Avatar
                                    style={{ backgroundColor: '#fea101' }}
                                    src={<Image
                                        src={CALogo}
                                        preview={false}
                                        width={40}
                                    />}
                                    gap={50}
                                    size="large"
                                />
                            </div>
                        )
                    }
                    {
                        (status === 0 || status === 2 || status === 4 || status === 6) && (
                            <div className="mx-8">
                                <Avatar
                                    style={{ backgroundColor: '#43de90' }}
                                    src={<Image
                                        src={CALogo}
                                        preview={false}
                                    />}
                                    gap={50}
                                    size="large"
                                />
                            </div>
                        )
                    }
                </div >
            )
        },
        {
            width: "15%",
            title: "",
            dataIndex: "message",
            key: "edit",
            render: (message, rowData) => (
                <>
                    <NormalButton
                        buttonText="Edit"
                        type={8}
                        onClick={() => {
                            setDraftObj(rowData)
                            setIsUpdateCall(true)
                            setPageState(2)
                        }}
                    />
                    <NormalButton
                        buttonText="Delete"
                        type={5}
                        onClick={() => handelShowModalOpen(rowData)}
                    />
                </>
            )
        },
    ]

    return (
        <div>
            <Table
                style={{ fontWeight: 500 }}
                columns={statusType === 0 ? columnsWithEditButton : columns}
                dataSource={allBroadcastMessage}
                pagination={allBroadcastMessage.length < 9
                    ? false
                    : { pageSize: 8 }
                }
            />

            <Modal
                visible={showDeleteModal}
                onCancel={handleShowModalClose}
                footer={false}
                closable={false}
                centered
                style={{
                    borderRadius: 10
                }}
            >
                <div>
                    <p className="danger-text font-size-18 font-weight-900">Are you sure you want to delete this Broadcast</p>
                    <div className="m-top-36 text-align-right">
                        <NormalButton
                            buttonText="Cancel"
                            type={5}
                            onClick={handleShowModalClose}
                        />
                        <NormalButton
                            buttonText="Delete"
                            type={3}
                            onClick={deleteDraft}
                        />
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default SentMessage;