import { Button, DatePicker, Table } from "antd";
import { Content } from "antd/lib/layout/layout";
import { withRouter } from "react-router-dom";
import { HeaderComponent } from "../Common";
import moment from "moment";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { getSubscriptionDeductionData } from "../../apis/admin";
import SubscriptionProgressModal from "./components/SubscriptionProgressModal";

const reportTableRows = [
  {
    title: "PAN",
    dataIndex: "PAN",
  },
  {
    title: "No of Employees",
    dataIndex: "noOfEmployees",
  },
  {
    title: "Amount Deducted Per Employee",
    dataIndex: "amountDeductedPerEmployee",
  },
  {
    title: "Total Deduction",
    dataIndex: "totalAmountDeducted",
  },
  {
    title: "Status",
    dataIndex: "isSuccess",
    render: (data, row) =>
      row.isSuccess ? (
        <p style={{ color: "green", fontWeight: 600 }}>Success</p>
      ) : (
        <p style={{ color: "red", fontWeight: 600 }}>Failed</p>
      ),
  },
];

const SubscriptionManagement = ({ allOrg }) => {
  const generateFinancialYear = () => {
    let tempMonth = Number(moment(reportDate).format("M"));
    let tempYear = Number(moment(reportDate).format("yyyy"));
    let tempFinancialYear =
      tempMonth > 3
        ? `${tempYear}-${tempYear + 1}`
        : `${tempYear - 1}-${tempYear}`;
    return tempFinancialYear;
  };
  const [reportDate, setReportDate] = useState(moment());
  const [reportData, setReportData] = useState([]);
  const [month, setMonth] = useState(Number(moment().format("M")) - 1);
  const [financialYear, setFinancialYear] = useState(generateFinancialYear());
  const [modalVisible, setModalVisible] = useState(false);
  let currentMonth = new Date().getMonth();
  let currentYear = new Date().getFullYear();

  const handelDateChange = (date) => {
    let tempMonth = Number(moment(date).format("M"));
    let tempYear = Number(moment(date).format("yyyy"));
    let tempFinancialYear =
      tempMonth > 3
        ? `${tempYear}-${tempYear + 1}`
        : `${tempYear - 1}-${tempYear}`;
    setMonth(tempMonth - 1);
    setFinancialYear(tempFinancialYear);
    setReportDate(date);
  };

  useEffect(() => {
    getSubscriptionDeductionData(`${financialYear}/${month}`).then((res) => {
      if (res.status === 200) {
        setReportData(res.data);
      }
    });
  }, [financialYear, month, reportDate]);

  const updateSubscriptionData = () => {
    getSubscriptionDeductionData(`${financialYear}/${month}`).then((res) => {
      if (res.status === 200) {
        setReportData(res.data);
      }
    });
  }

  return (
    <div>
      <HeaderComponent />
      <Content className="app-main-content-container app-white-card">
        <div className="app-flex-container-1">
          <h1 className="app-heading-1">Subscription Deduction Data</h1>
          <div className="flex align-center">
            {currentMonth === month &&
              currentYear === new Date(reportDate).getFullYear() && (
                <Button type="primary" onClick={() => setModalVisible(true)} style={{ marginRight: 10 }}>
                  Process Subscription
                </Button>
              )}
            <SubscriptionProgressModal
              allOrg={allOrg}
              updateSubscriptionData={updateSubscriptionData}
              modalVisible={modalVisible}
              setModalVisible={setModalVisible}
            />
            <div>
              <span className="app-label-1" style={{ margin: "0 10px" }}>Financial year</span>
              <DatePicker
                format={"YYYY - MM"}
                className="app-date-picker month-picker"
                onChange={handelDateChange}
                picker={"month"}
                value={reportDate}
              />
            </div>
          </div>
        </div>
        <Table columns={reportTableRows} dataSource={reportData} />
      </Content>
    </div>
  );
};

const SubscriptionManagementContainer = connect(
  (state) => ({
    allOrg: state.admin.allOrg,
  }),
  (dispatch) => ({})
)(SubscriptionManagement);

export default withRouter(SubscriptionManagementContainer);
