import { Spin, Table } from "antd";
import { useEffect, useState } from "react";
import { getAllJannRentReceiptDocument } from "../../../apis/jann";

const RentReceipt = () => {
  const [listCount, setListCount] = useState(0);

  const [dataSource, setDataSource] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setLoader(true)
    getAllJannRentReceiptDocument().then(res => {
      if (res.data.success) {
        setDataSource(res.data.data);
      } else {
        setDataSource([]);
      }
    }).catch(err => {
      setDataSource([]);
    });
    setLoader(false)
  }, []);




  const columns = [
    {
      width: "20%",
      title: "Name",
      dataIndex: "userID",
      key: "name",
      render: (key, data) => (
        <p className="user-name">{data?.owner?.name?.firstName}</p>
      ),
    },
    {
      title: "ID",
      width: "10%",
      dataIndex: "userID",
      key: "userID",
    },
    {
      title: "Rent Amount (INR)",
      width: "10%",
      dataIndex: "rentAmount",
      key: "rentAmount",
      render: (key, data) => {
        return (
          <span>{key} (INR)</span>
        )
      }
    },
    {
      title: "Start Date",
      width: "10%",
      dataIndex: "startDate",
      key: "startDate",
      render: (key, data) => {
        return (
          <span>{new Date(data.startDate).toDateString()}</span>
        )
      }
    },
    {
      title: "End Date",
      width: "10%",
      dataIndex: "endDate",
      key: "endDate",
      render: (key, data) => {
        return (
          <span>{new Date(data.endDate).toDateString()}</span>
        )
      }
    },
    {
      title: "Current Stage",
      dataIndex: ["digitalSigning", "currentStage"],
      key: "item",
    },
    {
      title: "Payment Status",
      dataIndex: ["digitalSigning", "payment", "status"],
      key: "payment",
    },
    {
      title: "Current Stage Status",
      dataIndex: "digitalSigning",
      key: "digitalSigning",
      render: (key, data) => {
        return (<span>
          {data.digitalSigning && data.digitalSigning[data.digitalSigning.currentStage] ? data.digitalSigning[data.digitalSigning.currentStage].status : ""}
        </span>)
      }
    },
  ];



  return (
    <div className="app-white-card active-client-management-card">
      <Spin spinning={loader}>
        <div className="paper-header-container">
          <h3 className="paper-heading">List ({listCount})</h3>
        </div>
        <Table
          columns={columns}
          dataSource={dataSource}
          onChange={(pagination, filters, sorter, extra) => {
            setListCount(extra.currentDataSource.length);
          }}
          pagination={{ pageSize: 6 }}
        />
      </Spin>
    </div>
  )
};

export default RentReceipt;
