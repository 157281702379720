import React,{ useState } from "react";
import { Tabs } from "antd";
import { HeaderComponent } from "../Common";
import CreditLogs from "./Components/creditLogs";
import Requests from "./Components/requests";
import "./creditManagement.less";
import { FileViewer } from "../ComponentLibrary";

const { TabPane } = Tabs;

const Index = () => {
  const [viewFile, setViewFile] = useState(false);
  const [viewFileDocumentProps, setViewFileDocumentProps] = useState({});
  const [
    viewFileDocumentVerifyProps,
    setViewFileDocumentVerifyProps,
  ] = useState({});

  const handelViewDocument = (fileProps) => {
    setViewFile(true);
    let tempFileProps = {
      documentKey: fileProps.key,
      documentType: fileProps.key.split(".").pop(),
      documentName: fileProps.key.split("Credits-").pop(),
    };
    let tempViewFileDocumentVerifyProps = {
      showVerificationStatus: false,
    };
    setViewFileDocumentProps(tempFileProps);
    setViewFileDocumentVerifyProps(tempViewFileDocumentVerifyProps);
  };

  return (
    <>
      <FileViewer
        visible={viewFile}
        onclose={setViewFile}
        fileProps={viewFileDocumentProps}
        verifyProps={viewFileDocumentVerifyProps}
      />
      <section className="site-layout">
        <HeaderComponent name="Credit Management" />
        <div className="app-main-content-container">
          <Tabs defaultActiveKey="1" size="large">
            <TabPane tab="Request" key="1">
              <Requests fileView={handelViewDocument}/>
            </TabPane>
            <TabPane tab="Logs" key="2">
              <CreditLogs fileView={handelViewDocument}/>
            </TabPane>
          </Tabs>
        </div>
      </section>
    </>
  );
};

export default Index;
