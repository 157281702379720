/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import './register.less';
import { Layout, Button, Input, Checkbox, notification } from 'antd';
import { FloatLabel } from '../Common'
import { withRouter, Link } from 'react-router-dom'
import {register} from '../../apis/auth';
import TermsAndCondition from './TermsAndCondition';
const { Content } = Layout;

function Register(props) {
    const [adminDetails, setAdminDetails] = useState({
        name: "",
        PAN: "",
        email: "",
        phone: "",
        secretPassword: "",
        password: ""
    })
    const [error, setError] = useState("");
    const [iserror, setIserror] = useState(false);
    const [termsAndCondition, setTermsAndCondition] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const registerEmployee = async () => {

        if (adminDetails.name === "") {
            setIserror(true)
            setError("Please Enter Firm Name")
        }
        else if (adminDetails.PAN === "") {
            setIserror(true)
            setError("Please Enter PAN Number")
        }
        else if (adminDetails.email === "") {
            setIserror(true)
            setError("Please Enter Your Email")
        }
        else if (adminDetails.phone === "") {
            setIserror(true)
            setError("Please Enter Your Phone Number")
        }
        else if (adminDetails.password === "") {
            setIserror(true)
            setError("Please Enter Password")
        }
        else if (adminDetails.secretPassword === "") {
            setIserror(true)
            setError("Please Enter Secret Password")
        }
        else if (!termsAndCondition) {
            setIserror(true)
            setError("Please verify and accept the terms and condition")
        }
        else {
            setIserror(false)
            let authResult = await register(adminDetails);
            setAdminDetails({
                name: "",
                PAN: "",
                email: "",
                secretPassword: "",
                password: ""
            })
            if (authResult.data.success) {
                notification.success({
                    message: "Organization created",
                    description: "Successfully created  the user please check your email for the default password",
                    placement: "topRight"
                })
                window.location = "/completionMessage"
            }
            else {
                notification.error({
                    message: authResult.data.message,
                    placement: "topRight"
                })
            }
        }
        if (iserror) {
            notification.error({
                message: error,
                placement: "topRight"
            })
        }
    }
    return (
        <Layout>
            <Content className="register-container">

                <div className="register-content">
                    <h2 className="register-heading">
                        Create an account
                    </h2>
                    <FloatLabel label="Name" name="name" value={adminDetails.name}>
                        <Input className="register-input"
                            value={adminDetails.name} onChange={e => {
                                setAdminDetails({ ...adminDetails, name: e.target.value})
                            }} />
                    </FloatLabel>
                    <FloatLabel label="Pan Number" name="PAN" value={adminDetails.PAN}>
                        <Input className="register-input"
                            bordered={false}
                            value={adminDetails.PAN}
                            onChange={e => {
                                if (e.target.value.length < 11)
                                    setAdminDetails({ ...adminDetails, PAN: e.target.value.toUpperCase() })
                            }} />
                    </FloatLabel>
                    <FloatLabel label="Email Address" name="email" value={adminDetails.email}>
                        <Input className="register-input"
                            bordered={false}
                            value={adminDetails.email}
                            onChange={e => setAdminDetails({ ...adminDetails, email: e.target.value })} />
                    </FloatLabel>
                    <FloatLabel label="Phone" name="phone" value={adminDetails.phone}>
                        <Input className="register-input"
                            bordered={false}
                            value={adminDetails.phone}
                            onChange={e => {
                                if (e.target.value.length < 11) {
                                    setAdminDetails({ ...adminDetails, phone: e.target.value })
                                }
                            }} />
                    </FloatLabel>
                    <Input.Password className="register-input"
                    size="large"
                    placeholder="Your Password"
                    value={adminDetails.password}
                            onChange={e => {
                                setAdminDetails({ ...adminDetails, password: e.target.value })
                                
                            }} />
                    <Input.Password className="register-input"
                    placeholder="Mool Admin Secret Password"

                    size="large"
                            value={adminDetails.secretPassword}
                            onChange={e => {
                                setAdminDetails({ ...adminDetails, secretPassword: e.target.value })
                                
                            }} />
                    <Checkbox checked={termsAndCondition} onClick={data => setTermsAndCondition(!termsAndCondition)} className="terms-and-condition-checkbox">
                        Accept <a onClick={() => setIsModalVisible(true)}>Terms and Conditions</a> of mool finance
                    </Checkbox>
                    <TermsAndCondition isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} />
                    <div className="submit-container">
                        <Button onClick={registerEmployee} className="submit-button" type="primary" htmlType="submit">
                            Create an Account
                        </Button>
                    </div>
                    <div className="goto-login-container">
                        <Link to="/login">
                            <p className="goto-login">
                                Already have an account? Login
                        </p>
                        </Link>
                    </div>
                </div>
                <h1 className="register-name">
                    Register Your Firm Into The System Of Easy client Management
                </h1>
            </Content>
        </Layout>
    )
}

export default withRouter(Register);
