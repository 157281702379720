import ActiveCampaigns from "../../../assets/icons/ActiveCampaigns.svg";
import TotalCampaigns from "../../../assets/icons/TotalCampaigns.svg";
import RevenueCreated from "../../../assets/icons/RevenueCreated.svg";

const NumCard = ({campaignList}) => {

  let active = 0;
  campaignList.forEach(element => {
    element.status === 1 && active++
  });
  return (
    <>
      <div className="app-white-card numCard-container">
        <div className="numCard-holder">
          <img src={TotalCampaigns} style={{width:"80px", height:"80px"}} alt="img" />
          <div className="numCard-content">
            <p className="numCard-header">Total Campaigns</p>
            <p className="numCard-amount">{campaignList.length}</p>
          </div>
        </div>
      </div>
      <div className="app-white-card numCard-container">
        <div className="numCard-holder">
          <img src={ActiveCampaigns} style={{width:"80px", height:"80px"}} alt="img" />
          <div className="numCard-content">
            <p className="numCard-header">Active Campaigns</p>
            <p className="numCard-amount">{active}</p>
          </div>
        </div>
      </div>
      <div className="app-white-card numCard-container">
        <div className="numCard-holder">
          <img src={RevenueCreated} style={{width:"80px", height:"80px"}} alt="img" />
          <div className="numCard-content">
            <p className="numCard-header">Revenue Created</p>
            <p className="numCard-amount">INR 50K</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default NumCard;
