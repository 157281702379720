import { Input, Table } from "antd";
import admin from "../../../redux/modules/admin";
import { connect } from "react-redux";
import { useState, useEffect } from "react";
import moment from "moment";
import EmployeeLogo from "../../../assets/header/EmployeeLogo.svg";

const CharteredAccountant = ({ adminReducer, allCa }) => {
  const [displayCA, setDisplayCA] = useState([]);

  useEffect(() => {
    setDisplayCA(allCa);
  }, [allCa]);


  const searchOrganization = (value) => {
    let SearchParams = value.toLowerCase();
    if (SearchParams.trim() !== "") {
      let newCA = [];
      newCA = allCa.filter((val) => {
        return val['displayName'].toString().toLowerCase().includes(SearchParams) || val['PAN'].toString().toLowerCase().includes(SearchParams)
      });
      setDisplayCA([...newCA.sort((a, b) => a.createdAt < b.createdAt).reverse()]);
    } else {
      setDisplayCA(allCa.sort((a, b) => a.createdAt < b.createdAt).reverse());
    }
  }

  const columns = [
    {
      width: "50%",
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name, data) => (
        <div className="org-list-name-container">
          <img
            className={
              data.image ? "ca-list-logo" : "ca-list-logo  ca-list-logo-color"
            }
            src={data.image ? data.image : EmployeeLogo}
            alt="img"
          />
          <div className="org-list-name-and-pan">
            <p className="org-list-name">{data.displayName}</p>
            <p className="org-list-pan">{data.PAN}</p>
          </div>
        </div>
      ),
    },
    {
      title: "Date of Creation",
      width: "20%",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => (
        <span className="date-of-joining">
          {moment(createdAt).format("MMMM Do YYYY")}
        </span>
      ),
    },
    {
      title: "Firm Name",
      width: "30%",
      dataIndex: "firmName",
      key: "firmName",
      render: (firmName) => <span className="date-of-joining">{firmName}</span>,
    },
  ];

  return (
    <div className="app-white-card active-client-management-card">
      <div className="paper-header-container">
        <h3 className="paper-heading">CA (Chartered Accountant)</h3>
        <div className="paper-heading-action">
          <Input.Search
            onPressEnter={(e) => { searchOrganization(e.target.value) }}
            onSearch={(value, e) => { searchOrganization(value) }}
            onClear
            enterButton={true}
            className="search-field"
            size={"large"}
            placeholder="Search Name or PAN"
            allowClear
          />
        </div>
      </div>
      <Table dataSource={displayCA} columns={columns} pagination={{ pageSize: 8 }} />
    </div>
  );
};

const CharteredAccountantContainer = connect(
  (state) => ({
    allCa: state.admin.allCa,
  }),
  (dispatch) => ({
    adminReducer: admin.getActions(dispatch),
  })
)(CharteredAccountant);

export default CharteredAccountantContainer;
