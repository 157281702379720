import { Content } from "antd/lib/layout/layout";
import React from "react";
import { HeaderComponent } from "../Common";
import { InputField } from "../ComponentLibrary";
import './complianceCredentials.less'
import { Table } from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import admin from '../../redux/modules/admin';

const columns = [
    {
        title: "PAN",
        ellipsis: true,
        key: "PAN",
        render: (text) => (
            <Link to={`/admin/compliance-credential/${text.organisationId}/${text.PAN}`}><h1 id="PAN">{text.PAN}</h1></Link>
        )
    },
    {
        title: "Employee Id",
        dataIndex: ["currentOrganisationMeta", "employeeId"],
        ellipsis: true,
        key: "employeeId",
    },
    {
        title: "Name",
        dataIndex: "displayName",
        ellipsis: true,
        key: "displayName",
    },
    {
        title: "Designation",
        dataIndex: "designation",
        ellipsis: true,
        key: "designation",
    },
    {
        title: "Phone",
        dataIndex: "phone",
        ellipsis: true,
        key: "phone",
    },
    {
        title: "Email",
        dataIndex: "primaryEmail",
        ellipsis: true,
        key: "primaryEmail",
    },
];

const ComplianceCredential = ({allOrg, adminReducer}) => {
    
    return (
        <div>
            <HeaderComponent />
            <Content className='app-main-content-container app-white-card'>
                <div className='app-flex-container-1'>
                    <h1 className='app-heading-1'>Compliance Credentials</h1>
                    <div className='flex align-center'>
                        <div>
                            <span className='app-label-1'>Organisation Id</span>
                            <InputField></InputField>
                        </div>
                    </div>
                </div>
                <Table dataSource={allOrg} columns={columns} />,
            </Content>
        </div>
    );
};

const ComplianceCredentialContainer = connect(
    state => ({
        allOrg: state.admin.allOrg,
    }),
    (dispatch) => ({
        adminReducer: admin.getActions(dispatch),
    })
)(ComplianceCredential)

export default ComplianceCredentialContainer;
