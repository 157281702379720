import { Spin, Switch } from "antd";
import { setAccountBlockedStatus } from "../../../../../apis/admin";
import { useEffect, useState } from "react";

const BlockCard = ({ user, blocked }) => {

    let [blockedState, setBlockedState] = useState(false)
    let [apiLoading, setApiLoading] = useState(false)

    useEffect(() => {
        setBlockedState(blocked)
    }, [blocked])

    let onChange = async (status) => {
        try {
            setApiLoading(true)
            setBlockedState(status)
            let response = await setAccountBlockedStatus({ id: user._id, disabled: status })
            if (response.status !== 200) {
                setBlockedState(!status)
            }
        } catch (error) {
            setBlockedState(!status)
        } finally {
            setApiLoading(false)
        }
    }

    return (
        <div className="originality-container">
            <Spin spinning={apiLoading}>
                <div className={"flex"}>
                    <p className="heading">Block/Disable Account</p>
                    <div className={"switch-container"}>
                        <p className={"switch-container-text-left"}>False</p>
                        <Switch onChange={(status) => { onChange(status) }} checked={blockedState ? true : false} />
                        <p className={"switch-container-text-right"}>True</p>
                    </div>
                </div>
            </Spin>
        </div>
    )
}

export default BlockCard