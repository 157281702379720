import auth from '../../../redux/modules/auth';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './Header.less'
import { Layout, Menu, Dropdown, Button } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import organisationLogo from "../../../assets/header/organisation_temporary_logo.svg";
import EmployeeLogo from "../../../assets/header/EmployeeLogo.svg";


const { Header } = Layout;

const HeaderComponent = (props) => {

    function handleMenuClick(e) {
        const { authReducer } = props
        authReducer.logout()
        window.location = "/"
    }

    const menu = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item key="0" icon={<LogoutOutlined />}>
                Logout
            </Menu.Item>
        </Menu>
    );
    return (
        <Header className="site-layout-header" style={{ padding: 0 }}>
            <div className="header-title">
                {props.icon ? <img alt="img" src={props.icon} /> : <></>}
                {props.name ? <h2>{props.name}</h2> : <></>}
            </div>
            <div className="logout-formm">
                <p className="name-container">{props.user.displayName}</p>
                <Dropdown overlay={menu}>
                    <Button className="dropdown-logo-container">
                        <img
                            alt="logo"
                            className="user-logo"
                            src={
                                props.image
                                    ? props.image
                                    : props.user.role === "org"
                                        ? organisationLogo
                                        : EmployeeLogo
                            }
                            style={
                                props.image
                                    ? {}
                                    : props.user.role === "org"
                                        ? { backgroundColor: "#fff1de" }
                                        : { backgroundColor: "#fea101" }
                            }
                        />
                    </Button>
                </Dropdown>

            </div>
        </Header>
    )
}

HeaderComponent.propTypes = {
    user: PropTypes.object.isRequired
}

const HeaderComponentContainer = connect(
    state => ({
        user: state.auth.user,
        image: state.auth.image
    }),
    dispatch => ({
        authReducer: auth.getActions(dispatch)
    })
)(HeaderComponent)

export default HeaderComponentContainer;