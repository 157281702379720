import { DatePicker, Row, Spin} from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import ComplianceDocument from "./ComplainceDocument";
import { getComplaince } from "../../../apis/apis";
import moment from "moment";
// const statusOptions = [
//   { label: "Pending", value: "0" },
//   { label: "Accepted", value: "1" },
//   { label: "Rejected", value: "2" },
// ];

const ManageDocuments = ({ userData, user }) => {
  const generateFinancialYear = () => {
    let tempMonth = Number(moment(reportDate).format("M"));
    let tempYear = Number(moment(reportDate).format("yyyy"));
    let tempFinancialYear =
      tempMonth > 3
        ? `${tempYear}-${tempYear + 1}`
        : `${tempYear - 1}-${tempYear}`;
    return tempFinancialYear;
  };
  const [reportDate, setReportDate] = useState(moment());

  const [month, setMonth] = useState(Number(moment().format("M")) - 1);
  const [financialYear, setFinancialYear] = useState(generateFinancialYear());

  const handelDateChange = (date) => {
    let tempMonth = Number(moment(date).format("M"));
    let tempYear = Number(moment(date).format("yyyy"));
    let tempFinancialYear =
      tempMonth > 3
        ? `${tempYear}-${tempYear + 1}`
        : `${tempYear - 1}-${tempYear}`;
    setMonth(tempMonth - 1);
    setFinancialYear(tempFinancialYear);
    setReportDate(date);
  };
  
  const [loading, setLoading] = useState(false);
  // TODO: Place these structure to a file and fetch these details from these files
  const [complaince, setComplaince] = useState({
    financialYear,
    organisationId: user.organisationId,
    month: parseInt(month),
    PF: {
      amount: 0,
      status: 0,
      document: [],
    },
    PT: {
      amount: 0,
      status: 0,
      document: [],
    },
    ESIC: {
      amount: 0,
      status: 0,
      document: [],
    },
    TDS: {
      amount: 0,
      status: 0,
      document: [],
    },
  });
  useEffect(() => {
    updateData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, reportDate]);

  const updateData = async () => {
    try {
      if (user.organisationId) {
        setLoading(true)
        let res = await getComplaince(user.organisationId, financialYear, month);
        let response = res.data;
        if (Object.keys(response).length) {
          let emptyObject = {
            amount: 0,
            status: 0,
            document: [],
          }
          let demoComplaince = {
            financialYear,
            month,
            organisationId: user.organisationId,
            PF: Object.keys(response.PF).length ? response.PF : emptyObject,
            PT: Object.keys(response.PT).length ? response.PT : emptyObject,
            ESIC: Object.keys(response.ESIC).length ? response.ESIC : emptyObject,
            TDS: Object.keys(response.TDS).length ? response.TDS : emptyObject,
          }
          setComplaince({ ...demoComplaince });
        }
        setLoading(false)
      }

    } catch (err) { }
  };
  return (
    <Content className="app-white-card">
      <h2>Manage Documents</h2>
      <Spin spinning={loading} size="large" className="credential-space-top">
        <Row className="justify-between mt-3">
          <DatePicker
              format={"YYYY - MM"}
              className="app-date-picker month-picker"
              onChange={handelDateChange}
              picker={"month"}
              value={reportDate}
            />
        </Row>
        <ComplianceDocument
          organisationId={user.organisationId}
          month={month}
          financialYear={financialYear}
          complianceType={0}
          Name="Profivend Fund"
          updateData={updateData}
          complaince={complaince.PF}
        />
        <ComplianceDocument
          organisationId={user.organisationId}
          month={month}
          financialYear={financialYear}
          complianceType={1}
          Name="Professional Tax"
          updateData={updateData}
          complaince={complaince.PT}
        />
        <ComplianceDocument
          organisationId={user.organisationId}
          month={month}
          financialYear={financialYear}
          complianceType={2}
          Name="ESIC"
          updateData={updateData}
          complaince={complaince.ESIC}
        />
        <ComplianceDocument
          organisationId={user.organisationId}
          month={month}
          financialYear={financialYear}
          complianceType={3}
          Name="TDS"
          updateData={updateData}
          complaince={complaince.TDS}
        />
      </Spin>
    </Content>
  );
};

export default ManageDocuments;
