import "./fileViewer.less";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { useState } from "react";
import { getDocumentURL } from "../../../apis/admin"
import { verifyNo, verifyYes } from "../../../assets/common/verifiedLogos"
import { NormalButton, FileLogos } from "../index";
import goBackSymbol from "../../../assets/common/goBackSymbol.svg";
import zoomSymbol from "../../../assets/common/zoomSymbol.svg";
import plusSymbol from "../../../assets/common/plusSymbol.svg";
import minusSymbol from "../../../assets/common/minusSymbol.svg";
import React from "react";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

const FileViewer = ({ visible, onclose, fileProps, verifyProps }) => {
    const [isFileLoading, setIsFileLoading] = useState(false);
    const [totalPages, settotalPages] = useState(1);
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [document, setDocument] = useState("");
    const [zoomLevel, setzoomLevel] = useState(1);

    const fetchFile = async () => {
        setIsFileLoading(true);
        let document = await getDocumentURL({Key: fileProps.documentKey});
        setDocument(document.data);
        if (fileProps.documentType !== "pdf") {
            setIsFileLoading(false)
        }
    };

    React.useEffect(() => {
        setDocument("");
        fetchFile()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileProps.documentKey])


    const handelZoom = (type) => {
        if (type === 1) {
            (zoomLevel <= 1) ? setzoomLevel(1) : setzoomLevel(zoomLevel - 0.1)
        }
        else {
            setzoomLevel(zoomLevel + 0.1)
        }
    }

    const handelPage = (type) => {
        type === 1
            ? currentPageNumber <= 1
                ? setCurrentPageNumber(1)
                : setCurrentPageNumber(currentPageNumber - 1)
            : currentPageNumber >= totalPages
                ? setCurrentPageNumber(totalPages)
                : setCurrentPageNumber(currentPageNumber + 1)
    }

    return (<>
        {
            visible
                ? <div style={{ width: "100vw" }}>
                    <div className="file-viewer-container"></div>
                    <div className="file-viewer-container-1">
                        <div className="top-bar">
                            <div className="top-bar-left">
                                <div className="back-button-container">
                                    <NormalButton
                                        dis
                                        type={4}
                                        icon={goBackSymbol}
                                        onClick={() => { onclose(false) }}
                                    />
                                </div>
                                <div className="file-type-container">
                                    {
                                        fileProps.documentType === "pdf"
                                            ? <FileLogos
                                                type={1}
                                            />
                                            : <FileLogos
                                                type={2}
                                            />
                                    }
                                </div>
                                <div className="file-name-container">
                                    <span>{fileProps.documentName}</span>
                                </div>
                            </div>
                            <div className="top-bar-right">
                                {
                                    verifyProps.showVerificationStatus
                                        ? verifyProps.userCanVerify
                                            ? verifyProps.fileIsVerified
                                                ? <div className="verified-text-container"><img alt="img" src={verifyYes} className="verified-logo-img" />Verified</div>
                                                : <div className="verified-text-container">
                                                    <NormalButton
                                                        buttonText="Verify The Document"
                                                        type={1}
                                                        onClick={() => verifyProps.verifyCallback(verifyProps.propsForVerifyCallback)}
                                                    />
                                                </div>
                                            : verifyProps.fileIsVerified
                                                ? <div className="verified-text-container"><img alt="img" src={verifyYes} className="verified-logo-img" />Verified</div>
                                                : <div className="verified-text-container"><img alt="img" src={verifyNo} className="verified-logo-img" />Pending</div>
                                        : <></>
                                }

                            </div>
                        </div>
                        <div className="document-display-area-zoom-container">
                            {
                                isFileLoading
                                    ? <div className="document-display-spin-container"><Spin indicator={antIcon} /></div>
                                    : ""
                            }
                            <div className="document-display-area" style={{ transform: `scale(${zoomLevel}` }}>
                                {fileProps.documentType === "pdf" ? (
                                    <>
                                        {
                                            document !== ""
                                                ? <Document
                                                    className="document-viewer"
                                                    file={document}
                                                    onLoadSuccess={(pn) => { settotalPages(pn.numPages); setIsFileLoading(false) }}
                                                >
                                                    <Page pageNumber={currentPageNumber} />
                                                </Document>
                                                : ""
                                        }
                                    </>
                                ) : (

                                    <img alt="img" src={document} className="document-display" />

                                )}
                            </div>
                        </div>
                        <div className="navigation-menu">
                        </div>
                    </div>
                    <div className="bottom-nav-panel-container">
                        <div className="bottom-nav-panel">
                            <NormalButton
                                dis
                                type={4}
                                icon={minusSymbol}
                                onClick={() => handelPage(1)}
                            />
                            <span>Page {currentPageNumber} / {totalPages}</span>
                            <NormalButton
                                dis
                                type={4}
                                icon={plusSymbol}
                                onClick={() => handelPage(2)}
                            />

                            <NormalButton
                                dis
                                type={4}
                                icon={minusSymbol}
                                onClick={() => handelZoom(1)}
                            />
                            <img alt="img" src={zoomSymbol}></img>
                            <NormalButton
                                dis
                                type={4}
                                icon={plusSymbol}
                                onClick={() => handelZoom(2)}
                            />
                        </div>
                    </div>
                </div>
                : ""
        }
    </>
    )
}

export default FileViewer;