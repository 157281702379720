import Chart from "react-apexcharts";
import config from "../../../utils/config";

const ChartsCards = (props) => {
  return (
    <div className="charts-card">
      <h4 className="card-data-heading">{props.heading}</h4>
      <div className="amount-and-option-button-container">
        <div>
          <span className="currency-format">{props.currency}</span>
          <span className="chart-amount">
            {props.input !== "pf"
              ? `${config.numformatter(79000)} INR`
              : `${config.numformatter(56500)} INR`}
          </span>
        </div>
      </div>
      <div className="chart">
        <Chart
          options={optionData}
          series={[
            {
              name: "Chart Heade",
              data: props.input === "pf" ? [...pfData] : [...tdsData],
            },
          ]}
          type={props.type}
          height={350}
        />
      </div>
    </div>
  );
};

let pfData = [12500, 13000, 14000, 17000];
let tdsData = [18000, 20000, 22000, 19000];

let optionData = {
  plotOptions: {
    bar: {
      borderRadius: 10,
      dataLabels: {
        position: "top",
      },
    },
  },
  xaxis: {
    categories: ["April", "May", "June", "July"],
    position: "top",
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    crosshairs: {
      fill: {
        type: "gradient",
        gradient: {
          colorFrom: "#D8E3F0",
          colorTo: "#BED1E6",
          stops: [0, 100],
          opacityFrom: 0.4,
          opacityTo: 0.5,
        },
      },
    },
    tooltip: {
      enabled: true,
    },
  },
};

export default ChartsCards;
