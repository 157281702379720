import { Input, notification, Table } from "antd"
import { NormalButton } from "../../ComponentLibrary";
import EmployeeLogo from "../../../assets/header/EmployeeLogo.svg";
import { getAllRequest, acceptOrgCaRequest } from "../../../apis/admin";
import { useState, useEffect } from "react"
import orgTempLogo from "../../../assets/common/organisation_temporary_logo.svg"
import { Link } from "react-router-dom";
import moment from "moment";

const RequestClientManagement = () => {
    const [displayOrganization, setDisplayOrganization] = useState([]);
    const [allRequest, setAllRequest] = useState([]);

    useEffect(() => {
        async function getRequest() {
            let allRes = await getAllRequest()
            let data = allRes.data.sort((a, b) => a.createdAt < b.createdAt).reverse();
            setAllRequest(data)
            setDisplayOrganization(data)
        }
        getRequest()
    }, [])

    const handelAcceptRequest = async (PAN) => {
        let response = await acceptOrgCaRequest({ PAN });
        if (response.data.success) {
            notification.success({ message: "Request has been accepted" })
            let allRes = await getAllRequest()
            setAllRequest(allRes.data)
        }
        else {
            notification.error({ message: "Failed to accept request, Please try again" })
        }
    }
    const columns = [
        {
            width: "30%",
            title: 'Name',
            dataIndex: 'firmName',
            key: 'firmName',
            render: (name, data) => (
                <>
                    {
                        data.role === "org"
                            ? <div className="org-list-name-container">
                                <img className={data.image ? "org-list-logo" : "org-list-logo org-list-logo-color"} src={data.image ? data.image : orgTempLogo} alt="img" />
                                <div className="org-list-name-and-pan">
                                    <Link className="org-list-name" to={`/admin/organisationDetails/${data.PAN}`} >{data.displayName}</Link>
                                    <p className="org-list-pan">PAN: {data.PAN}</p>
                                </div>
                            </div>
                            : <div className="org-list-name-container">
                                <img className={data.image ? "ca-list-logo" : "ca-list-logo  ca-list-logo-color"} src={data.image ? data.image : EmployeeLogo} alt="img" />
                                <div className="org-list-name-and-pan">
                                    <p className="org-list-name">{data.displayName}</p>
                                    <p className="org-list-pan">{data.PAN}</p>
                                </div>
                            </div>
                    }
                </>
            )
        },
        {
            title: "Email",
            width: "20%",
            dataIndex: "primaryEmail",
            key: "primaryEmail",
            render: primaryEmail => (
                <span className="date-of-joining">{primaryEmail}</span>
            )
        },
        {
            title: "Date of Creation",
            width: "25%",
            dataIndex: "createdAt",
            key: "createdAt",
            render: createdAt => (
                <span className="date-of-joining">{moment(createdAt).format('MMMM Do YYYY')}</span>
            )
        },
        {
            title: 'Accept',
            dataIndex: 'accept',
            key: 'accept',
            width: "20%",
            render: (PAN, data) => (
                <div>
                    {
                        <>
                            <NormalButton
                                type={1}
                                buttonText="Accept"
                                onClick={() => handelAcceptRequest(data.PAN)}
                            />
                            <NormalButton
                                type={5}
                                buttonText="Cancel"
                                disabled={true}
                            />
                        </>
                    }
                </div>
            )
        },
    ];

    const searchOrganization = (value) => {
        let SearchParams = value.toLowerCase();
        if (SearchParams.trim() !== "") {
            let newOrganization = [];
            newOrganization = allRequest.filter((val) => {
                return val['displayName'].toString().toLowerCase().includes(SearchParams) || val['PAN'].toString().toLowerCase().includes(SearchParams)
            });
            setDisplayOrganization([...newOrganization.sort((a, b) => a.createdAt < b.createdAt).reverse()]);
        } else {
            setDisplayOrganization(allRequest);
        }
    }


    return (
        <div className="app-white-card active-client-management-card">
            <div className="paper-header-container">
                <h3 className="paper-heading">Organizations</h3>
                <div className="paper-heading-action">
                    <Input.Search
                        onPressEnter={(e) => { searchOrganization(e.target.value) }}
                        onSearch={(value, e) => { searchOrganization(value) }}
                        onClear
                        enterButton={true}
                        className="search-field"
                        size={"large"}
                        placeholder="Search Name or PAN"
                        allowClear
                    />
                </div>
            </div>
            <Table pagination={{ pageSize: 6 }} dataSource={displayOrganization} columns={columns} showHeader={false} />
        </div>
    )
}

export default RequestClientManagement;