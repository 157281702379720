import React from "react";
import { Table } from "antd";
import moment from "moment";

const TransactionHistory = ({ historyList }) => {
  const TransactionsColumns = [
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      width: "15%",
      render: (createdAt) => moment(createdAt).format("ll"),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: "15%",
      render: type => (
        <p className={type === "credit" ? "success-text" : "danger-text"}>{type === "credit" ? "Credit" : "Debit"}</p>
        )
    },
    {
      title: "amount",
      dataIndex: "amount",
      key: "amount",
      width: "15%",
      render: (amount, data) => (
        <p className={data.type === "credit" ? "success-text" : "danger-text"}>{amount} {data.currencyType}</p>
      )
    },
    {
      title: "Balance",
      dataIndex: "mooldhanAmount",
      key: "mooldhanAmount",
      width: "15%",
      render: (mooldhanAmount, rowData) => (
        <p>
          {mooldhanAmount} {rowData.currencyType}
        </p>
      ),
    },
    {
        title: "Reason",
        dataIndex: "reason",
        key: "reason",
        width: "20%",
        render: (reason) => <p>{reason}</p>,
    },
  ];

  return (
    <div className="transaction-history-container">
      <h1 className="transaction-history-heading">Transaction History</h1>
      <Table
        pagination={historyList.length < 8 ? false : { pageSize: 8 }}
        columns={TransactionsColumns}
        dataSource={historyList.reverse()}
        scroll={{ x: 900 }}
      />
    </div>
  );
};

export default TransactionHistory;
