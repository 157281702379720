
import { Input, Modal, notification } from 'antd';
import React, { useState } from 'react';
import { verifyUserPassword } from '../../../apis/admin';

const UserVerification = ({ visible, setVisible, setAuthenticated }) => {
    const [password, setPassword] = useState("");
    const verify = () => {
        verifyUserPassword({
            password
        }).then(res => {
            if (res.data && res.data.success) {
                if (res.data.message && res.data.message.verified) {
                    setAuthenticated(true)
                }
                else {
                    notification.warn({ message: "Authentication failed!" })
                }
            } else {
                setAuthenticated(false)
                notification.warn({ message: "Authentication failed!" })
            }
            setVisible(false)
        })
    }
    return (
        <>
            <Modal
                title="Authentication Required!"
                centered
                visible={visible}
                okText={"Verify"}
                onOk={verify}
                onCancel={() => setVisible(false)}
            >
                <div className="organisation-details-container">
                    <Input.Password onPressEnter={(e) => { setPassword(e.target.value) }} autoComplete={false} placeholder="Enter Your Password" style={{ padding: "10px" }} value={password} onChange={(e) => { setPassword(e.target.value) }} />
                </div>
            </Modal>
        </>
    );
};

export default UserVerification;