import "./Support.less";
import { HeaderComponent } from "../Common"
import activeSupport from "../../assets/icons/activeIcons/ActiveSupportIcon.svg"
import { InputField, NormalButton, Status, FileViewer } from "../ComponentLibrary"
import { Table, Modal, Row, Col, Select, notification } from "antd";
import { useEffect, useState } from "react";
import { getAllSupportTickets, updateSupportTicketStatus } from "../../apis/admin"


const { Option } = Select;

const Support = () => {

    const [supportTickets, setSupportTickets] = useState([])

    async function getTickets() {
        const res = await getAllSupportTickets()
        if (res.status === 200) {
            setSupportTickets(res.data)
        }
    }

    useEffect(() => {
        getTickets()
    }, [])

    const columns = [
        {
            title: "Pan",
            dataIndex: "PAN",
            key: "pan",
            width: "13%",
        },
        {
            title: "Requested on",
            dataIndex: "requestedOn",
            key: "requestedOn",
            render: (date) => <p>{new Date(date).toDateString()}</p>,
            width: "13%",
        },
        {
            title: "Reason",
            dataIndex: "reason",
            key: "reason",
            width: "13%",
        },
        {
            title: "Phone",
            dataIndex: "phone",
            key: "phone",
            width: "13%",
        },
        {
            title: "Attachement",
            dataIndex: "attachments",
            key: "attachments",
            width: "13%",
            render: (attachments, rowData) => (
                <p>
                    {
                        rowData.attachments
                            ? <NormalButton
                                onClick={() => handelViewDocument(attachments[0])}
                                buttonText={"#1"}
                                type={7}
                            ></NormalButton>
                            : "* no attachment *"
                    }
                </p>
            )
        },
        {
            title: "",
            dataIndex: "status",
            key: "status",
            width: "13%",
            render: (status) => (
                <div>
                    {
                        status === 0
                            ? <Status
                                type={1} text="Pending"
                            />
                            : <Status
                                type={2} text="Resolved"
                            />
                    }
                </div>

            )
        },
        {
            title: "",
            key: "viewButton",
            width: "13%",
            render: (attachments, rowData) => (
                <NormalButton
                    type={5}
                    buttonText="View"
                    onClick={() => showSupportModal(rowData)}
                />
            )
        }
    ]

    const [isModalSupportModalVisible, setIsSupportModalVisible] = useState(false);
    const [currentSelectedTicket, setCurrentSelectedTicket] = useState({});
    const [viewFile, setviewFile] = useState(false);
    const [viewFileDocumentProps, setviewFileDocumentProps] = useState({});
    const [viewFileDocumentVerifyProps, setviewFileDocumentVerifyProps] = useState({});

    const showSupportModal = (currentSupportTicket) => {
        setIsSupportModalVisible(true);
        setCurrentSelectedTicket({
            ...currentSupportTicket,
            _status: currentSupportTicket.status,
        });
    };

    const handleSupportStatusChange = async () => {
        if (currentSelectedTicket.status !== currentSelectedTicket._status) {
            let tempData = {
                id: currentSelectedTicket._id,
                status: currentSelectedTicket._status,
            }
            let res = await updateSupportTicketStatus(tempData);
             if (res.data.success) {
                getTickets()
                setIsSupportModalVisible(false);
                setCurrentSelectedTicket({});
                notification.success({ message: "Successfully updated ticket status" });
                return;
            }
            else {
                notification.error({ message: "Failed to update status, Please try again" });
                return;
            }
        }
        setIsSupportModalVisible(false);
        setCurrentSelectedTicket({});

    };

    const handleSupportCancel = () => {
        setIsSupportModalVisible(false);
        setCurrentSelectedTicket({});
    };

    const handelViewDocument = (fileProps) => {
        setviewFile(true);
        let tempFileProps = {
            documentKey: fileProps.key,
            documentType: fileProps.key.split(".").pop(),
            documentName: fileProps.key.split("reimbursement-").pop(),
        };
        let tempViewFileDocumentVerifyProps = {
            showVerificationStatus: false,
        };
        setviewFileDocumentProps(tempFileProps);
        setviewFileDocumentVerifyProps(tempViewFileDocumentVerifyProps);
    };

    return (
        <>
            <FileViewer
                visible={viewFile}
                onclose={setviewFile}
                fileProps={viewFileDocumentProps}
                verifyProps={viewFileDocumentVerifyProps}
            />
            <div className="site-layout">
                <HeaderComponent icon={activeSupport} name={"Support"} />
                <div className="app-white-card app-main-content-container">
                    <div className="app-flex-container-1">
                        <h1 className="app-heading-1">Support</h1>
                        <InputField
                            type="2"
                        />
                    </div>
                    <Table columns={columns} dataSource={supportTickets.reverse()}></Table>
                </div>
            </div>
            <Modal
                visible={isModalSupportModalVisible}
                onCancel={handleSupportCancel}
                footer={false}
                closable={false}
                className="support-ticket-details-model-container"
                zIndex={50}
                pagination={{ pageSize: 8 }}
            >
                <div className="support-ticket-details-model">
                    <div className="app-flex-container-1">
                        <h3 className="app-heading-1">Ticket details</h3>
                        {
                            currentSelectedTicket.status === 0
                                ? <Select
                                    value={currentSelectedTicket._status}
                                    onChange={(e) => setCurrentSelectedTicket({
                                        ...currentSelectedTicket,
                                        _status: e
                                    })}
                                    className="support-ticket-modal-select-field"
                                >
                                    <Option
                                        value={0}
                                        className="support-ticket-modal-select-option"
                                    >
                                        In Progress
                                        </Option>
                                    <Option
                                        value={1}
                                        className="support-ticket-modal-select-option"
                                    >
                                        Resolved
                                    </Option>
                                </Select>
                                : <p className="support-ticket-modal-text-1">Resolved</p>
                        }

                    </div>
                    <Row>
                        <Col md={24} xl={12}>
                            <div className="app-input-container-1">
                                <label className="support-ticket-modal-label">Requested on</label>
                                <p className="support-ticket-modal-text-1">{currentSelectedTicket.requestedOn}</p>
                            </div>
                        </Col>
                        <Col md={24} xl={12}>
                            <div className="app-input-container-1">
                                <label className="support-ticket-modal-label">Phone number</label>
                                <p className="support-ticket-modal-text-1">{currentSelectedTicket.phone}</p>
                            </div>
                        </Col>
                        <Col md={24} xl={24}>
                            <div className="app-input-container-1">
                                <label className="support-ticket-modal-label">Attachment</label>
                                <p className="support-ticket-modal-text-1">
                                    {
                                        currentSelectedTicket.attachments
                                            ? <NormalButton
                                                buttonText={"#1"}
                                                type={7}
                                                margin={[10, 0]}
                                                onClick={() =>
                                                    handelViewDocument(currentSelectedTicket.attachments[0])
                                                }
                                            />
                                            : " No attachment "
                                    }
                                </p>
                            </div>
                        </Col>
                        <Col md={24} xl={24}>
                            <div className="app-input-container-1">
                                <label className="support-ticket-modal-label">Reason</label>
                                <p className="support-ticket-modal-text-2">{currentSelectedTicket.reason}</p>
                            </div>
                        </Col>
                    </Row>
                    <div className="support-ticket-details-model-buttons">
                        <NormalButton
                            type={2}
                            buttonText="Close"
                            margin={[10, 10]}
                            onClick={handleSupportCancel}
                        />
                        <NormalButton
                            type={1}
                            buttonText="Continue"
                            onClick={handleSupportStatusChange}
                        />
                    </div>
                </div>
            </Modal>

        </>
    )
}

export default Support