import { InputField, NormalButton } from "../../ComponentLibrary";
import { useState, useEffect } from "react";
import { Checkbox, Input, notification } from "antd";
import { newBroadcast, updateBroadcast } from "../../../apis/admin"

const { TextArea } = Input;

const CreateNewMessage = ({
    setPageState,
    draftObj,
    setDraftObj,
    isUpdateCall,
    setIsUpdateCall,
}) => {

    const [checkedOptions, setCheckedOptions] = useState([])
    const [newMessageBody, setNewMessageBody] = useState({
        message: "",
        title: "",
        status: null,
        messageStatus: null,
    })

    useEffect(() => {
        if (isUpdateCall) {
            setNewMessageBody({
                message: draftObj.message,
                title: draftObj.title,
                status: null,
                messageStatus: draftObj.messageStatus,
            })
            let arr = []
            if (draftObj.status === 0 || draftObj.status === 1 || draftObj.status === 4 || draftObj.status === 5) {
                arr.push('1')
            }
            if (draftObj.status === 0 || draftObj.status === 2 || draftObj.status === 4 || draftObj.status === 6) {
                arr.push('3')
            }
            if (draftObj.status === 0 || draftObj.status === 3 || draftObj.status === 5 || draftObj.status === 6) {
                arr.push('2')
            }
            setCheckedOptions([...arr])
        }
    }, [draftObj.message, draftObj.messageStatus, draftObj.status, draftObj.title, isUpdateCall])

    function onChange(checkedValues) {
        setCheckedOptions(checkedValues);
    }

    const options = [
        { label: 'Organisation', value: '1' },
        { label: 'Employees', value: '2' },
        { label: 'CA', value: '3' },
    ];

    const setStatus = () => {
        return new Promise((resolve, reject) => {
            let tempStatus = null;
            if (checkedOptions.includes('1')) {
                if (checkedOptions.includes('2')) {
                    if (checkedOptions.includes('3')) {
                        tempStatus = 0;
                    } else {
                        tempStatus = 5;
                    }
                } else if (checkedOptions.includes('3')) {
                    tempStatus = 4;
                } else {
                    tempStatus = 1;
                }
            } else if (checkedOptions.includes('2')) {
                if (checkedOptions.includes('3')) {
                    tempStatus = 6;
                } else {
                    tempStatus = 3;
                }
            } else if (checkedOptions.includes('3')) {
                tempStatus = 2;
            }
            resolve(tempStatus);
        })
    }

    const onSend = async () => {
        let tempNewMessage = newMessageBody;
        let tempStatus = await setStatus();
        tempNewMessage.status = tempStatus;
        tempNewMessage.messageStatus = 2;
        saveMessage(tempNewMessage, 2)
    }

    const onDraft = async () => {
        let tempNewMessage = newMessageBody;
        let tempStatus = await setStatus();
        tempNewMessage.status = tempStatus;
        tempNewMessage.messageStatus = 0;
        saveMessage(tempNewMessage, 0)
    }

    const saveMessage = async (tempNewMessage, type) => {
        if (!tempNewMessage.title) {
            notification.error({ message: "Broadcast title cannot be empty." });
            return;
        }
        if (tempNewMessage.status === null) {
            notification.error({ message: "Please select atleast one recipient." })
            return;
        }
        if (!tempNewMessage.message) {
            notification.error({ message: "Broadcast message cannot be empty." })
            return;
        }
        let res = {};
        if (isUpdateCall) {
            tempNewMessage.broadcastId = draftObj.broadcastId;
            res = await updateBroadcast(draftObj.broadcastId, tempNewMessage)
        } else {
            res = await newBroadcast(tempNewMessage);
        }
        if (res.data.success) {
            if (type === 0) {
                notification.success({ message: "The message was saved as draft" })
            }
            else if (type === 2) {
                notification.success({ message: "Successfully created new broadcast message." })
            }
            setPageState(1)
        }
        else {
            if (type === 0) {
                notification.error({ message: "Failed to send, please try again." })
            }
            else if (type === 2) {
                notification.error({ message: "Failed to save as draft, please try again." })
            }

        }
    }

    return (
        <div >
            <div className="paper-header-container">
                <div style={{
                    width: 500
                }}
                >
                    <label className="block m-bottom-16 font-size-16 font-weight-700">Title</label>
                    <InputField
                        value={newMessageBody.title}
                        onChange={(e) => {
                            setNewMessageBody({
                                ...newMessageBody,
                                title: e.target.value,
                            })
                        }}
                    />
                </div>
                <div className="paper-heading-action">
                    <NormalButton
                        type={5}
                        margin={[0, 10]}
                        buttonText="Cancel"
                        onClick={() => {
                            setPageState(1)
                            setDraftObj({})
                            setIsUpdateCall(false)
                        }}
                    />
                    <NormalButton
                        type={5}
                        margin={[0, 10]}
                        buttonText="Save as draft"
                        onClick={() => {
                            onDraft()
                            setDraftObj({})
                            setIsUpdateCall(false)
                        }}
                    />
                    <NormalButton
                        type={1}
                        margin={[0, 10]}
                        buttonText="Send"
                        onClick={() => {
                            onSend()
                            setDraftObj({})
                            setIsUpdateCall(false)
                        }}
                    />
                </div>
            </div>
            <div className="my-24">
                <label className="block m-bottom-16 font-size-16 font-weight-700">To</label>
                <Checkbox.Group
                    value={checkedOptions}
                    options={options}
                    defaultValue={['Pear']}
                    onChange={onChange}
                />
            </div>
            <div>
                <label className="block m-bottom-16 font-size-16 font-weight-700">Message</label>
                <TextArea
                    rows={20}
                    value={newMessageBody.message}
                    onChange={(e) => {
                        setNewMessageBody({
                            ...newMessageBody,
                            message: e.target.value,
                        })
                    }}
                />
            </div>
        </div>
    )
}

export default CreateNewMessage;