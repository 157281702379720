import { BranchesOutlined, DollarOutlined, HistoryOutlined, InfoCircleOutlined, SettingOutlined, UserOutlined, UserSwitchOutlined } from "@ant-design/icons";
import { Breadcrumb, Col, notification, Row, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { getMoolCoinTransactions, getOrgInfo, getUserFromPAN } from "../../apis/admin";
import { HeaderComponent } from "../Common";
import Logs from "../Logs/Logs";
import AddressUpdate from "./Components/AddressUpdate";
import ChildOrganisations from "./Components/ChildOrganisations";
import EmployeesDetails from "./Components/EmployeesDetails";
import NumCard from "./Components/numCard";
import OrganisationName from "./Components/organisationName";
import Payouts from "./Components/PayoutDetails";
import Settings from "./Components/Settings";
import TransactionHistory from "./Components/transactionHistory";
import ViewLog from "./Components/viewLog";
import "./OrganisationDetails.less";


const { TabPane } = Tabs;
const Index = () => {
  const [userDetails, setUserDetails] = useState({});
  const [employeeList, setEmployeeList] = useState([]);
  const [historyList, setHistoryList] = useState([]);
  const [payout, setPayout] = useState({});
  const { PAN } = useParams();

  useEffect(() => {
    getOrganisationData(PAN);
  }, [PAN]);

  const getOrganisationData = async (PAN) => {
    let organisationData = await getUserFromPAN(PAN);
    const organisationId = organisationData.data.organisationId;
    if (!organisationId) return notification.error("Error while getting organisation information")
    let org = await getOrgInfo(organisationId);
    let historyListData = await getMoolCoinTransactions(organisationId);
    setEmployeeList([...org.data.employeeList]);
    setUserDetails(org.data.user);
    setHistoryList([...historyListData.data]);
    setPayout({ ...org.data.payout })
  };
  return (
    <div className="site-layout">
      <HeaderComponent />
      <div className="app-breadcrum-container">
        <Breadcrumb className="app-breadcrum">
          <Breadcrumb.Item>
            <Link to={`/admin/client-management`}>Client Management</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item className="app-breadcrum-active">
            {userDetails.displayName}
          </Breadcrumb.Item>
        </Breadcrumb>
        <Tabs defaultActiveKey="1" >
          <TabPane
            tab={
              <div className="tabs">
                <span className="tab-name"><InfoCircleOutlined />Overview</span>
              </div>
            }
            key="1"
          >
            <div className="app-main-content" style={{ marginLeft: "-30px" }}>
              <Row gutter={[0, 10]}>
                <Col xs={24} sm={24} md={24} lg={12}>
                  <div className="org-details-column">
                    <OrganisationName user={userDetails} employeeList={employeeList} />
                    <ViewLog />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                  <div className="address-subscription">
                    <div className="subscription-type-column">
                      <NumCard user={userDetails} PAN={userDetails.PAN} employeeList={employeeList} />
                    </div>
                    <AddressUpdate user={userDetails} />
                  </div>
                </Col>

              </Row>
            </div>
          </TabPane>

          <TabPane
            tab={
              <div className="tabs">
                <span className="tab-name"><UserOutlined />Employees ({employeeList.length})</span>
              </div>
            }
            key="2"
          >
            <EmployeesDetails employeeList={employeeList} organisation={userDetails} />
          </TabPane>

          <TabPane
            tab={
              <div className="tabs">
                <span className="tab-name"><BranchesOutlined />Child Organisations</span>
              </div>
            }
            key="3"
          >
            <ChildOrganisations organisation={userDetails} />
          </TabPane>
          <TabPane
            tab={
              <div className="tabs">
                <span className="tab-name"><UserSwitchOutlined />Transaction History</span>
              </div>
            }
            key="4"
          >
            <TransactionHistory historyList={historyList} />
          </TabPane>
          <TabPane
            tab={
              <div className="tabs">
                <span className="tab-name"><DollarOutlined />Payouts</span>
              </div>
            }
            key="5"
          >
            <div className="organisation-details-container">
              <Payouts payout={payout} user={userDetails} getOrganisationData={getOrganisationData} />
            </div>
          </TabPane>
          <TabPane
            tab={
              <div className="tabs">
                <span className="tab-name"><HistoryOutlined />Logs</span>
              </div>
            }
            key="6"
          >
            <div className="organisation-details-container">
              <Logs user={userDetails} />
            </div>
          </TabPane>
          <TabPane
            tab={
              <div className="tabs">
                <span className="tab-name"><SettingOutlined />Settings</span>
              </div>
            }
            key="7"
          >
            <Settings user={userDetails} />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default Index;
