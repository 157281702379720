import axios from './index';

export async function getComplaince(organisationId, financialYear, month) {
    return await axios.get(`compliance/${organisationId}/${financialYear}/${month}`)
}
// complaince/create

export async function createComplaince(data) {
    return axios.post('complaince/create', data)
}
