import ReducerFactory from "../utils/reducerFactory";
import { getAllOrg, getAllCa, getUsersStats } from "../../apis/admin"
const initialState = {
    allOrg: [],
    allCa: [],
    userStats: {},
}

const reducerFactory = new ReducerFactory("admin", initialState);

reducerFactory.addAction(
    "INITIATE_ADMIN",
    "initiateAdmin",
    async (data) => {
        let orgList = await getAllOrg()
        let caList = await getAllCa()
        let stats = await getUsersStats()
        return {allOrg: orgList.data, allCa: caList.data, stats: stats.data.message}
    },
    (state, action) => {
        if (action.data) {
            state.allOrg = action.data.allOrg
            state.allCa = action.data.allCa
            state.userStats = action.data.stats
        }
        const newState = Object.assign({}, state);
        return newState;
    }
);

reducerFactory.addAction(
    "All_ORG",
    "allOrg",
    async (data) => {
        let orgList = await getAllOrg()
        return orgList.data
    },
    (state, action) => {
        if (action.data) {
            state.allOrg = action.data;
        }
        const newState = Object.assign({}, state);
        return newState;
    }
);

reducerFactory.addAction(
    "All_CA",
    "allCa",
    async (data) => {
        let caList = await getAllCa()
        return caList.data
    },
    (state, action) => {
        if (action.data) {
            state.allCa = action.data
        }
        const newState = Object.assign({}, state);
        return newState;
    }
);

export default reducerFactory;
























