import axios from './index';

export async function check() {
  return axios.get('admin/check');
}

export async function register(data) {
  return axios.post('admin/register', data);
}

export async function login(data) {
  return axios.post('admin/login', data);
}


export async function setPassword(data) {
  return axios.post('admin/set/password', data);
}

export async function checkUser(data) {
  return axios.post('admin/verify', data);
}

export async function removeS3Object(data) {
  return axios.delete(`document`, { data })
}

export async function getAllLogsByUserID(userId) {
  return axios.get(`logs/user/${userId}`);
}

export async function getAllLogsByOrganisationId(organisationId) {
  return axios.get(`logs/org/${organisationId}`);
}

export async function updateOrganisation(data) {
  return axios.post(`admin/update/org`, data);
}

export async function updateUser(data) {
  return axios.post(`admin/update/user`, data);
}