import { Button, Col, Divider, notification, Row, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import Cross from "../../assets/icons/Cross.svg";
import Pen from "../../assets/icons/Pen.svg";
import Submit from "../../assets/common/verifiedLogos/verify-yes.svg";
import { InputField } from "../ComponentLibrary";
import { updateOrganisation } from "../../apis/auth";

const OtherEmployeeDetails = ({ user }) => {
    const [disabled, setDisabled] = useState(true)
    const [address, setAddress] = useState({
        addressLine1: "",
        addressLine2: "",
        landmark: "",
        city: "",
        state: "",
        pincode: "",
        country: "India"
    });
    const [bankInfo, setBankInfo] = useState({
        bankName: "",
        accountNumber: "",
        bankAddress: "",
        ifsc: "",
    });
    const [credentials, setCredentials] = useState({
        aadhaar: "",
        passportNumber: "",
        UAN: "",
        UANPassword: "",
        drivingLicenseNumber: "",
        PFNo: "",
        voterID: "",
        esicNumber: "",
    });

    const handleAddressChange = (e) => {
        setAddress({ ...address, [e.target.name]: e.target.value })
    }
    const handleBankInfoChange = (e) => {
        setBankInfo({ ...bankInfo, [e.target.name]: e.target.value })
    }
    const handleCredentialChange = (e) => {
        setCredentials({ ...credentials, [e.target.name]: e.target.value })
    }

    const onUpdate = () => {
        updateOrganisation(address).then(res => {
            notification.success({ message: "Updated Organisation Address Info Successfully!" })
        }).catch(err => {
            notification.error({ message: "Error While Updating Organisation Address" })
        })
    }

    useEffect(() => {
        if (user.address) {
            const addresses = user.address;
            const bankDetails = user.bankInfo;
            const credentialDetails = user.credentials;
            setAddress({
                addressLine1: addresses.addressLine1 ? addresses.addressLine1 : "",
                addressLine2: addresses.addressLine2 ? addresses.addressLine2 : "",
                landmark: addresses.landmark ? addresses.landmark : "",
                city: addresses.city ? addresses.city : "",
                state: addresses.state ? addresses.state : "",
                pincode: addresses.pincode ? addresses.pincode : "",
                country: "India"
            });
            setBankInfo({
                bankName: bankDetails.bankName,
                accountNumber: bankDetails.accountNumber,
                bankAddress: bankDetails.bankAddress,
                ifsc: bankDetails.ifsc,
            });
            setCredentials({
                aadhaar: credentialDetails.aadhaar,
                passportNumber: credentialDetails.passportNumber,
                UAN: credentialDetails.UAN,
                UANPassword: credentialDetails.UANPassword,
                drivingLicenseNumber: credentialDetails.drivingLicenseNumber,
                PFNo: credentialDetails.PFNo,
                voterID: credentialDetails.voterID,
                esicNumber: credentialDetails.esicNumber,
            })
        }
    }, [user])

    return (
        <div >
            <div className=" organisation-details-container address-edit" >
                <Skeleton loading={!user.organisationId} active avatar paragraph={{ rows: 6 }}>
                    <div className="information-section">
                        <div className="flex justify-between">
                            <h2>
                                Employee Address
                            </h2>
                            <div className="flex">
                                {
                                    !disabled && <Button type="link" className="edit-button" style={{ marginTop: "13px" }} onClick={onUpdate}>
                                        <span style={{ color: "rgb(64, 210, 172)" }}><img alt="img" src={Submit} />
                                            {" "}Save</span>
                                    </Button>
                                }
                                <Button type="link" className="edit-card-button" style={{ marginTop: "13px" }} onClick={() => setDisabled(!disabled)}>
                                    {disabled ? <span style={{ color: "rgb(254, 161, 1)" }}><img alt="img" src={Pen} />
                                        {" "}Edit</span> : <span style={{ color: "rgb(118, 123, 130)" }}><img alt="img" src={Cross} />
                                        {" "}Cancel</span>}
                                </Button>
                            </div>
                        </div>
                        <Row gutter={[40, 2]}>
                            <Col xs={24} sm={24} md={12} lg={12}>
                                <div className="employee-info">
                                    <h3 className="headings fields-heading">Address Line 1</h3>
                                    <InputField name="addressLine1" onChange={handleAddressChange} disabled={disabled} className="profile-data-display" value={address.addressLine1} />
                                </div>
                                <div className="employee-info">
                                    <h3 className="headings fields-heading">Landmark</h3>
                                    <InputField name="landmark" onChange={handleAddressChange} disabled={disabled} className="profile-data-display" value={address.landmark} />
                                </div>

                                <div className="employee-info">
                                    <h3 className="headings fields-heading">State</h3>
                                    <InputField name="state" onChange={handleAddressChange} disabled={disabled} className="profile-data-display" value={address.state} />
                                </div>

                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12}>
                                <div className="employee-info">
                                    <h3 className="headings fields-heading">Address Line 2</h3>
                                    <InputField name="addressLine2" onChange={handleAddressChange} disabled={disabled} className="profile-data-display" value={address.addressLine2} />
                                </div>
                                <div className="employee-info">
                                    <h3 className="headings fields-heading">City</h3>
                                    <InputField name="city" onChange={handleAddressChange} disabled={disabled} className="profile-data-display" value={address.city} />
                                </div>

                                <div className="employee-info">
                                    <h3 className="headings fields-heading">PINCode</h3>
                                    <InputField name="pincode" onChange={handleAddressChange} disabled={disabled} className="profile-data-display" value={address.pincode} />
                                </div>

                            </Col>
                        </Row>
                    </div>
                </Skeleton>
            </div>

            <div className=" organisation-details-container address-edit" >
                <Divider />
                <Skeleton loading={!user.organisationId} active avatar paragraph={{ rows: 6 }}>
                    <div className="information-section">
                        <div className="flex justify-between">
                            <h2>
                                Bank Information
                            </h2>
                        </div>
                        <Row gutter={[40, 2]}>
                            <Col xs={24} sm={24} md={12} lg={12}>
                                <div className="employee-info">
                                    <h3 className="headings fields-heading">Account Number</h3>
                                    <InputField name="accountNumber" onChange={handleBankInfoChange} disabled={disabled} className="profile-data-display" value={bankInfo.accountNumber} />
                                </div>
                                <div className="employee-info">
                                    <h3 className="headings fields-heading">Bank Name</h3>
                                    <InputField name="bankName" onChange={handleBankInfoChange} disabled={disabled} className="profile-data-display" value={bankInfo.bankName} />
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12}>
                                <div className="employee-info">
                                    <h3 className="headings fields-heading">IFSC Code.</h3>
                                    <InputField name="ifsc" onChange={handleBankInfoChange} disabled={disabled} className="profile-data-display" value={bankInfo.ifsc} />
                                </div>
                                <div className="employee-info">
                                    <h3 className="headings fields-heading">Bank Address</h3>
                                    <InputField name="bankAddress" onChange={handleBankInfoChange} disabled={disabled} className="profile-data-display" value={bankInfo.bankAddress} />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Skeleton>
            </div>

            <div className=" organisation-details-container address-edit" >
                <Divider />
                <Skeleton loading={!user.organisationId} active avatar paragraph={{ rows: 6 }}>
                    <div className="information-section">
                        <div className="flex justify-between">
                            <h2>
                                Credentials
                            </h2>
                        </div>
                        <Row gutter={[40, 2]}>
                            <Col xs={24} sm={24} md={12} lg={12}>
                                <div className="employee-info">
                                    <h3 className="headings fields-heading">Aadhaar</h3>
                                    <InputField name="aadhaar" onChange={handleCredentialChange} disabled={disabled} className="profile-data-display" value={credentials.aadhaar} />
                                </div>

                                <div className="employee-info">
                                    <h3 className="headings fields-heading">Passport Number</h3>
                                    <InputField name="passportNumber" onChange={handleCredentialChange} disabled={disabled} className="profile-data-display" value={credentials.passportNumber} />
                                </div>
                                <div className="employee-info">
                                    <h3 className="headings fields-heading">UAN No</h3>
                                    <InputField name="UAN" onChange={handleCredentialChange} disabled={disabled} className="profile-data-display" value={credentials.UAN} />
                                </div>
                                <div className="employee-info">
                                    <h3 className="headings fields-heading">Voter ID</h3>
                                    <InputField name="voterID" onChange={handleCredentialChange} disabled={disabled} className="profile-data-display" value={credentials.voterID} />
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12}>
                                <div className="employee-info">
                                    <h3 className="headings fields-heading">Driving License Number</h3>
                                    <InputField name="drivingLicenseNumber" onChange={handleCredentialChange} disabled={disabled} className="profile-data-display" value={credentials.drivingLicenseNumber} />
                                </div>
                                <div className="employee-info">
                                    <h3 className="headings fields-heading">PF Number</h3>
                                    <InputField name="PFNo" onChange={handleCredentialChange} disabled={disabled} className="profile-data-display" value={credentials.PFNo} />
                                </div>
                                <div className="employee-info">
                                    <h3 className="headings fields-heading">ESIC Number</h3>
                                    <InputField name="esicNumber" onChange={handleCredentialChange} disabled={disabled} className="profile-data-display" value={credentials.esicNumber} />
                                </div>
                                <div className="employee-info">
                                    <h3 className="headings fields-heading">UAN Password</h3>
                                    <InputField name="UANPassword" onChange={handleCredentialChange} disabled={disabled} className="profile-data-display" value={credentials.UANPassword} />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Skeleton>
            </div>
        </div>
    );
};

export default OtherEmployeeDetails;