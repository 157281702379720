import React, { useState, useEffect } from "react";
import { getComplianceCredentialsByPANAndOrgId } from "../../apis/admin";
import { Col, Row, Spin } from "antd";
import { HeaderComponent } from "../Common";
import CredentialDetails from "./components/CredentialDetails";
import ManageDocuments from "./components/ManageDocuments";
import { connect } from "react-redux";

const ManageCredentials = (props) => {
  const PAN = props.match.params.PAN;
  const organisationId = props.match.params.organisationId
  const [orgUser, setOrgUser] = useState({});
  const [userData, setUserData] = useState({
    user: {},
    organisationId: "",
    PAN: "",
    PF: { userName: "", password: "" },
    PT: { userName: "", password: "" },
    ESIC: { userName: "", password: "" },
    TDS: { userName: "", password: "" },
  });
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    let org = props.allOrg.filter(val => val.PAN === PAN && val.organisationId === organisationId)
    if (org.length > 0) {
      setOrgUser({ ...org[0] })
    }
    getComplianceCredentialsByPANAndOrgId(PAN, organisationId)
      .then((res) => {
        if (res.data) {
          const credObj = {
            userName: "",
            password: ""
          }
          setUserData({
            ...res.data,
            PF: Object.keys(res.data.PF).length ? res.data.PF : { ...credObj },
            PT: Object.keys(res.data.PT).length ? res.data.PT : { ...credObj },
            ESIC: Object.keys(res.data.ESIC).length ? res.data.ESIC : { ...credObj },
            TDS: Object.keys(res.data.TDS).length ? res.data.TDS : { ...credObj },
          })
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [PAN, organisationId, props.allOrg]);

  return (
    <>
      <Spin spinning={loading}>
        <div className='site-layout'>
          <HeaderComponent name='Manage Credentials' />
          <div className='app-main-content-container'>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col sm={24} md={12}>
                <CredentialDetails userData={userData} user={orgUser} />
              </Col>
              <Col sm={24} md={12}>
                <ManageDocuments userData={userData} user={orgUser} />
              </Col>
            </Row>
          </div>
        </div>
      </Spin>
    </>
  );
};
const ManageCredentialsContainer = connect(
  state => ({
    allOrg: state.admin.allOrg,
  }),
  (dispatch) => ({
  })
)(ManageCredentials)

export default ManageCredentialsContainer;
