import React, { useState, useEffect } from "react";
import {
  Table,
  Input,
  Button,
  notification,
  Modal,
  Select,
  InputNumber,
  Spin,
} from "antd";
import Add from "../../../assets/icons/Add.svg";
import moment from "moment";
import {
  getNewCreditRequests,
  updateCreditStatus,
  updateAddCreditStatus,
} from "../../../apis/admin";
import { NormalButton } from "../../ComponentLibrary";
import { connect } from "react-redux";
import orgTempLogo from "../../../assets/common/organisation_temporary_logo.svg";
import Cross from "../../../assets/icons/Cross.svg";
const { Option } = Select;

const Requests = ({ allOrg, allCa, fileView }) => {
  const [selectDropDown, setSelectDropDown] = useState({});
  const [userCredits, setUserCredits] = useState([]);
  const [isCreditModalVisible, setIsCreditModalVisible] = useState(false);
  const [organisationDisplayData, setOrganisationDisplayData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addCreditData, setAddCreditData] = useState({
    typeOfClient: "",
    PAN: "",
    addAmount: 0,
    currencyType: "INR",
  });
  useEffect(() => {
    setSelectDropDown({
      ...selectDropDown,
      org: allOrg,
      ca: allCa,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCa, allOrg]);
  const showCreditModal = () => {
    setIsCreditModalVisible(true);
  };
  const closeCreditModal = () => {
    setAddCreditData({
      typeOfClient: "",
      PAN: "",
      addAmount: 0,
      currencyType: "INR",
    });
    setIsCreditModalVisible(false);
  };
  useEffect(() => {
    getUserCredits();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getUserCredits = async () => {
    setLoading(true);
    let creditData = await getNewCreditRequests(0);
    creditData.data.forEach((element) => {
      let name = fetchOrgName(element.PAN)
        ? fetchOrgName(element.PAN)
        : fetchCaName(element.PAN);
      element.name = name;
    });
    creditData.data.forEach((element) => {
      let img = fetchOrgImg(element.PAN)
        ? fetchOrgImg(element.PAN)
        : fetchCaImg(element.PAN);
      element.img = img;
    });
    setUserCredits(creditData.data);
    setOrganisationDisplayData(creditData.data);
    setLoading(false);
  };

  const fetchOrgName = (PAN) => {
    let companyName = "Company";
    for (let i = 0; i < allOrg.length; i++) {
      if (allOrg[i].PAN === PAN) {
        companyName = allOrg[i].displayName;
        break;
      }
    }
    return companyName;
  };

  const fetchOrgImg = (PAN) => {
    let companyImg = "";
    for (let i = 0; i < allOrg.length; i++) {
      if (allOrg[i].PAN === PAN) {
        companyImg = allOrg[i].image;
        break;
      }
    }
    return companyImg;
  };

  const fetchCaName = (PAN) => {
    let companyName = "Company";
    for (let i = 0; i < allCa.length; i++) {
      if (allCa[i].PAN === PAN) {
        companyName = allCa[i].displayName;
        break;
      }
    }
    return companyName;
  };

  const fetchCaImg = (PAN) => {
    let companyImg = "";
    for (let i = 0; i < allCa.length; i++) {
      if (allCa[i].PAN === PAN) {
        companyImg = allCa[i].image;
        break;
      }
    }
    return companyImg;
  };

  const handleCreditChange = async (rowData) => {
    setLoading(true);
    let tempData = {
      id: rowData._id,
      status: 1,
    };
    let res = await updateCreditStatus(tempData);
    setLoading(false);
    if (res.data.success) {
      getUserCredits();
      notification.success({ message: "Successfully updated ticket status" });
      return;
    } else {
      notification.error({
        message: "Failed to update status, Please try again",
      });
      return;
    }
  };

  const cancelCreditChange = async (rowData) => {
    setLoading(true);
    let tempData = {
      id: rowData._id,
      status: 2,
    };
    let response = await updateCreditStatus(tempData);
    setLoading(false);
    if (response.data.success) {
      getUserCredits();
      notification.warning({ message: "Successfully deleted the requests" });
      return;
    } else {
      notification.error({
        message: "Failed to update status, Please try again",
      });
      return;
    }
  };
  const confirmCredit = async () => {
    setLoading(true);
    let res = await updateAddCreditStatus(addCreditData);
    setLoading(false);
    if (res.data.success) {
      setAddCreditData({
        typeOfClient: "",
        PAN: "",
        addAmount: 0,
      });
      setIsCreditModalVisible(false);
      notification.success({ message: "Successfully added mool credits" });
      return;
    } else {
      notification.error({
        message: "Failed to add mool credits, Please try again",
      });
      return;
    }
  };
  const searchOrganisation = (e) => {
    let newOrganisation = [];
    let SerachParams = e.target.value.toLowerCase();
    newOrganisation = userCredits.filter((val) => {
      return Object.keys(val).some((keys) =>
        val[keys]
          .toString()
          .toLowerCase()
          .includes(SerachParams)
      );
    });
    setOrganisationDisplayData(newOrganisation);
  };
  const columns = [
    {
      width: "15%",
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name, rowdata) => (
        <>
          <div className="org-list-name-container">
            <img
              alt="img"
              className={
                rowdata.img
                  ? "org-list-logo"
                  : "org-list-logo org-list-logo-color"
              }
              src={rowdata.img ? rowdata.img : orgTempLogo}
            ></img>
            <div className="org-list-name-and-pan">
              <p className="org-list-name">{rowdata.name}</p>
              <p className="org-list-pan">PAN:{rowdata.PAN}</p>
            </div>
          </div>
        </>
      ),
    },
    {
      width: "10%",
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => (
        <span className="date-of-joining">{moment(date).format("ll")}</span>
      ),
    },
    {
      width: "10%",
      title: "Requested On",
      dataIndex: "requestedOn",
      key: "requestedOn",
      render: (requestedOn) => (
        <span className="date-of-joining">
          {moment(requestedOn).format("ll")}
        </span>
      ),
    },
    {
      title: "Attachment",
      dataIndex: "documents",
      key: "documents",
      ellipsis: true,
      width: "20%",
      render: (documents, rowData) =>
        rowData.proofs.map((item, i) => {
          return (
            <NormalButton
              type={5}
              buttonText={`${item.name}`}
              padding={[0, 0]}
              color="blue"
              onClick={() => fileView(item)}
              className="attachment-buttons"
            />
          );
        }),
    },

    {
      key: "accept",
      width: "20%",
      dataIndex: "credits",
      render: (credits, rowData) => (
        <div>
          {
            <>
              <NormalButton
                type={1}
                buttonText={`Add ${rowData.amount} Coins`}
                width="100%"
                onClick={() => handleCreditChange(rowData)}
              />
              <NormalButton
                type={5}
                buttonText="Cancel"
                onClick={() => cancelCreditChange(rowData)}
              />
            </>
          }
        </div>
      ),
    },
  ];

  const List = ["org", "ca"];
  return (
    <>
      <Spin spinning={loading}>
        <div className="app-white-card active-client-management-card">
          <div className="app-flex-container-1">
            <h3 className="app-heading-2">New Request</h3>
            <div className="manageCreditContainer">
              <Button
                type="link"
                className="edit-card-button"
                onClick={() => showCreditModal()}
              >
                <img
                  src={Add}
                  alt="img"
                  style={{ marginBottom: "2px", marginRight: "2px" }}
                />
                Manage Credit
              </Button>
              <Input
                onChange={searchOrganisation}
                className="credit-input-field"
                size={"large"}
                placeholder="search"
                bordered={false}
              ></Input>
            </div>
          </div>
          <Table
            pagination={{ pageSize: 6 }}
            columns={columns}
            dataSource={organisationDisplayData}
            size="middle"
          />
        </div>
        <Modal
          className="credit-modal"
          visible={isCreditModalVisible}
          onCancel={closeCreditModal}
          centered
          footer={null}
          closable={false}
        >
          <div className="modal-header-section">
            <p className="modal-section-heading">Manage Credit</p>
            <Button
              type="link"
              className="modal-close"
              onClick={() => closeCreditModal()}
            >
              <img src={Cross} alt="img"></img>
            </Button>
          </div>
          <div className="modal-body-section">
            <div className="client-type">
              <p className="client-type-heading">Client Type</p>
              <Select
                className="general-input-field"
                name="clientType"
                value={addCreditData.typeOfClient}
                onChange={(e) => {
                  let addCreditDataInfo = addCreditData;
                  setAddCreditData({
                    ...addCreditDataInfo,
                    typeOfClient: e,
                  });
                }}
                style={{ width: "97%" }}
                bordered={false}
                size={"large"}
              >
                {List.map((val, i) => {
                  return <Option key={`option-ca-org-item-${i}`} value={val}>{val}</Option>;
                })}
              </Select>
            </div>
            {addCreditData.typeOfClient && (
              <div className="client-name">
                <p className="client-type-heading">Client</p>
                <Select
                  className="general-input-field"
                  value={addCreditData.PAN}
                  onChange={(e) => {
                    setAddCreditData({
                      ...addCreditData,
                      PAN: e.toUpperCase(),
                    });
                  }}
                  style={{ width: "97%" }}
                  bordered={false}
                  size={"large"}
                >
                  {selectDropDown[addCreditData.typeOfClient].map((val, i) => (
                    <Option key={`option-list-ca-org-item-${i}`} value={val.PAN}>{val.displayName}</Option>
                  ))}
                </Select>
              </div>
            )}
            <div className="credit-addition-info">
              <h3 className="credit-addition-heading">Mool Credit</h3>
              <InputNumber
                className="general-input-field"
                style={{ width: "97%" }}
                name="creditPrice"
                value={parseInt(addCreditData.addAmount)}
                onChange={(e) => {
                  let addCreditDataInfo = addCreditData;
                  setAddCreditData({
                    ...addCreditDataInfo,
                    addAmount: e,
                  });
                }}
                size={"large"}
              />
            </div>
            <div className="credit-button">
              <Button
                className="submit-button"
                style={{
                  width: "30%",
                  marginTop: "20px",
                  marginRight: "20px",
                  padding: "0px 24px",
                }}
                onClick={confirmCredit}
              >
                {" "}
                Save{" "}
              </Button>
            </div>
          </div>
        </Modal>
      </Spin>
    </>
  );
};

const RequestsContainer = connect((state) => ({
  allOrg: state.admin.allOrg,
  allCa: state.admin.allCa,
}))(Requests);

export default RequestsContainer;
